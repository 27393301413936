import React, { useState, useEffect } from "react";
import { Upload, Button, message, Select, Form, Image, Tag, Space,Table,Tooltip  } from "antd";
import { Col, Row, Container } from "react-bootstrap";
import { InboxOutlined, EyeOutlined, DeleteOutlined, FileOutlined } from "@ant-design/icons";
import "../css/label.css"; // Importa tu archivo CSS personalizado
import iconUpload from "../img/icon-upload.png";
import iconPreview from "../img/icon-preview.png";
import iconDelete from "../img/icon-delete.png";
import iconAcepted from "../img/green-check.png";
import { CREAR_BENEFICIO, GET_BENEFICIOS, GUARDAR_DOCUMENTO_FUNCIONAL, LISTA_BENEFICIO } from "../services/api";
import { useAuthContext } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;
const { Option } = Select;

const UploadTest = () => {
  const [form] = Form.useForm();
  const { user, usuarioSinPermisos} = useAuthContext();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [filePreviews, setFilePreviews] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);//useState("");
  const [urlSave, setUrlSave] = useState([]);
  const [selectBeneficios, setSelectBeneficios] = useState([]);//listado beneficios
  const [usuarioIDs, setUsuarioIDs] = useState({
    id_pos: null,
    id_persona: null
  });
  const [loadingTable, setLoadingTable] = useState(false);
  const [tablaBeneficios, setTablaBeneficios] = useState([]);


  const resetFormAndReloadTable  = () => {
   // Resetear los valores del formulario de antd
   form.resetFields();

   // Resetear otras variables
   setFileList([]);
   setFilePreviews({});
   setSelectedOption(null);
   setUrlSave([]);
   refreshTable();
  };

  const refreshTable = async() => {
    console.log("refreshTable");
    setLoadingTable(true);
    const {id_pos, id_persona} = user.usuario.usuario_cuenta[0].personas;

    const objEnviar = {
      "id_pos": id_pos,
      "id_persona":id_persona,
    }

    const { data, msg } = await LISTA_BENEFICIO(objEnviar);
    console.log(data, msg);
    if (data === null) {
      //setErrorMessage(msg);
      setLoadingTable(false);

    } else {

      let formattedData = [];

      if (data && data.data.length > 0) {
      
        formattedData = data.data.map(item => ({
          key: item.id_solicitud_beneficios,
          nom_beneficio: item.tipo[0].descripcion,
          fecha_exp: item.fechaVencimiento,
          estado: item.estados_beneficios.descripcion,
          obs_ejecutivo: item.observacionEjecutivo,
          docs_beneficio: item.documentos,
        }));

 
      }
      setTablaBeneficios(formattedData);
      setLoadingTable(false);
    }

  }; 
  const obtenerDataTabla = async() => {
    setLoadingTable(true);

    if (!user || !user.usuario || !user.usuario.usuario_cuenta || !user.usuario.usuario_cuenta[0].personas) {
      navigate("/login");
      return;
    }
    const {id_pos, id_persona} = user.usuario.usuario_cuenta[0].personas;
    console.log(user.usuario.usuario_cuenta[0].personas);
    console.log("pos=>",id_pos, "|persona=>", id_persona);
    // const objEstaticoBorrar = {
    //   // "id_pos": 0,
    //   // "id_persona":10478,
    //   "id_pos": 3006,
    //   "id_persona":8,
    // }

    const objEnviar = {
      "id_pos": id_pos,
      "id_persona":id_persona,
    }
    // console.log(objEnviar, "objEnviar");
    const { data, msg } = await LISTA_BENEFICIO(objEnviar);
    console.log(data);
    if (data === null) {
      //setErrorMessage(msg);
      setLoadingTable(false);

    } else {

      let formattedData = [];

      if (data && data.data.length > 0) {
      
        formattedData = data.data.map(item => ({
          key: item.id_solicitud_beneficios,
          nom_beneficio: item.tipo[0].descripcion,
          fecha_exp: item.fechaVencimiento,
          estado: item.estados_beneficios.descripcion,
          obs_ejecutivo: item.observacionEjecutivo,
          docs_beneficio: item.documentos,
        }));

 
      }
      setTablaBeneficios(formattedData);
      setLoadingTable(false);
    }

  };

  const handleUploadTesty = ({ fileList }) => {
    const filteredFiles = fileList.filter((file) => {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      return allowedTypes.includes(file.type);
    });

    const nonAllowedFiles = fileList.filter(
      (file) => !filteredFiles.includes(file)
    );
    if (nonAllowedFiles.length > 0) {
      message.error(
        `No se pueden cargar los siguientes tipos de archivo: ${nonAllowedFiles
          .map((file) => file.name)
          .join(", ")}`
      );
    }

    const updatedFileList = filteredFiles.map((file) => ({
      ...file,
      uid: file.uid || Math.random().toString(36).substring(7),
    }));

    setFileList(updatedFileList);

    const previews = {};
    updatedFileList.forEach(async(file) => {
      // console.log(file, "updatedFileList");
      const url_documento = await documentoSave(file);

      //si existe url
      if(url_documento){
        file.type === "application/pdf"
        ? (previews[file.uid] =
            "https://recursos.tabsa.cl/storage/documents/bKI3PacW.pdf#view=FitH")
        : (previews[file.uid] = url_documento.url); 
      }else{

        message.error("NO SE CARGO LA IMAGEN");
      }
               // "https://cdn.vox-cdn.com/thumbor/xWWGhhBNkSFTtHXDNnDDK8LGX6I=/0x0:1147x647/920x613/filters:focal(483x233:665x415):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/70221420/Jotaro.0.jpeg");
    });
    setFilePreviews(previews);
  };

  //FUNCIONALIDAD DINAMICA DEL UPLOAD CON SEPARACION DE IMAGENES Y PDF
  const handleUpload = ({ fileList }) => {
    //ARCHIVOS PERMITIDOS
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    //FILTRA SOLO LOS PERMITIDOS
    const filteredFiles = fileList.filter((file) => allowedTypes.includes(file.type));
    //ARCHIVOS NO PERMITIDOS
    const nonAllowedFiles = fileList.filter((file) => !filteredFiles.includes(file));
    if (nonAllowedFiles.length > 0) {
      message.error(
        `No se pueden cargar los siguientes tipos de archivo: ${nonAllowedFiles
          .map((file) => file.name)
          .join(", ")}`
      );
    }
  
    //LOGICA DE ARCHIVOS PERMITIDOS, SE LE ASIGNA UN ID RANDOM
    const updatedFileList = filteredFiles.map((file) => ({
      ...file,
      uid: file.uid || Math.random().toString(36).substring(7),
    }));
  
    //SE OBTIENE URL DE PREVISUALIZACION 
    const previews = {};
    const validFilePromises = updatedFileList.map(async (file) => {
      const url_documento = await documentoSave(file);
      //VALIDA SI VIENE URL Y LOS SEPARA POR TIPO DE ARCHIVO YA SEA PDF O IMAGEN
      if (url_documento) {
        previews[file.uid] = file.type === "application/pdf"
          // ? "https://recursos.tabsa.cl/storage/documents/bKI3PacW.pdf#view=FitH"
          ? `${url_documento.url}#view=FitH`
          : url_documento.url;
        return file;
      } else {
        message.error(`No se pudo cargar el documento: ${file.name}`);
        return null;
      }
    });
    //ACTUALIZACION DE ESTADOS QUITA TODO POSIBLE ERROR DE QUE SE CARGUE MAL EL DOCUMENTO (TESTEAR)
    Promise.all(validFilePromises).then((results) => {
      const validFiles = results.filter((file) => file !== null);
      setFileList(validFiles);
      setFilePreviews(previews);
    });
  };
  
  
  const handlePreview = (uid) => {
    const previewUrl = filePreviews[uid];
    window.open(previewUrl, "_blank");
  };

  const handleDeleteTets= (uid) => {
    const updatedFileList = fileList.filter((file) => file.uid !== uid);
    const updatedPreviews = { ...filePreviews };
    delete updatedPreviews[uid];
    setFileList(updatedFileList);
    setFilePreviews(updatedPreviews);
  };
  const handleDelete = (uid) => {
    const updatedFileList = fileList.filter((file) => file.uid !== uid);
    const updatedPreviews = { ...filePreviews };
    delete updatedPreviews[uid];
    setFileList(updatedFileList);
    setFilePreviews(updatedPreviews);
    // Clear the form value for the document
    form.setFieldsValue({
      documentos: updatedFileList.length > 0 ? updatedFileList : null,
    });
  };
  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  //este es el bueno
  const handleSubmit = async(values) => {
    // Lógica para enviar el formulario
    
    const {id_pos, id_persona} = user.usuario.usuario_cuenta[0].personas;
    console.log("ids directos",id_pos,"|||", id_persona);
    console.log(usuarioIDs, "usuarioIDs");
    console.log("Formulario enviado: estado del formulario", values);
    setUrlSave(Object.values(filePreviews));
    const beneficioEscogido = values.beneficio;
    console.log(beneficioEscogido, "beneficioEscogido");
    console.log(urlSave, "urlSave");
    console.log(selectedOption, "selectedOption");
    console.log(filePreviews, "filePreviews");


    const obj = {
      opcion: selectedOption,
      urls: Object.values(filePreviews), // Obtener las URLs desde el estado
    };
    console.log("object con opcion escogida y urls=>", obj);

    const objEnviar = {
      id_pos: id_pos,
      id_persona: id_persona,
      tipo: beneficioEscogido,
      urls: Object.values(filePreviews), // Obtener las URLs desde el estado
    };
    console.log("|||||||||||||||||||||||||||||||||");
    console.log("comienza el flujo de CREAR_BENEFICIO");
    console.log("objEnviar front=>", objEnviar);
    
    const { data, msg } = await CREAR_BENEFICIO(objEnviar)
    // console.log(data,"data.data CREAR_BENEFICIO");
    // console.log(data.data, "data.data CREAR_BENEFICIO");
    if (data === null) {
      console.log("No se hizo la peticion bien");
      console.log(data);
      console.log(msg);
    } else {
      resetFormAndReloadTable()
      console.log("Se hizo bien la peticion");
      console.log(data);
      console.log(msg);

    }

  };

  const handleClick = () => {
    console.log("se resetaron los campos");
    resetFormAndReloadTable();
    // Lógica para enviar el formulario
    console.log(urlSave);
    console.log(selectedOption);
    console.log(filePreviews);

    //setUrlSave(Object.values(filePreviews));
    const obj = {
      opcion: selectedOption,
      urls: Object.values(filePreviews), // Obtener las URLs desde el estado
    };
    // console.log("object=>", obj);

    const objEnviar = {
      id_pos: null,
      id_persona: null,
      tipo: selectedOption,
      url: Object.values(filePreviews), // Obtener las URLs desde el estado
    };

    // console.log("objEnviar=>", objEnviar);
  };

  const verLogs = () => {
    console.log("fileList", fileList);
    console.log("filePreviews", filePreviews);

    console.log("nuevos logs");
    console.log("selectedOption", selectedOption);
    console.log("urlSave", urlSave);
  };

  //LISTADO BENEFICIOS
  const obtenerListadoBeneficios = async () => {
    const resp = await GET_BENEFICIOS();
 
    if(resp.data === null){
      message.error(resp.msg);
    }else{
      // message.success(resp.msg);
      // console.log(resp.data);
      setSelectBeneficios(resp.data);
    }
  };

  //SE HACE LA PETICION PARA GUARDAR LA IMAGEN Y TE DEVUELVE EL ULR DEL DOCUMENTO O UN NULL
  const uploadImageToApi = async (file) => {
    // console.log("llego uploadImageToApi=>", file);
    // return
    try {
      const respImg = await GUARDAR_DOCUMENTO_FUNCIONAL(file);
      if(respImg.data === null){
        // message.error(respImg.msg);
        console.log(respImg,"mala peticion");
        return null
      }else{
        // message.success(respImg.msg);
        console.log(respImg, "buena peticion");
        return respImg.data;
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  //ESTA FUNCION RESCATA EL VALOR PARA LUEGO ANALIZARLO "handleUpload"
  const documentoSave = async(documento) =>{

    // const formData = new FormData();
    // formData.append('image', documento.originFileObj);

    const imageData = await uploadImageToApi(documento.originFileObj);
    // console.log("documentoSave fin del flujo",imageData);
    return imageData
    // values.ImagenSave = imageData.url;
  }

  useEffect(() => {
    document.title = "Tabsa | Beneficios";
    obtenerListadoBeneficios();
    obtenerDataTabla();

  }, []);
    //table example

    const columns = [
      {
        title: "Nombre del Beneficio",
        dataIndex: "nom_beneficio",
        key: "nom_beneficio",
  
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Fecha de expiración",
        dataIndex: "fecha_exp",
        key: "fecha_exp",
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
      },
      {
        title: "Observación ejecutivo",
        dataIndex: "obs_ejecutivo",
        key: "obs_ejecutivo",
      },
      // {
      //   title: "Documentos",
      //   key: "action",
      //   // width: 180,
      //   // fixed: 'right',
      //   render: (_, record) => (
      //     <Space>
      //     {record.docs_beneficio && record.docs_beneficio.map((doc, index) => (

      //       <Tooltip key={index} title="Ver documento" color="blue">
      //         <Image key={doc.id_solicitud_documento} src={iconUpload} width={36} preview={false}  onClick={() => window.open(doc.url, "_blank")} style={{ cursor: 'pointer' }} />
      //       </Tooltip>

      //     ))}
      //   </Space>
      //   ),
      // },
      {
        title: "Documentos",
        key: "action",
        render: (_, record) => (
          <Space>
            {record.docs_beneficio && record.docs_beneficio.map((doc, index) => (
              <Tooltip key={index} title="Ver documento" color="blue">
                <div
                  key={doc.id_solicitud_documento}
                  onClick={() => window.open(doc.url, "_blank")}
                  style={{ cursor: 'pointer', display: 'inline-block' }}
                >
                  <Image src={iconUpload} width={36} alt="Ver documento" preview={false} />
                </div>
              </Tooltip>
            ))}
          </Space>
        ),
      },
    ];
    const columns22 = [
      {
        title: "Nombre del Beneficio",
        dataIndex: "nom_beneficio",
        key: "nom_beneficio",
  
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Fecha de expiración",
        dataIndex: "fecha_exp",
        key: "fecha_exp",
      },
    ];
    const data = [
      {
        key: "1",
        name: "John Brown",
        age: 32,
        address: "New York No. 1 Lake Park",
        tags: ["nice", "developer"],
      },
      {
        key: "2",
        name: "Jim Green",
        age: 42,
        address: "London No. 1 Lake Park",
        tags: ["loser"],
      },
      {
        key: "3",
        name: "Joe Black",
        age: 32,
        address: "Sydney No. 1 Lake Park",
        tags: ["cool", "teacher"],
      },
    ];
  return (
    <Container 
    // style={{ padding: "5%" }} 
    style={{
      padding: "2% 5% 0 5%",
    }}
    fluid>
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <h1 className="titulo">Mis Beneficios</h1>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <h2 className="subtitulo">Descripción del beneficio:</h2>
          <p className="parrafo">
            • Si quieres evitar pagar el impuesto de IVA en el transporte de tu
            vehículo, solo necesitas enviar una copia de tu tarjeta de
            Residente.
          </p>
        </Col>

        {/* <Col xxl={3} xl={3} lg={3} md={3} xs={12}> */}
        <Col xs={12}>
          <Form
           form={form}
            onFinish={handleSubmit}
            name="basic"
            autoComplete="off"
            labelAlign="left"
            layout="vertical"
            requiredMark={false} // Esto oculta todos los asteriscos
          >
            <Row>
              <Col xxl={3} xl={3} lg={3} md={3} xs={12}>
                <Form.Item
                  name="beneficio"
                  label="Lista de beneficios"
                  className="floating-label-form-item"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona un beneficio!",
                    },
                  ]}
                >
                  {/* <div>  */}
                  {/* <Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    placeholder="seleccionar beneficio"
                  >
                    <Option value="1">beneficio 1</Option>
                    <Option value="2">beneficio 2</Option>
                    <Option value="3">beneficio 3</Option>
                  </Select> */}

                  {/* </div>  */}
                  <Select placeholder="Selecciona beneficio" allowClear >
                      {selectBeneficios.map((benefist) => (
                        <Option
                          key={benefist.id_tipo_persona}
                          value={benefist.id_tipo_persona}
                        >
                          {benefist.descripcion}
                        </Option>
                      ))}
                    </Select>
                </Form.Item>
                <span style={{ 
                  //background: "red" 
                }}
                >
                  Selecciona un beneficio para conocer más detalles.
                </span>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                <h2 className="subtitulo">Documentos requeridos:</h2>
                <Row>
                  <Col xxl={3} xl={3} lg={8} md={8} xs={12}>
                  <Form.Item
                  name="documentos"
                  className="floating-label-form-item"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione documentos!",
                    },
                  ]}
                >
                    <Dragger
                      showUploadList={false}
                      beforeUpload={() => false}
                      onChange={handleUpload}
                      accept=".jpg,.png,.pdf"
                      multiple
                      maxCount={3}
                    >
                      <div>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                          {/* <Image src={iconUpload} preview={false} /> */}
                        </p>

                        <p className="ant-upload-text">
                          Haz clic o arrastra archivos aquí para cargar
                        </p>
                        <p className="ant-upload-hint">
                          Solo se permiten archivos JPG, PNG y PDF.
                        </p>
                      </div>
                    </Dragger>
                    </Form.Item>
                  </Col>
                  {fileList.map((file) => (
                    <Col
                      xxl={3}
                      xl={4}
                      lg={4}
                      md={4}
                      xs={12}
                      // xxl={2}
                      // xl={3}
                      // lg={4}
                      // md={6}
                      // xs={12}
                      key={file.uid}
                      style={{
                        // marginTop: '10px',
                        // marginRight: '30px'
                        // margin: "45px 45px 0 0",
                        margin: "0 5px 0 0",
                        // background: "grey",
                        // border:"2px solid black",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ textAlign: "center", marginRight: "10px" }}>
                        <embed
                          src={filePreviews[file.uid]}
                          // width="300"
                          // height="150"
                          type={file.type}
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "contain",
                          }}
                          // className="test-embed"
                        />
                      </div>

                      <div style={{ textAlign: "center" }}>documento</div>
                      <div className="button-separate-benefist">
                        <Button
                          onClick={() => handlePreview(file.uid)}
                          style={{
                            background: "transparent",
                            border: "none",
                            borderRadius: "none",
                            color: "black",
                          }}
                        >
                          <Image
                            src={iconPreview}
                            width={25}
                            preview={false}
                            style={{ marginLeft: "-20px" }}
                          />
                          Previsualizar
                        </Button>
                        <Button
                          onClick={() => handleDelete(file.uid)}
                          style={{
                            background: "transparent",
                            border: "none",
                            borderRadius: "none",
                            color: "black",
                          }}
                        >
                          <Image
                            src={iconDelete}
                            width={25}
                            preview={false}
                            style={{ marginLeft: "-20px" }}
                          />
                          Borrar
                        </Button>
                      </div>
                      {/* <div style={{textAlign:"center"}}>
                <Button icon={<EyeOutlined />} onClick={() => handlePreview(file.uid)} style={{textAlign:"left"}}>Previsualizar</Button>
                <Button icon={<DeleteOutlined />} onClick={() => handleDelete(file.uid)} style={{textAlign:"right"}}
                  // style={{ marginLeft: '10px'}}
                >Borrar</Button>
              </div> */}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row>
              <Form.Item className="fi_display margin_dt" style={{marginTop:"50px"}}>
                {/* <Button htmlType="submit">DENTRO DEL FORM</Button> */}
                <Button className="boton_primario btn_medidas" htmlType="submit">Solicitar beneficio</Button>
              </Form.Item>
            </Row>
          </Form>
        </Col>

        {/* {fileList.length > 0 && (
        <Row style={{ marginTop: '70px' }}>
          {fileList.map(file => (
            <Col xxl={3} xl={4} lg={4} md={6} xs={12} key={file.uid} style={{ marginTop: '10px'}}>
              <embed 
                src={filePreviews[file.uid]} 
                width="300" 
                height="150" 
                type="application/pdf" 
                style={{ width: '300px', height: '150px', objectFit: 'contain', marginRight: '10px' }} 
              />
              <div style={{ display:"flex"}} >
                <Button icon={<EyeOutlined />} onClick={() => handlePreview(file.uid)}>Previsualizar</Button>
                <Button icon={<DeleteOutlined />} onClick={() => handleDelete(file.uid)} style={{ marginLeft: '10px'}}>Borrar</Button>
              </div>
            </Col>
          ))}
        </Row>
      )} */}
      </Row>

      {/* <Row justify="center" style={{ marginTop: "20px" }}>
        <Col>
          <Button onClick={handleClick}>Enviar</Button>
          <Button
            onClick={() => verLogs()}
          >
            ver logs
          </Button>
        </Col>
      </Row> */}
      <Row style={{ marginTop: "20px", marginBottom: "50px"}}>
      <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <h2 className="subtitulo">Descripción del beneficio:</h2>
          <p className="parrafo">
            • Si quieres evitar pagar el impuesto de IVA en el transporte de tu
            vehículo, solo necesitas enviar una copia de tu tarjeta de
            Residente.
          </p>
          <Table
            loading={loadingTable}
            columns={columns}
            dataSource={tablaBeneficios}
            bordered={true}
            style={{ marginTop: "20px" }}
            scroll={{
              x: 1000,
              y: 400,
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default UploadTest;

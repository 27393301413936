import React,  { useEffect }  from 'react'
import { Col, Row, Container } from "react-bootstrap";
import MantBenefits from '../components/MantBenefits';

const MyBenefits = () => {
    useEffect(() => {
        document.title = "Tabsa | Mis Beneficios";
    }, []);
      
    return (
    <Container 
    style={{
        padding: "2% 5% 0 5%",
    }}
    fluid
    >
        <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
            <MantBenefits />
        </Col>
        </Row>
    </Container>
    )
}

export default MyBenefits
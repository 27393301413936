// import React from 'react';
// import ReactDOM from 'react-dom/client';
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import './css/index.css';
import Read from './views/Read';
import Dashboard from './views/Dashboard';
import Layout from './views/Layout';
import Account from './views/Account';
import RecoverPassword from './views/RecoverPassword';
import ValidDocument from './views/ValidDocs';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
  useLocation
} from "react-router-dom";
import Login from "./views/Login";
import { GoogleOAuthProvider, useGoogleOAuth } from '@react-oauth/google';
// import {useGoogleLogin} from 'react-google-login'
import ErrorView from "./views/ErrorView";
import AuthContextProvider, { useAuthContext } from "./contexts/authContext";
import UserProfile from "./views/UserProfile";
import PrivateRouteMx from "./components/router/PrivateRoute"
import "bootstrap/dist/css/bootstrap.css";

import LoginExecutive from "./views/LoginExecutive";
import MyBenefits from "./views/MyBenefits";

import LayoutExecutive from "./views/LayoutExecutive";
import Profile from "./views/Profile";
import PruebaFormulario from "./views/PruebaFormulario";
import ProfilePublic from "./views/ProfilePublic";
import ProfilePrivate from "./views/ProfilePrivate";
import UploadTest from "./views/UploadTest";
import DraggerTest from "./views/DaggerTest";
import ChangePassword from "./views/ChangePassword";
import ProfilePublicEmail from "./views/ProfilePublicEmail";
import TestMobile from "./views/TestMobile";
import DashboardExecutive from "./views/DashboardExecutive";
import ContentExcutive from "./views/ContentExcutive";

const PrivateRouteX = ({ path, element, roleR }) => {
  //logica autentificacion normal mas la de google
  // console.log(path, element );
  // const { accessToken } = useGoogleOAuth();
  // console.log(accessToken, "accessToken");
  // //return accessToken ? element : <Navigate to="/login" />;
  // //test en negacion para probar
  // return !accessToken ? element : <Navigate to="/login" />;

  //login normal
  let tieneSession = localStorage.getItem("token");
  const { user, role, isAuthenticated } = useAuthContext();
  console.log("usuariooooo?>",user);
  console.log("isAuthenticated?>",isAuthenticated);
  console.log("role?>",role);
  if(!tieneSession){
    return <Navigate to="/login-ejecutivo" />
  }

  // return element
  const permisoRol = "ejecutivo"
  console.log({element, roleR});
  if (roleR && permisoRol !== roleR) {
    // Usuario no tiene el rol necesario para esta ruta
    return <Navigate to="/" replace />;
  }

  return element;
};

function PrivateRoute({ element, requiredRole }) {

  const { user, role, isAuthenticated } = useAuthContext();

  if (!user) {
    if (requiredRole === 'ejecutivo') {
      return <Navigate to="/login-ejecutivo" />;
    }
    return <Navigate to="/login" />;
  }

  if (requiredRole && role !== requiredRole) {
    if (requiredRole === 'ejecutivo') {
      return <Navigate to="/login-ejecutivo" replace />;
    }
    return <Navigate to="/login" replace />;
  }

  return element;
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    // index: true,
    errorElement: <ErrorView />,
  },
  {
    path: "/login-ejecutivo",
    element: <LoginExecutive />,
    errorElement: <ErrorView />,
  },
  {
    // path: "/registrar-perfil",
    path: "/registrar-perfil/:id",
    element: <ProfilePublic />,
    errorElement: <ErrorView />,
  },
  {
    path: "/registrar-perfil-correo",
    element: <ProfilePublicEmail />,
    errorElement: <ErrorView />,
  },
  {
    path: "/crear-cuenta",
    element: <Account />,
    errorElement: <ErrorView />,
  },
  {
    path: "/recuperar",
    element: <RecoverPassword />,
    errorElement: <ErrorView />,
  },
  //test 
  {
    path: "/testmobile",
    element: <TestMobile />,
    // index: true,
    errorElement: <ErrorView />,
  },
  {
    path: "",
    element: <LayoutComponent />,
    errorElement: <ErrorView />,//se agrego al altimo
    children: [
      {
        path: "/",
        //element: <Dashboard />,
        element: <PrivateRoute element={<Dashboard />} requiredRole="normal" />,
      },
      //testeando mantenedor beneficios
      {
        path: "beneficios",
        //element: <Read />,
        element: <PrivateRoute element={<MyBenefits />} requiredRole="normal"/>,
      },
      {
        path: "perfil",
        //element: <Read />,
        // element: <PrivateRoute element={<PruebaFormulario />} requiredRole="normal"/>,
        element: <PrivateRoute element={<ProfilePrivate />} requiredRole="normal"/>,
      },
      {
        path: "perfil/cambiar-clave",
        element: <PrivateRoute element={<ChangePassword />} requiredRole="normal"/>,
      },
      {
        path: "up",
        element: <PrivateRoute element={<UploadTest />} requiredRole="normal"/>,
      },
      {
        path: "dragger",//prueba de drager imagen
        element: <PrivateRoute element={<DraggerTest />} requiredRole="normal"/>,
      },
      {
        path: "read",
        //element: <Read />,
        // element: <PrivateRoute element={<Read />} requiredRole="ejecutivo"/>,
        element: <PrivateRoute element={<DashboardExecutive />} requiredRole="ejecutivo"/>,
      },
      {
        path: "validacion-documentos",
        //element: <Read />,
        // element: <PrivateRoute element={<ValidDocument />} requiredRole="ejecutivo"/>,
        element: <PrivateRoute element={<ContentExcutive />} requiredRole="ejecutivo"/>,
      },
    ]
  }
]);

function LayoutComponent() {
  const { role } = useAuthContext();
  
  return (
    <>
  
      { role === 'ejecutivo' ? <LayoutExecutive/> : <Layout/>}
      {/* <Layout/> */}
      <main>
        <Outlet /> {/* Nested routes render here */}
      </main>
      {/* <footer>Footer Content</footer> */}
    </>
    
  );
}
const clientID = "719034906518-k041pj1og2h93bm9f529i5fas6dr8pbh.apps.googleusercontent.com";//esta es la mia
//cambiar api key por la de tabsa 514603936961-d3i35e6m7o1orf497pfvhot46h67o480.apps.googleusercontent.com
ReactDOM.createRoot(document.getElementById("root")).render(
  <AuthContextProvider>
    <GoogleOAuthProvider clientId="514603936961-d3i35e6m7o1orf497pfvhot46h67o480.apps.googleusercontent.com">
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </GoogleOAuthProvider>
  </AuthContextProvider>,
);

import React, { useEffect,useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import { Table, Space, Tag, Image, Divider,Typography,Alert,Tooltip } from "antd";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import arrowRight from "../img/icon-arrow-right.png";
import arrowLeft from "../img/icon-arrow-left.png";
import greencheck from "../img/green-check.png";
import imgStaticOne from "../img/img-static.png";
import imgStaticTwo from "../img/img-static-two.png";
import iconMore from "../img/icon-more.png";
import action_one from "../img/blue-x.png";
import action_two from "../img/icon-sync.png";
import action_three from "../img/icon-calendar.png";
import action_four from "../img/icon-doble-check.png";
import action_boleta from "../img/icon-boleta.png";
import action_embarque from "../img/icon-embarque.png";
import iconRedX from "../img/red-x.png";

import { CONTENIDO_PERSONA, INFORMACION_PERSONA, TABLA_RESERVAS_CUPONES } from "../services/api";
import { useAuthContext } from "../contexts/authContext";
import FloatingButtons from "../components/FloatingButtons";
import { ErrorModal, InfoModal } from "../utils/modalMsg";
import { encryptReservaPreventa } from "../utils/cryptoUtils";

const { Paragraph } = Typography;

const dayjs = require('dayjs');

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, usuarioSinPermisos} = useAuthContext();
  const [infoValues, setInfoValues] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);
  const [bloqueoInfo, setBloqueoInfo] = useState(false);  //en caso de que no venga la informacion bloquea la accion de editar perfil
  const [inicioConGoogleStatic, setInicioConGoogleStatic] = useState(false);//borrar controlar con varibale de auth
  const [tablaReservasCupones, setTablaReservasCupones] = useState([]);
  const [listaAlertas, setListaAlertas] = useState([]);
  const [listaCarrusel, setListaCarrusel] = useState([]);
  const [listaBeneficios, setListaBeneficios] = useState([]);

  //valores iniciles de la tabla
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0
  });
  // MODAL ERROR
  const [modalErrorVisible, setModalErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // MODAL INFORMACION
  const [modalInfoVisible, setModalInfoVisible] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  //test funcion para sacar de sessio si no tienes permisos  IMPORTANTE!!!!! TEST VALIDAR EN QA
  const sinAutorizacion = () => {
    const verificacionDePermisos = usuarioSinPermisos();

    if(verificacionDePermisos !== "2"){
      //NO TIENE PERMISOS, SE ABRE MODAL CON RESPECTIVO MENSAJE Y UNA VEZ CERRANDO MODAL LO SACA DE LA SESION
      setModalInfoVisible(true);
      setInfoMessage("Su sesión se cerrará porque no ha validado el código SMS enviado a su teléfono.");
      //aqui se le quita de la session
      //navigate("/login");//comentado
      //en este caso cuando se cierre el modal te sacara al login
    }
  };
  const handleOkInfo = () => {
    setModalInfoVisible(false);
    navigate("/login")
  };
  const handleOk = () => {
    setModalErrorVisible(false);
  };

  const ObtenerContenido = async () => {
    const {id_pos, id_persona} = user.usuario.usuario_cuenta[0].personas;
    const objEnviar = {
      "id_pos": id_pos,
      "id_persona":id_persona
    }

    const { data, msg } = await CONTENIDO_PERSONA(objEnviar);
    if (data === null) {
      setErrorMessage(msg);
    } else {
      setListaAlertas(data.contenidoAlert);
      setListaCarrusel(data.contenidoCarrusel);
    }

  };

  const handleCarruselClick = (url) => {
    if (!url) {
      //no tiene url
      return;
    }
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const ObtenerInfoPersona = async () => {
    try {
      if (!user || !user.usuario || !user.usuario.usuario_cuenta || !user.usuario.usuario_cuenta[0].personas) {
        navigate("/login");
        return;
      }
      //aqui se agrega los beneficios que estan desde el login de momento no es dinamico
      console.log("se rellenan los beneficios");
      console.log(user.beneficios, "beneficios");
      setListaBeneficios(user.beneficios);

      const rutUser = user.usuario.usuario_cuenta[0].personas.rut;
      const pasaporteUser = user.usuario.usuario_cuenta[0].personas.pasaporte;
      const emailUser = user.usuario.usuario_cuenta[0].personas.email;
      const datosUserSession = user.usuario.usuario_cuenta[0];
      const inicioGoogle = user.usuario.googleLogin === "1" ? true : false; //si inicia con google es un 1 en caso de inicio normal 0
      //SE PUEDE BUSCAR POR RUT O PASAPORTE
      const obj = {
        "rut" : rutUser !== null ? rutUser : pasaporteUser,
        "email" : emailUser
      }
      const { data, msg } = await INFORMACION_PERSONA(obj);
      if (data === null) {

        setInfoValues({
          nombreInfo: "",
          correoInfo: "",
          docIdInfo: "",
          nacimientoInfo: "",
          idPos: "",
          idPersona: ""
        })
        setBloqueoInfo(true);
        modalErrorVisible(true);
        setErrorMessage(msg)
      } else {
   
        const {fecha_nacimiento,nombre,apellido,email,rut,pasaporte} = data;
        const { id_pos, id_usuarios} = datosUserSession;
        const fechaSplit = fecha_nacimiento.split('-');
        const fechaFormat = `${fechaSplit[2]}-${fechaSplit[1]}-${fechaSplit[0]}`;
        setInfoValues({
          nombreInfo: `${nombre} ${apellido}`,
          correoInfo: `${email}`,
          docIdInfo: rut ? `${rut}` :`${pasaporte}`,
          nacimientoInfo: fechaFormat,
          idPos: id_pos,
          idPersona: id_usuarios
        })
        setInicioConGoogleStatic(inicioGoogle);
        setBloqueoInfo(false);
      }
    } catch (error) {

      modalErrorVisible(true);
      setErrorMessage("Error no controlado. Fallo al encontrar la información del usuario.",)
    }
  };

  const ObtenerTablaReservasCupones = async () => {
      setLoadingTable(true);
      //Obtenemos id pos y id persona
      const {id_pos, id_persona} = user.usuario.usuario_cuenta[0].personas;
      const objEnviar = {
        "id_pos": id_pos,
        "id_persona":id_persona,
        "pag":1
      }

      const { data, msg } = await TABLA_RESERVAS_CUPONES(objEnviar);
      if (data === null) {
        setErrorMessage(msg);
        setLoadingTable(false);
      } else {
        console.log(data.data, "listado");
        let formattedData = [];
        if (data.data && data.data.length > 0) {
          formattedData = data.data.map(item => ({
            key: item.id,
            id_reserva: item.id,
            nombre_tramo: item.nombre_tramo,
            horario_cruce: formatearFechaChilena(item.horario_cruce),
            estado: item.estado,
            dte: item.dte,
            // tarjeta: item.tarjeta,
            tarjeta: item.tarjeta_embarque,
            action: item.acciones
          }));
        }
        console.log(formattedData, "formattedData");
        setTablaReservasCupones(formattedData);
        //setTablaReservasCupones(data.data);
        
        setLoadingTable(false);
        setPagination({
          current: 1,
          // pageSize: 10,
          // total: data.totalPages * 10
          total: data.totalData
        });
      }

  };

  const columns = [
    {
      title: "N° RESERVA",
      dataIndex: "id_reserva",
      key: "id_reserva",
    },
    {
      title: "Ruta",
      dataIndex: "nombre_tramo",
      key: "nombre_tramo",

      render: (text) => <a>{text}</a>,
    },
    {
      title: "Fecha cruce",
      dataIndex: "horario_cruce",
      key: "horario_cruce",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
    },
    {
      title: "Boletas",
      dataIndex: "dte",
      key: "dte",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {/* EL ICONO DE LA BOTELA ES MAS GRANDE */}
          <Image src={action_boleta} width={30} preview={false} />
        </a>
      ),
    },
    {
      title: "Tarjeta Embarque",
      key: "tarjeta",
      dataIndex: "tarjeta",
      render: (text) => {
        if (text.visible) {
          return (
            <a href={text.url} target="_blank" rel="noopener noreferrer">
              <Image src={action_embarque} width={36} preview={false} />
            </a>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "Acciones",
      key: "action",
      width: 180,
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
        {record.action.map((actionItem, index) => {
          // Verificar si el token es true y se modificar la URL
          const url = actionItem.token 
            ? `${actionItem.url}${encryptReservaPreventa(record.id_reserva)}` 
            : actionItem.url;
  
          return (
            <Tooltip title={actionItem.nombre} key={index}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <Image src={actionItem.icono} width={36} preview={false} />
              </a>
            </Tooltip>
          );
        })}
      </Space>
      ),
    }
  ];

  useEffect(() => {
    document.title = "Tabsa | Inicio";
    ObtenerInfoPersona();
    ObtenerTablaReservasCupones();//tabla en fase test con datos estaticos
    ObtenerContenido();//alertas y carrusel
    usuarioSinPermisos();//QUITAR YA QUE SE HACE LO MISMO EN EL SIN sinAutorizacion
    //obtenerDatosPersona(user)
    sinAutorizacion();//testeando
   
  }, [usuarioSinPermisos]);


  function formatearFechaChilena(dateStr) {
    let date;
    
    //(YYYY-MM-DD HH:mm:ss)
    if (dayjs(dateStr, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
        date = dayjs(dateStr, 'YYYY-MM-DD HH:mm:ss');
    }else {
      return dateStr;
    }
    //formato chileno
    return date.format('DD/MM/YYYY HH:mm');
  }

  //QUITAR CONTENIDO ESTATICO DESPUES DE LAS PRUEBAS DE TABSA Y QA IMPORTANTE!!!!! TEST 
  const handleTableChange = async(e) => {
    setLoadingTable(true);

    let pageCurrent = e.current;
    // const objEstaticoBorrar = {
    //   "id_pos": 0,
    //   "id_persona":10478,
    //   "pag": pageCurrent
    // }
    const {id_pos, id_persona} = user.usuario.usuario_cuenta[0].personas;
    const objEnviar = {
      "id_pos": id_pos,
      "id_persona":id_persona,
      "pag":pageCurrent
    }
    const { data, msg } = await TABLA_RESERVAS_CUPONES(objEnviar);
    if (data === null) {
      setErrorMessage(msg);
      setLoadingTable(false);
    } else {
      
      let formattedData = [];
      if (data.data && data.data.length > 0) {
        formattedData = data.data.map(item => ({
          key: item.id,
          id_reserva: item.id,
          nombre_tramo: item.nombre_tramo,
          horario_cruce: formatearFechaChilena(item.horario_cruce),
          estado: item.estado,
          dte: item.dte,
          // tarjeta: item.tarjeta,
          tarjeta: item.tarjeta_embarque,
          action: item.acciones
        }));
      }

      setTablaReservasCupones(formattedData);
      //setTablaReservasCupones(data.data);
     
      setLoadingTable(false);
      setPagination({
        current: pageCurrent,
        // pageSize: 10,
        // total: data.totalPages * 10
        total: data.totalData
      });
    }

  };


  const CustomPrevArrow = ({ onClick }) => {
    return (
      <button className="custom-prev-arrow" onClick={onClick}>
        <Image
          preview={false}
          alt="Previous"
          onClick={onClick}
          src={arrowLeft}
          width={50}
        />
      </button>
    );
  };

  const CustomNextArrow = ({ onClick }) => {
    return (
      <button className="custom-next-arrow" onClick={onClick}>
        <Image
          preview={false}
          alt="Next"
          onClick={onClick}
          src={arrowRight}
          width={50}
        />
      </button>
    );
  };

  //FALTAN EL LISTADO DE BENEFICIOS IMPORTANTE !!! SE ME NOTIFICO QUE ESTA DATA ESTA EN EL OBJ DEL LOGIN
  const beneficiosItems = [
    {
      image: imgStaticOne,
      // image: "https://p325k7wa.twic.pics/high/elden-ring/elden-ring/08-shadow-of-the-erdtree/elden-ring-expansion-SOTE/00-page-content/ERSOTE-header-mobile.jpg?twic=v1/step=10/quality=80/max=760",
      alt: "image",
    },
    {
      image: imgStaticTwo,
      alt: "image",
    },
    // {
    //   image: iconMore,
    //   alt: "icon",A
    // },
  ];

  const onCloseAlert = (index) => {
    setListaAlertas(listaAlertas.filter((_, i) => i !== index));
  };

  return (
    <Container
      // style={{
      //   padding: "5%",
      // }}
      style={{
        padding: "2% 5% 0 5%",
      }}
      fluid
    >
      <Row>

      <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          style={{marginBottom:"40px"}}
        >
          {listaAlertas.map((alerta, index) => (
            <Alert
              key={index}
              description={
                <span style={{ color: 'black', display: 'block', textAlign: 'center', fontWeight: 'bold', lineHeight: '45px', fontSize: '24px',fontFamily: 'Lato, sans-serif' }}>
                  {alerta.contenido}
                </span>
              }
              closeIcon={<Image preview={false} src={iconRedX} alt="close icon" style={{ width: '44px', height: '44px' }} />}
              onClose={() => onCloseAlert(index)}
              style={{ textAlign: 'center', marginBottom:"10px" }}
            />
          ))}
        </Col>
        

        {/* <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
        >
          <FloatingButtons clickOut={showItems}/>
        </Col> */}
        

        {/* <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <h1 className="titulo">Mis Beneficios</h1>
          <Divider />
          <Row gutter={[16, 16]}>
            {beneficiosItems.map((src, index) => (
              <Col
                key={index}
                xxl={2}
                xl={2}
                lg={3}
                md={3}
                xs={6}
                className="beneficios_col_test"
              >
                <Image
                  src={src.image}
                  preview={false}
                  className={src.alt === "icon" ? "beneficios_icon_test" : "beneficios_img_test"}
                />
              </Col>
            ))}
          </Row>
          <Divider />
        </Col> */}

        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <h1 className="titulo">Mis Beneficios</h1>
          <Divider />
          <Row gutter={[16, 16]}>
            {listaBeneficios.map((beneficio,index) => (
              <Col
                key={beneficio.id}
                xxl={2}
                xl={2}
                lg={3}
                md={3}
                xs={6}
                className="beneficios_col_test"
              >
                <Tooltip key={index} title={beneficio.descripcion} color="blue">
                  <Image
                    src={beneficio.imagen}
                    preview={false}
                    className="beneficios_img_test"
                  />
                </Tooltip>
              </Col>
            ))}
          </Row>
          <Divider />
        </Col>

        <Col
          xxl={4}
          xl={4}
          lg={12}
          md={12}
          xs={12}
          className="dashboard_info_col"
          style={{marginBottom:"20px"}}//nuevo
        >
          <Row 
          className="dashboard_info_box"
          >
            <Col xxl={12} xl={12} lg={12} md={12} xs={12} 
            className="dashboard_info_sep"
            >
              <h1 className="dashboard_info_titulo">Mis Datos</h1>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
              <div className="dashboard_info_texto">
                Nombre/Apellido: <b className="texto_bold">{infoValues.nombreInfo}</b>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
              <div className="dashboard_info_texto">
                Correo electrónico: <b className="texto_bold">{infoValues.correoInfo}</b>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
              <div className="dashboard_info_texto">
                Documento: <b className="texto_bold"> {infoValues.docIdInfo}</b>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
              <div className="dashboard_info_texto">
                Fecha de nacimiento: <b className="texto_bold">{infoValues.nacimientoInfo}</b>
              </div>
            </Col>
  
            {
              inicioConGoogleStatic ?
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  className="dashboard_check_sep"
                >
                  <div className="texto_extra"
                    // style={inicioConGoogleStatic ===false ? {display: 'block',marginTop: '80px'} : {display: 'block',marginTop: '80px', opacity:0}}
                    style={inicioConGoogleStatic ? {display: 'block',marginBottom: '-20px'} : {display: 'block', opacity:0}}
                  >
                    <Image src={greencheck} preview={false} width={43} />
                    Usuario iniciado sesión a través de Google.
                  </div>  
                </Col> 
              :
              <Col className="dashboard_check_sep"></Col>
            }
            <Col xxl={12} xl={12} lg={12} md={12} xs={12} className="dashboard_edit_col">
                <Typography>
                  {
                    bloqueoInfo === true ? 
                      <Paragraph className="dashboard_info_link_bloqueo">
                        Editar mis datos
                      </Paragraph>
                    :
                    <Paragraph className="dashboard_info_link">
                      <Link to="/perfil">
                          Editar mis datos
                      </Link>
                    </Paragraph>
                  }
  
                </Typography>
       
            </Col>
          </Row>
        </Col>

        <Col
          xxl={{ span: 7, offset: 1 }}
          xl={{ span: 7, offset: 1 }}
          lg={{ span: 12, offset: 0 }}
          md={{ span: 12, offset: 0 }}
          xs={{ span: 12, offset: 0 }}
          className="carousel_col"
        >
        <Carousel
          // selectedItem={1}
          showThumbs={false} //son la imagenes
          showStatus={false} //contador de imagen
          showIndicators={false} //son los puntitos de imagenes
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && <CustomPrevArrow onClick={onClickHandler} />
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && <CustomNextArrow onClick={onClickHandler} />
          }

        >
          {/* {listaCarrusel.map((item, index) => (
                <div key={index} className="carousel_box">
                <a href={item.href} target="_blank" rel="noopener noreferrer">
                  <img
                    src={item.contenido}
                    alt={`Carousel image ${index + 1}`}
                    className="carousel_img"
         
                  />
                </a>
              </div>
                  ))} */}


              {listaCarrusel.map((item, index) => (
                item.href ? (
                  <Tooltip key={index} title="Haz click" color='blue'>
                    <div key={index} className="carousel_box" onClick={() => handleCarruselClick(item.href)} style={{ cursor: 'pointer' }}>
                      <img
                        src={item.contenido}
                        alt={`Carousel image ${index + 1}`}
                        className="carousel_img"
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div key={index} className="carousel_box">
                    <img
                      src={item.contenido}
                      alt={`Carousel image ${index + 1}`}
                      className="carousel_img"
                    />
                  </div>
                )
              ))}

        </Carousel>
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} xs={12} style={{ marginTop: "30px", marginBottom: "30px" }}>
          <h1 className="titulo">Historial de Reservas/Ventas</h1>
          {/* <Table columns={columnsTest} dataSource={dataTest} bordered={true}  style={{marginTop:"20px"}} scroll={{
            x: 1000,
            y: 400,
          }} /> */}
          {/* <Table
            dataSource={tablaReservasCupones}
            columns={columns}
            style={{marginTop:"20px"}} scroll={{
              x: 1000,
              y: 400,
            }} 
            loading={loadingTable}

            pagination={pagination}
            onChange={(e)=>handleTableChange(e)}
          /> */}
          <Table
            columns={columns}
            dataSource={tablaReservasCupones}
            pagination={{
              ...pagination,
              showSizeChanger: false, // Ocultar el selector de pageSize
            }}
            onChange={(e)=>handleTableChange(e)}
            loading={loadingTable}
            style={{marginTop:"20px"}} scroll={{
              x: 1000,
              y: 400,
            }} 
          />
        </Col>

        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          style={{textAlign:"center"}}
        >
            <FloatingButtons />
        </Col>
      </Row>

      <ErrorModal
        isVisible={modalErrorVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
      <InfoModal
        isVisible={modalInfoVisible}
        onClose={handleOkInfo}
        errorMessage={infoMessage}
      />
    </Container>
  );
};

export default Dashboard;

import CryptoJS from "crypto-js";

const SECRET_KEY = "mi_clave_secreta"; // Clave secreta para encriptar
const SECRET_KEY_RP = "miClaveSecreta123";

const encrypt = (data) => {
  const jsonString = JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(jsonString, SECRET_KEY).toString();
  return encodeURIComponent(encrypted); // URL
};

const decrypt = (ciphertext) => {
  const decodedCiphertext = decodeURIComponent(ciphertext); //URL
  const bytes = CryptoJS.AES.decrypt(decodedCiphertext, SECRET_KEY);
  const jsonString = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(jsonString);
};

const encryptReservaPreventa = (data) => {
  const jsonString = JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(jsonString, SECRET_KEY_RP).toString();
  return encodeURIComponent(encrypted); // URL
};

//TESTAR EN QA
const encryptURLEmail = (data) => {
  const jsonString = JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(jsonString, SECRET_KEY).toString();
  const encoded = encodeURIComponent(encrypted);
  console.log(encoded," <===|||encoded antes del replace /");
  // Reemplazar las barras '/' con espacios
  const safeUrl = encoded.replace(/\//g, ' ');
  return safeUrl;
};

const decryptURLEmail = (encryptedData) => {
  // Revertir los espacios a barras '/'
  console.log("Decrypt first=>",encryptedData);
  // const decoded = encryptedData.replace(/ /g, '/');
  //console.log(decoded, "Aqui se le agregan los / si esq se le sacaron");
  const decrypted = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), SECRET_KEY);
  const jsonString = decrypted.toString(CryptoJS.enc.Utf8);
  console.log(jsonString);
  return JSON.parse(jsonString);
};


export { encrypt, decrypt, encryptReservaPreventa, encryptURLEmail, decryptURLEmail };

export const messages = {
    error: {
      default: "Ocurrió un error.",
      empty: "No se encontraron datos.",
      statusCode: "Error al obtener datos. Código de estado.",
      statusCodeSave: "No se pudieron guardar los datos. Código de estado.",
      needed: "Te falto enviar algún campo.",
      emailExist: "Ya existe un usuario con ese correo.",
      userNotFound: "Usuario no encontrado.",
      incorrectPassword: "Contraseña incorrecta.",
      notAuthorized: "No autorizado.",
      tokenNotValid: "Token no válido.",
      network: 'Error de red. Por favor, verifica tu conexión a Internet.',
      dontSaveImage: 'No se pudo cargar la imagen.'
    },
    success: {
      userLogged: "¡Qué bueno verte de vuelta!",
      authorized: "Autorizado.",
      dataObtained: "Se obtuvieron los datos correctamente.",
      saveImg: "Se cargó correctamente la imagen."
    },
    fields: {
      email: "¡Por favor, introduzca su correo electrónico!",
      emailNotValid: "¡El correo electrónico no es un válido!",
      password: "¡Por favor, introduzca su contraseña!"
    },
    text:{
      changePass: "Para cambiar su contraseña debe cumplir con los mismos criterios de la contraseña actual."
    },

    placeholder:{
      email: "Dirección de correo electrónico",
      password: "Contraseña",
      usuario:"Usuario",

      //cambiar contraseña
      password_old: "Contraseña anterior",
      password_new: "Contraseña nueva",
      password_newConfirm: "Confirmar contraseña nueva",
    }
};

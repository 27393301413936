import React, { useState } from "react";
import Logotipo from "../img/logo_two.svg";
import {
  Button,
  Form,
  Input,
  ConfigProvider,
  Typography,
  Image,
  message
} from "antd";

import { Col, Row, Container } from "react-bootstrap";
import { messages } from "../utils/messages";
import { CAMBIAR_CLAVE_OR_OMITE_LOGIN, RESTABLECER_CLAVE } from "../services/api";
import { ErrorModal, SmsVerificationRecoverModal, SuccessModal } from "../utils/modalMsg";
const { Paragraph, Text } = Typography;

const FormRecoverPassword = () => {
  const [form] = Form.useForm();
  const [btnDisabled, setBtnDisabled] = useState(false);
  // Modal error
  const [modalErrorVisible, setModalErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [modalSuccessVisible, setModalSuccessVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [modalSMSVisible, setModalSMSVisible] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");

  const handleOk = () => {
    setModalErrorVisible(false);
  };
  const handleOkSuccess = () => {
    setModalSuccessVisible(false);
    setModalSMSVisible(true); // Abrir el modal SMS
  };
  const handleOkSMS = () => {
    setModalSMSVisible(false);
  };

  const emailRules = [
    { required: true, message: "¡Por favor, ingrese su correo electrónico!" },
    { type: "email", message: "El correo electrónico no es válido." },
  ];
  const confirmEmailRules = [
      {
        required: true,
        message: "¡Por favor, ingrese el correo electrónico de confirmación!",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("recover_email") === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("¡El nuevo correo electrónico que ingresó no coincide!")
          );
        },
      }),
    ];

  const customInputTheme = {
    components: {
      Input: {
        // padding: "10px 10px 10px 10px",
        colorTextPlaceholder:"#FFFFFF99",//"#FFFFFF",
        colorBgContainer: "#0885C7",
        colorText: "#FFFFFF",
        colorError: "#ff4d4f",
      },
    },
  };
  
  // Función para manejar el envío del formulario
  const onFinish = async(values) => {
    setBtnDisabled(true);
    console.log(values, "onFinish");
    const { recover_email, recover_email2} = values;
    if(recover_email === recover_email2){
      const objRestablecerEnviar = {
        "email_login" : recover_email,
      }
      const respRestablecerClave = await RESTABLECER_CLAVE(objRestablecerEnviar);
      if(respRestablecerClave.data === null){
        setErrorMessage(respRestablecerClave.msg);
        setModalErrorVisible(true);
        setBtnDisabled(false);
      }else{
        setEmailMessage(recover_email)
        setSuccessMessage(respRestablecerClave.msg);
        setModalSuccessVisible(true);
        setBtnDisabled(false);
        //Aqui se abre modal sms. Logica dentro del modal
      }
     
    }else{
      setBtnDisabled(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Complete los datos del formulario de recuperación de contraseña.")
  };


  return (
      <Container className="contenedor_account" fluid>
        <Row>
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            xs={12}
            style={{ background: "white", textAlign: "center" }}
          >
            <Image preview={false} width={200} src={Logotipo} />
          </Col>
        </Row>
  
        <Row className="recover_form_conteiner">
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            xs={12}
            style={{ textAlign: "center" }}
          >
            <Typography>
              <Text className="titulo">Recuperar contraseña</Text>
            </Typography>
          </Col>
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            xs={12}
            className="account_text"
          >
            Para recuperar tu contraseña, asegúrate de cumplir con los siguientes requisitos: tener una cuenta registrada y vinculada a tu número de teléfono.
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
            <Form
              form={form}
              name="dependencies"
              autoComplete="off"
              className="form_account_content"
              layout="vertical"
              initialValues={{
                "recover_email": "",
                "recover_email2":""
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row>
                <ConfigProvider theme={customInputTheme}>

  
                  <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <Form.Item name="recover_email" rules={emailRules}>
                      <Input
                        id="recover_emailInput"
                        placeholder={"Ingresar dirección correo electrónico"}
                        className="recover_input"
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <Form.Item
                      name="recover_email2"
                      dependencies={["recover_email"]}
                      rules={confirmEmailRules}
                    >
                      <Input
                        id="recover_email2Input"
                        placeholder={"Confirmar dirección correo electrónico"}
                        className="recover_input"
                      />
                    </Form.Item>
                  </Col>

  
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    xs={12}
                    style={{ marginTop:"25%"}}
                  >
                    <Form.Item >
                      <Button className="boton_recover" htmlType="submit" disabled={btnDisabled}>
                        Enviar
                      </Button>
                    </Form.Item>
                  </Col>
  
    
                </ConfigProvider>
              </Row>
            </Form>
          </Col>
        </Row>
        <ErrorModal
          isVisible={modalErrorVisible}
          onClose={handleOk}
          errorMessage={errorMessage}
        />
        <SuccessModal
          isVisible={modalSuccessVisible}
          onClose={handleOkSuccess}
          errorMessage={successMessage}
        />
        <SmsVerificationRecoverModal 
          isVisible={modalSMSVisible}
          onClose={handleOkSMS}
          emailMessage={emailMessage}
        />
      </Container>
    );
}

export default FormRecoverPassword
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logotipo from "../img/logo_two.svg";
import { Button, Form, Input, ConfigProvider, Typography, Image } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Row, Container } from "react-bootstrap";
import { messages } from "../utils/messages";
import { useAuthContext } from "../contexts/authContext";
import { LOGIN_ENTRY_EJECUTIVO } from "../services/api";
import { ErrorModal, SuccessModal } from "../utils/modalMsg";
const { Text } = Typography;

const FormLoginExecutive = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { login_executive } = useAuthContext();
  const [errors, setErrors] = useState([]);
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [cargando, setCargando] = useState(false);
  const [modalErrorVisible, setModalErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Modal success
  const [modalSuccessVisible, setModalSuccessVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleOk = () => {
    setModalErrorVisible(false);
  };
  const handleOkSuccess = () => {
    setModalSuccessVisible(false);
    //una vez que la credenciales son correctas se redirige
    navigate("/read");
  };

  const customInputTheme = {
    components: {
      Input: {
        // padding: "10px 10px 10px 10px",
        // fontSize: '16px',
        // borderColor: 'red',
        // colorTextPlaceholder: "white",
        // colorBgContainer: "#00276c",
        // colorText: "white",
        // colorTextPlaceholder: "#FFFFFF",
        // colorBgContainer: "#0885C7",
        // colorText: "#FFFFFF",
        // colorError: "#ff4d4f",
      },
    },
  };

  const handleSubmit = async (values, tipoCuenta) => {
    setCargando(true)
    console.log("handleSubmit", values, tipoCuenta);
    const {usuario, password} = values;

    const objAccess ={
      "usuario": usuario,
      "password": password
    }
  
    const respExecutiveAccess = await LOGIN_ENTRY_EJECUTIVO(objAccess);
    console.log(respExecutiveAccess, "respExecutiveAccess");
    if(respExecutiveAccess.data === null){
      console.log("no tiene acceso");
      setModalErrorVisible(true);
      setErrorMessage(respExecutiveAccess.msg);
      setCargando(false);
    }else{
      // login_executive("seba ejecutivo");
      // navigate("/read");
      login_executive(respExecutiveAccess.data);
      setModalSuccessVisible(true);
      setSuccessMessage(respExecutiveAccess.msg);
      setCargando(false);
    }
 
  };

  return (
    <Container className="contenedor_login" fluid>
      <Row>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          className="login_logotipo"
        >
          <Image preview={false} width={200} src={Logotipo} />
        </Col>
      </Row>

      <Row className="login_executive_conteiner">
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Typography>
            <Text className="titulo_black">Acceso ejecutivo</Text>
          </Typography>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Form
            className="form_content_executive"
            // name="normal_login"
            onFinish={handleSubmit}
            //nuevo
            form={form}
            name="ejecutivo_login"
            autoComplete="off"
            layout="vertical"
          >
            <ConfigProvider theme={customInputTheme}>
              <Form.Item
                id="usuario"
                name="usuario"
                rules={[
                  {
                    required: true,
                    message: "¡Por favor, introduzca su usuario!",
                  },
                ]}
              >
                <Input
                  id="usuario"
                  name="usuario"
                    autoComplete="new-email"
                  prefix={<UserOutlined />}
                  placeholder={messages.placeholder.usuario}
                  onChange={(event) => setUsuario(event.target.value)}
                  className="login_input"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: messages.fields.password,
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  autoComplete="new-password"
                  prefix={<LockOutlined />}
                  placeholder={messages.placeholder.password}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  className="login_input"
                />
              </Form.Item>
            </ConfigProvider>

            <Form.Item className="fi_display margin_dt_ex">
              <Button
                className="boton_executive_login"
                htmlType="submit"
                loading={cargando}
              >
                Acceder como ejecutivo
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <ErrorModal
        isVisible={modalErrorVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
      <SuccessModal
        isVisible={modalSuccessVisible}
        onClose={handleOkSuccess}
        errorMessage={successMessage}
      />
    </Container>
  );
};

export default FormLoginExecutive;

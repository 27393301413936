import { Button } from 'antd';
import React,  { useEffect }  from 'react'
import { Col, Row, Container } from "react-bootstrap";


const TestMobile = () => {
    useEffect(() => {
        document.title = "Tabsa | Test mobile"
    }, [])
    const handleRedirect = () => {
        const userAgent = navigator.userAgent;
 
        if (/iPhone|iPad|iPod/i.test(userAgent)) {
          console.log('Dispositivo iOS detectado');
          window.location.href = 'https://www.wikipedia.org';
        } else if (/Android/i.test(userAgent)) {
          console.log('Dispositivo Android detectado');
          window.ReactNativeWebView.postMessage("Dispositivo Android detectado");
          //si no logra abrir el mapa, abrira la app
          // window.location.href = 'comgooglemaps://';
          // setTimeout(() => {
          //   window.location.href = 'https://play.google.com/store/apps/details?id=cl.tabsaApp.app&hl=es_AR';
          // }, 1500);
        } else {
          console.log('Navegador de escritorio detectado');
          window.location.href = 'https://www.google.com';
        }
      };
  return (
    <Container 
    style={{
        padding: "5%",
    }}
    fluid
    >
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
        <Button type="primary" onClick={handleRedirect}>
            Abrir App Móvil Hans
        </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default TestMobile
import React,  { useEffect }  from 'react'
import { Col, Row, Container } from "react-bootstrap";
import FormRecoverPassword from '../components/FormRecoverPassword';


const RecoverPassword = () => {
    useEffect(() => {
        document.title = "Tabsa | Crear cuenta"
      }, [])
  return (
    <Container 
    style={{
        padding: "5%",
    }}
    fluid
    >
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <FormRecoverPassword />
        </Col>
      </Row>
    </Container>
  )
}

export default RecoverPassword
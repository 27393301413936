import React,  { useEffect }  from 'react'
import { Col, Row, Container } from "react-bootstrap";
import FormLogin from '../components/FormLogin';

const Login = () => {
  useEffect(() => {
    document.title = "Tabsa | Login"
  }, [])
  
  return (
    <Container 
    className='login_background'
    fluid
    >
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <FormLogin />
        </Col>
      </Row>
    </Container>
  )
}

export default Login
//TESTING
const url_base = "https://api-cliente.tabsa.cl/api";
const url_tool = "https://api-tool.tabsa.cl/api";
const url_save_doc = "https://recursos.tabsa.cl/api/file/save";

//PRODUCTIVO
//const url_base = "https://api-cliente.tabsa.cl/api";
//const url_tool = "https://api-tool.tabsa.cl/api"; 
// https://api-cliente.dev.tabsa.cl/api/pc/persona/login
//ENDPOINT - LOGIN
export const API_LOGIN = `${url_base}/pc/persona/login`;//

//ENDPOINT - REGISTRO/SIGNUP DE CUENTA DE USUARIO Y PERFIL DE USUARIO
export const API_CREATE_CUENTA = `${url_base}/usuarioPersona`; //Crea un cuenta de usuario
export const API_CREATE_PERFIL_CUENTA = `${url_base}/pc/persona/insertar`; //Crea los datos de perfil del usuario (RUN, NOMBRE, TELEFONO...)
export const API_VALIDATE_SMS = `${url_base}/pc/checkCode`; //Validacion numero de telefono sms https://api-cliente.dev.tabsa.cl/api/pc/checkCode
export const API_ASIGNAR_CUENTA = `${url_base}/usuarioPersona/asignar`; //Union de cuenta de usuario y su perfil.
export const API_REENVIAR_CODIGO = `${url_base}/pc/resendCode`; // reeviar codigo via sms https://api-cliente.dev.tabsa.cl/api/pc/resendCode
export const API_CAMBIAR_TELEFONO = `${url_base}/pc/persona/cambiarTelefono`; //https://api-cliente.dev.tabsa.cl/api/pc/persona/cambiarTelefono
export const API_RESTABLECER_CLAVE = `${url_base}/pc/usuarios/restablecerClave`; //https://api-cliente.dev.tabsa.cl/api/pc/usuarios/restablecerClave
export const API_CAMBIAR_CLAVE = `${url_base}/pc/usuarios/cambiarClave`; //https://api-cliente.dev.tabsa.cl/api/pc/usuarios/cambiarClave

//ENDPOINT - EDITAR PERFIL Y MOSTRAR INFORMACION
export const API_EDIT_PERFIL_CUENTA= `${url_base}/pc/persona/update`; //Edición de perfil del usuario (RUN, NOMBRE, TELEFONO...)https://api-cliente.dev.tabsa.cl/api/pc/persona/update
export const API_INFO_PERSONA = `${url_base}/pc/persona/informacion`; //Informacion de los datos de la persona

//ENDPOINT - DASHBOARD
export const API_TABLA_RESERVAS_CUPONES = `${url_base}/pc/tablaReservasCupones`; //https://api-cliente.dev.tabsa.cl/api/pc/tablaReservasCupones
export const API_CONTENIDO_PERSONA = `${url_base}/contenido/listarContenidoPersona`; //https://api-cliente.dev.tabsa.cl/api/contenido/listarContenidoPersona
export const API_EDIT_USER = `${url_base}/persona/update`; //Edición de usuarios persona
export const API_PAISES = `${url_tool}/lista/paises`; //Edición de usuarios persona



//ENDPOINT - BENEFICIOS
export const API_BENEFICIOS = `${url_base}/beneficios`;//https://api-cliente.dev.tabsa.cl/api/beneficios SELECT
export const API_SAVE_DOC = `${url_save_doc}`; //Cargar documentos ".jpg,.png,.pdf"
export const API_BENEFICIO_LISTA = `${url_base}/beneficios/listar`; //Lista de documentos por usuario
export const API_BENEFICIO_CREAR = `${url_base}/beneficios/crear`; //Edición de usuarios persona
export const API_BENEFICIO_APROBAR = `${url_base}/beneficios/aprobar`; //Edición de usuarios persona
export const API_BENEFICIO_RECHAZAR = `${url_base}/beneficios/rechazar`; //Edición de usuarios persona
export const API_BENEFICIOS_ALL = `${url_base}/beneficios/listarAll`; //Lista de beficios vista ejecutiva(GET)
//https://api-cliente.dev.tabsa.cl/api/beneficios/listarAll


//ENDPOINT - EJECUTIVO
export const API_EJECUTIVO_CONTENIDO = `${url_base}/contenido/listarContenido`; //contenido del ejecutivo (GET)
export const API_EJECUTIVO_CONDICIONES = `${url_base}/contenido/listarCondiciones`; // PREGUNTAR COMO USAR  (GET)
export const API_EJECUTIVO_AGREGAR_CONTENIDO = `${url_base}/contenido/agregarContenido`; // (POST)
export const API_EJECUTIVO_DESACTIVAR_CONTENIDO = `${url_base}/contenido/desactivarContenido`; //  (POST)
// https://api-cliente.dev.tabsa.cl/api/contenido/listarContenido
// https://api-cliente.dev.tabsa.cl/api/contenido/listarCondiciones 
// https://api-cliente.dev.tabsa.cl/api/contenido/agregarContenido
// https://api-cliente.dev.tabsa.cl/api/contenido/desactivarContenido


export const API_LOGIN_EJECUTIVO = `https://ldap.tabsa.cl/api/auth`;
//https://ldap.tabsa.cl/api/auth






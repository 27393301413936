import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, DatePicker, Select, Radio, Image,message } from "antd";
import { INFORMACION_PERSONA, GET_PAISES, EDITAR_PERFIL_CUENTA, CAMBIAR_TELEFONO } from "../services/api";
import greencheck from "../img/green-check.png";
import bluex from "../img/blue-x.png";
import "../css/label.css"; // Importa tu archivo CSS personalizado

import { ErrorModal, InfoModal, SmsVerificationInternoModal, SuccessModal } from "../utils/modalMsg";
import { useAuthContext } from "../contexts/authContext";

import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import updateLocale from "dayjs/plugin/updateLocale";
import LocaleES from "antd/lib/date-picker/locale/es_ES";
dayjs.extend(updateLocale);

const { Option } = Select;

const FormProfilePrivate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { isAuthenticated, user, updateStatusUser,usuarioSinPermisos} = useAuthContext();
  const [idType, setIdType] = useState("rut");
  const [formData, setFormData] = useState({});
  const formKey = formData ? JSON.stringify(formData) : "default";
  const [nacionalidades, setNacionalidades] = useState([]);
  const [paises, setPaises] = useState([]);
  const [codigoAreas, setCodigoAreas] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [inicioConGoogleStatic, setInicioConGoogleStatic] = useState(false); //borrar controlar con varibale de auth
  const [userImportant, setUserImportant] = useState({}); //datos importantes del usuario id pos, id cuenta/persona y email
  const [telefonoSinEditar, setTelefonoSinEditar] = useState("");//validar si el telefono cambio o no

  // Modal error
  const [modalErrorVisible, setModalErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [modalSuccessVisible, setModalSuccessVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [modalSMSVisible, setModalSMSVisible] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [phoneMessage, setPhoneMessage] = useState("");

    // Modal info
    const [modalInfoVisible, setModalInfoVisible] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
  // const [primerTelefono, setPrimerTelefono] = useState("");//ese valor es para verificar si cambio el telefono en formulario

  // const [permisoUsuario, setPermisoUsuario] = useState(null);//simulacion

  // Modal error
  const handleOk = () => {
    setModalErrorVisible(false);
  };
  const handleOkSuccess = () => {
    setModalSuccessVisible(false);
    //setModalSMSVisible(true); // Abrir el modal SMS
  };
  const handleOkSMS = () => {
    setModalSMSVisible(false);
    //SE HACE UNA VERIFICACION AL USUARIO SI TIENE LOS PERMISOS PARA SEGUIR NAVEGANDO
    const verificacionDePermisos = usuarioSinPermisos();
    console.log("verificacionDePermisos=>",verificacionDePermisos);
    if(verificacionDePermisos !== "2"){
      setInfoMessage("Por motivos de seguridad, su cuenta será cerrada ya que no ha verificado su número de teléfono.");
      setModalInfoVisible(true);
    }
  };
  const handleOkInfo = () => {
    //CIERRA EL MODAL Y TE REDIRIGE AL LOGIN
    setModalInfoVisible(false);
    navigate("/login");
  };
  const redirectDashboard = () => {
    navigate("/");
  };
  const redirectChangePassword = () => {
    navigate("/perfil/cambiar-clave");
  };

  
  //quitar obj estatico y colocar el que venga del login del localstorage******
  const ObtenerPerfilPersona = async () => {
    try {
      if(isAuthenticated !== true && !user){
        setBtnDisabled(true);
        navigate("/login");
      }else{
        const inicioGoogle = user.usuario.googleLogin === "1" ? true : false; //si inicia con google es un 1 en caso de inicio normal 0
        const usuarioIdPersona = user.usuario.usuario_cuenta[0].id_cuenta;
        const usuarioIdPos = user.usuario.usuario_cuenta[0].id_pos;
        const rutUser = user.usuario.usuario_cuenta[0].personas.rut;
        const pasaporteUser = user.usuario.usuario_cuenta[0].personas.pasaporte;
        const emailCuenta = user.usuario.email_login; //Es el email con el cual inicio sesion no se altera!

        const obj = {
          rut: rutUser !== null ? rutUser : pasaporteUser,
          email: emailCuenta,
        };
        const { data, msg } = await INFORMACION_PERSONA(obj);
        if (data === null) {
          setErrorMessage(msg);
          setModalErrorVisible(true);
          setBtnDisabled(true);
        } else {
          const {
            email,
            telefono,
            rut,
            pasaporte,
            nombre,
            apellido,
            fecha_nacimiento,
            sexo,
            nacionalidad,
            residencia_direccion,
            residencia_ciudad,
            residencia_comuna,
            residencia_pais,
          } = data;
       
          const partesTelefono = telefono.split(" ");
          const codigoArea = partesTelefono[0]; 
          const numeroTelefono = partesTelefono[1];
          // const primerTelefonoCompleto = `${codigoArea} ${numeroTelefono}`
        
          let objData ={
            email,
            // area: codigoArea,
            // telefono:numeroTelefono,
            rut,
            pasaporte,
            nombre,
            apellido,
            fecha_nacimiento,
            sexo,
            nacionalidad,
            residencia_direccion,
            residencia_ciudad,
            residencia_comuna,
            residencia_pais,
          }
          
          let initialValues = {
            email: emailCuenta || undefined,
            telefono: numeroTelefono || undefined,
            nombre:nombre || undefined,
            apellido: apellido || undefined,
            fecha_nacimiento: dayjs(fecha_nacimiento) || undefined,
            sexo: sexo || undefined,
            nacionalidad: nacionalidad
              ? parseInt(nacionalidad)
              : undefined,
            rut: rut || "",
            pasaporte: pasaporte || "",
            residencia_pais: residencia_pais || undefined,
            residencia_ciudad: residencia_ciudad || undefined,
            residencia_comuna: residencia_comuna || undefined,
            residencia_direccion: residencia_direccion || undefined,
            Identificacion: !rut ? "pasaporte" : "rut",
            area: codigoArea,
          };
          setFormData(initialValues);
          !rut ? setIdType("pasaporte") : setIdType("rut");
          setInputDisabled(true);
          setBtnDisabled(false);
          setInicioConGoogleStatic(inicioGoogle);
          setUserImportant({
            id_pos: usuarioIdPos,
            id_persona: usuarioIdPersona,
            email_cuenta: emailCuenta
          });
          setTelefonoSinEditar(telefono);
        }
      }


    } catch (error) {
      setErrorMessage(error.message);
      setModalErrorVisible(true);
      setBtnDisabled(true);
    }
  };
  const ObtenerNacionalidad = async () => {
    try {
      const { data, msg } = await GET_PAISES();
      if (data === null) {
        setErrorMessage(msg);
        setModalErrorVisible(true);
        // setBtnDisabled(true);
      } else {
        setNacionalidades(data);
        setPaises(data);
        setCodigoAreas(data);

        // setBtnDisabled(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setModalErrorVisible(true);
      // setBtnDisabled(true);
    }
  };

  // Función para manejar el envío del formulario
  const onFinish = async(values) => {
    // Convertir fecha de nacimiento a string en formato 'YYYY-MM-DD'
    const {id_pos, id_persona, email_cuenta} = userImportant;
    const formattedValues = {
      ...values,
      fecha_nacimiento: values.fecha_nacimiento.format("YYYY-MM-DD"),
      telefono_completo: `${values.area.trim()} ${values.telefono.trim()}`,
      id_pos: id_pos,
      id_persona: id_persona,
      email_cuenta: email_cuenta

    };
    
    const editarResp = await EDITAR_PERFIL_CUENTA(formattedValues)

    if(editarResp.data === null){
      setModalErrorVisible(true);
      setErrorMessage(editarResp.msg);
    }else{
      //SE ACTUALIZA DATOS DE PERFIL Y SI EL TELEFONO ES DISTINTO APLICA EL CAMBIO DE TELEFONO Y VERIFICAACION DE SMS
      console.log(telefonoSinEditar,"===", formattedValues.telefono_completo);
      if(telefonoSinEditar !== formattedValues.telefono_completo){
        const telefonoSMS = formattedValues.telefono_completo;
        const emailSMS = formattedValues.email_cuenta;
    
        let objEnviar = {
          "id_pos" : id_pos,
          "id_persona" : id_persona,
          "email_login" : emailSMS,
          "telefono" : telefonoSMS
        }
        
        const respTelefono = await CAMBIAR_TELEFONO(objEnviar);
        const {data, msg} = respTelefono;
        if(respTelefono.data === null){
          message.error(respTelefono.msg);
          setModalErrorVisible(true);
          setErrorMessage(msg);
        }else{

          //SI EL TELEFONO SE CAMBIA EXITOSAMENTE SE APLICAR UN CAMBIO DE ESTADO DEL USUARIO A ESTADO 4 DE "VERIFICACION CODIGO SMS"
          updateStatusUser("4");
          message.success(msg);
          setEmailMessage(emailSMS);
          setPhoneMessage(telefonoSMS);
          setModalSMSVisible(true);      
        }
      }else{
        //AQUI SOLO SE ACTUALIZA EL PERFIL MENOS EL TELEFONO
        console.log(editarResp.data);
        setModalSuccessVisible(true);
        setSuccessMessage(editarResp.msg);
        const emailCuenta = user.usuario.email_login; //Es el email con el cual inicio sesion no se altera!
        const dataActualizada = editarResp.data;
        //DIVIDIR TELEFONO Y AREA
        const partesTelefono = dataActualizada.telefono.split(" ");
        const codigoArea = partesTelefono[0]; 
        const numeroTelefono = partesTelefono[1];
        let initialUpdateValues = {
          email: emailCuenta || undefined,
          telefono: numeroTelefono || undefined,
          nombre: dataActualizada.nombre || undefined,
          apellido: dataActualizada.apellido || undefined,
          fecha_nacimiento: dayjs(dataActualizada.fecha_nacimiento) || undefined,
          sexo: dataActualizada.sexo || undefined,
          nacionalidad: dataActualizada.nacionalidad
            ? parseInt(dataActualizada.nacionalidad)
            : undefined,
          rut: dataActualizada.rut || "",
          pasaporte: dataActualizada.pasaporte || "",
          residencia_pais: dataActualizada.residencia_pais || undefined,
          residencia_ciudad: dataActualizada.residencia_ciudad || undefined,
          residencia_comuna: dataActualizada.residencia_comuna || undefined,
          residencia_direccion: dataActualizada.residencia_direccion || undefined,
          Identificacion: !dataActualizada.rut ? "pasaporte" : "rut",
          area: codigoArea,
        };
        setFormData(initialUpdateValues);
        console.log("aqui se coloca el teleno que se actualizo",dataActualizada.telefono);
        setTelefonoSinEditar(dataActualizada.telefono);
      }

    }
 

    
  };

  //REGLAS PARA CONTROLAR FORMULARIO
  const emailRules = [
    {
      type: "email",
      message: "¡No es un email válido!",
    },
    {
      required: true,
      message: "¡Por favor ingrese su correo electrónico!",
    },
    {
      max: 50,
      message: "'Email' no puede tener más de 50 caracteres",
    },
  ];
  const rutRules = [
    { required: true, message: "¡Por favor ingrese su RUT!" },
    {
      pattern: new RegExp("^\\d{7,8}-[kK\\d]$"),
      message: "El RUT debe estar en el formato correcto (Ej: 12345678-K).",
    },
  ];
  const pasaporteRules = [
    { required: true, message: "¡Por favor ingrese su pasaporte!" },
  ];
  const areaRules = [
    //{ required: true, message: "¡Por favor ingrese su codigo de area!" },
    { required: true,message: "¡Codigo area requerido!",},
  ];
  const telefonoRules = [
    {
      required: true,
      message: "Por favor ingresa tu número de teléfono!",
    },
    {
      pattern: /^[0-9]+$/,
      message: "El número de teléfono solo debe contener números!",
    },
    {
      max: 20,
      message: "'Teléfono' no puede tener más de 20 caracteres",
    },
  ];
  const nombreRules = [
    { required: true, message: "¡Por favor ingrese su nombre!" },
  ];
  const apellidoRules = [
    { required: true, message: "¡Por favor ingrese su apellido!" },
  ];
  const fechaNacimientoRules = [
    {
      required: true,
      message: "¡Por favor seleccione una fecha de nacimiento!",
    },
  ];
  const sexoRules = [
    {
      required: true,
      message: "¡Por favor seleccione un sexo!",
    },
  ];
  const nacionalidadRules = [
    {
      required: true,
      message: "¡Por favor seleccione su nacionalidad!",
    },
  ];
  const direccionRules = [
    { required: true, message: "¡Por favor ingrese su dirección!" },
  ];
  const ciudadRules = [
    { required: true, message: "¡Por favor ingrese su ciudad!" },
  ];
  const comunaRules = [
    { required: true, message: "¡Por favor ingrese su comuna!" },
  ];
  const paisRules = [
    {
      required: true,
      message: "¡Por favor seleccione su país!",
    },
  ];

  // Función para manejar errores en el envío
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //test funcion para sacar de sessio si no tienes permisos
  const sinAutorizacion = () => {
    const verificacionDePermisos = usuarioSinPermisos();
    console.log("Se ejecuto la funcion de verificacion de permisos",verificacionDePermisos);
    if(verificacionDePermisos !== "2"){
      console.log("no tienes permisos!!!!!");
      navigate("/login")
    }
  };

  useEffect(() => {
    // document.title = "Tabsa | Test";
    // verificarPermisos();
    ObtenerPerfilPersona();
    ObtenerNacionalidad();
    usuarioSinPermisos();
    sinAutorizacion();//Testeando
  }, [usuarioSinPermisos]);

  return (
    <Container
    //   style={{
    //     padding: "5%",
    //   }}
      fluid
    >
      <Row>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          style={{ textAlign: "right" }}
        >
          {isAuthenticated ? (
            <Image
              preview={false}
              src={bluex}
              width={68}
              style={{ cursor: "pointer" }}
              onClick={redirectDashboard}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>

      <Row>
        <Col
          xxl={6}
          xl={6}
          lg={6}
          md={6}
          xs={6}
          style={{ marginBottom: "20px" }}
        >
          <h1 className="titulo">Perfil</h1>
        </Col>
      </Row>

      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
        <Form
            form={form}//se coloco despues 11-06-24 fecha ref borrar test
            key={formKey}
            initialValues={formData}
            // initialValues={{
            //   email: usuario.email_login,
            //   Identificacion: "rut",
            //   area: "+56",
            // }}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            layout="vertical"
            requiredMark={false} // Esto oculta todos los asteriscos

          >
            <Row>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="Email"
                  name="email"
                  rules={emailRules}
                >
                  <Input placeholder=" " disabled={inputDisabled} />
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Row>
                  <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    xs={6}
                    className="col-floating"
                  >
                    <Form.Item
                      label="Area"
                      name="area"
                      className="floating-label-form-item"
                      rules={areaRules}
                  
                    >
                      <Select placeholder="Selecciona área" allowClear 
                      //disabled={inputDisabled} 
                      >
                        {codigoAreas
                          .filter((cod) => cod.codigo_telefono !== null)
                          .map((cod) => (
                            <Option
                              key={cod.codigo}
                              value={cod.codigo_telefono}
                            >
                              {cod.codigo_telefono + " " + cod.nombre}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    xs={6}
                    className="col-floating"
                  >
                    <Form.Item
                      className="floating-label-form-item"
                      label="Teléfono"
                      name="telefono"
                      rules={telefonoRules}
                    >
                      <Input 
                        //disabled={inputDisabled} 
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {inicioConGoogleStatic ? (
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <div className="texto_extra" style={{ marginTop: "5px" }}>
                    <Image preview={false} src={greencheck} width={43} />{" "}
                    Usuario iniciado sesión a través de Google.
                  </div>
                </Col>
              ) : (
                <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Button className="boton_change_password" onClick={()=> redirectChangePassword()}>
                  Cambiar contraseña
                </Button>
              </Col>
              )}
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  label={"Identificación"}
                  name={"Identificacion"}
                  className="floating-label-radio-form-item"
                >
                  <Radio.Group
                    // defaultValue="rut"
                    onChange={(e) => setIdType(e.target.value)}
                    disabled={true}
                  >
                    <Radio value="rut" >RUT</Radio>
                    <Radio value="pasaporte">Pasaporte</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                {idType === "rut" && (
                  <Form.Item
                    className="floating-label-form-item"
                    label="RUT"
                    name="rut"
                    rules={rutRules}
                  >
                    <Input disabled={inputDisabled}  />
                  </Form.Item>
                )}

                {idType === "pasaporte" && (
                  <Form.Item
                    className="floating-label-form-item"
                    label="Pasaporte"
                    name="pasaporte"
                    rules={pasaporteRules}
                  >
                    <Input  disabled={inputDisabled} />
                  </Form.Item>
                )}
              </Col>

              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="Nombre"
                  name="nombre"
                  rules={nombreRules}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="Apellido"
                  name="apellido"
                  rules={apellidoRules}
                >
                  <Input  />
                </Form.Item>
              </Col>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="Fecha de Nacimiento"
                  name="fecha_nacimiento"
                  rules={fechaNacimientoRules}
                >
                  <DatePicker
                    // format="YYYY-MM-DD" // Establecer el formato de fecha
                    format="DD-MM-YYYY"
                    locale={LocaleES}
             
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="Sexo"
                  name="sexo"
                  rules={sexoRules}
                >
                  <Select placeholder="Selecciona tu sexo" allowClear >
                    <Option value="1">Masculino</Option>
                    <Option value="2">Femenino</Option>
                    {/* Agregar más opciones según sea necesario */}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs="12" style={{marginBottom:"15px"}}>
              <h3 className="subtitulo">Residencia</h3>
              </Col>
            
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="Nacionalidad"
                  name="nacionalidad"
                  rules={nacionalidadRules}
                >
                  <Select placeholder="Selecciona tu nacionalidad" allowClear >
                    {nacionalidades.map((nacionalidad) => (
                      <Option
                        key={nacionalidad.codigo}
                        value={nacionalidad.codigo}
                      >
                        {nacionalidad.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              
         
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="Ciudad de Residencia"
                  name="residencia_ciudad"
                  rules={ciudadRules}
                >
                  <Input  />
                </Form.Item>
              </Col>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="Comuna de Residencia"
                  name="residencia_comuna"
                  rules={comunaRules}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="Dirección de Residencia"
                  name="residencia_direccion"
                  rules={direccionRules}
                >
                  <Input placeholder="direccion ingresa" />
                </Form.Item>
              </Col>
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="col-floating"
              >
                <Form.Item
                  className="floating-label-form-item"
                  label="País de Residencia"
                  name="residencia_pais"
                  rules={paisRules}
                >
                  <Select placeholder="Selecciona un país" allowClear >
                    {paises.map((pais) => (
                      <Option key={pais.codigo} value={pais.nombre}>
                        {pais.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              xs={12}
              style={{ textAlign: "center", marginTop: "20px" }}
            >

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={btnDisabled}
                className="boton_primario btn_medidas"
              >
                Guardar
              </Button>
          </Form.Item>
 
     
            </Col>
          </Form>
        </Col>
      </Row>

      {/* <ErrorModal
        isVisible={modalErrorVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      /> */}
      <SuccessModal
        isVisible={modalSuccessVisible}
        onClose={handleOkSuccess}
        errorMessage={successMessage}
      />
      <ErrorModal
        isVisible={modalErrorVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
      <SmsVerificationInternoModal
        isVisible={modalSMSVisible}
        onClose={handleOkSMS}
        phoneMessage={phoneMessage}
        emailMessage={emailMessage}
      />
      <InfoModal
        isVisible={modalInfoVisible}
        onClose={handleOkInfo}
        errorMessage={infoMessage}
      />
    </Container>
  );
};

export default FormProfilePrivate;

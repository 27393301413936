import React, { useState } from "react";
import { Button, Drawer, Menu, Avatar, ConfigProvider, Image } from "antd";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LINKS_MENU_EXECUTIVE } from "../shared/variables";
import { AntDesignOutlined } from "@ant-design/icons";
import { useAuthContext } from "../contexts/authContext";

import icon_user from "../img/user-icon.png";
import icon_menu from "../img/menu-icon.png";
import logotipo from "../img/logo_two.svg";
import icon_logout from "../img/icon-logout.png";

const LayoutExecutive = () => {


  const { logout, user} = useAuthContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  ///codigo de prueba(BORRAR)
  const userDataString = localStorage.getItem("token");
  const userData = JSON.parse(userDataString);
  // console.log("Layout TOKEN=>", userData, "USER=>",user);

  //ACCION CERRAR SESION
  const handleLogoutExecutive = async () => {
    logout();
    navigate("/login-ejecutivo");
  };

  //ESTILO DEL MENU 
  const customInputTheme = {
    components: {
      Drawer: {
        colorBgElevated: "#FFFAE7",
        colorBgMask: "rgba(255, 250, 231 , 0.5)",
      },
      Button: {
        colorLinkHover: "#ffffff",
      },
      Menu: {
        darkItemBg: "#FFFAE7",
      },
    },
  };

  //CONTROLAR MENU DRAWER
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  //NAVEGACION

  const items = LINKS_MENU_EXECUTIVE.map(({ label, route, title,imagen }) => ({
    key: route,
    label: (
      <Link to={route} key={title} className="menu_executive_links">
        <Image src={imagen} preview={false} style={{margin:"10px", width:"24px",height:"24px"}}/>{label}
      </Link>
    ),
  }));


  return (
    <Container
      className="layout_executive_conteiner"
      fluid
    >
    <ConfigProvider theme={customInputTheme}>
      <Row
      className="row_layout"
      style={{paddingTop:"1%"}}
      >
        <Col
          xxl={9}
          xl={9}
          lg={9}
          md={9}
          xs={11}
          className="left_alg"
        >
          <Image preview={false} src={logotipo} className="layout_logotipo"/>
        </Col>

        <Col
          xxl={{ span: 3, offset: 0 }}
          xl={{ span: 3, offset: 0 }}
          lg={{ span: 3, offset: 0 }}
          md={{ span: 3, offset: 0 }}
          xs={{ span: 1, offset: 0 }}
          // className="right_alg"
          style={{display:"flex",textAlign:"right",justifyContent:"flex-end"}}
        >

<Row className="mi_perfil_display">
            <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
              <Image preview={false} src={icon_user} className="layout_img_perfil"  />
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} xs={12} >
              <h3 className="layout_text">Mi Perfil</h3>
            </Col>
          </Row>
          <Image
            preview={false}
            src={icon_menu}
            className="layout_img_menu"
            style={{
            marginLeft:"18px"}}

            onClick={showDrawer}
            onDoubleClick={onClose}
          />

            <Drawer
              // title="Sistema Clientes Ejecutivos"
              // placement="right"
              // onClose={onClose}
              // open={open}
              // zIndex={500}
              // width={320}
              title={<Image preview={false} src={logotipo} width={133}/>}
              placement="right"
              onClose={onClose}
              open={open}
              zIndex={500}
              width={320}
              height={200}
              closeIcon={false}
              footer={<Button onClick={handleLogoutExecutive} block style={{margin:"2px", background:"transparent", border:"none",fontFamily: 'Lato, sans-serif'}}>Cerrar sesión <Image src={icon_logout} preview={false} width={26} /></Button>}
              
            >
              <Row 
              // className="center_alg"
              >
                {/* <Col xs={12}>
                  <Avatar
                    size={{
                      xs: 100,
                      sm: 100,
                      md: 100,
                      lg: 100,
                      xl: 100,
                      xxl: 100,
                    }}
                    icon={<AntDesignOutlined />}
                  />
     
                </Col> */}
                <Col xs={12} className="xs">
                  <Menu
                    mode="inline"
                    // theme="dark"
                    items={items}
                  ></Menu>
                </Col>
                {/* <Col xs={12}>
                  <Button onClick={handleLogoutExecutive}>Cerrar sesion</Button>
                </Col> */}
              </Row>
            </Drawer>
        </Col>
      </Row>
    </ConfigProvider>
    </Container>
  );
};

export default LayoutExecutive;

import React,  { useEffect }  from 'react'
import { Col, Row, Container } from "react-bootstrap";
import FormChangePassword from '../components/FormChangePassword';

const ChangePassword = () => {
    useEffect(() => {
        document.title = "Tabsa | Cambiar contraseña"
    }, [])
  return (
    <Container 
    style={{
        padding: "5%",
    }}
    fluid
    >
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
            <FormChangePassword />
        </Col>
      </Row>
    </Container>
  )
}

export default ChangePassword

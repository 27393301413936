import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js'
import { encrypt } from '../utils/cryptoUtils';

// import { useNavigate } from 'react-router-dom';

const MY_AUTH_APP = 'MY_TABSA_APP';
const SECRET_KEY = 'mi_clave_secreta'; // Clave secreta para encriptar
const SESSION_KEY = 'MY_TABSA_APP_SESSION';

export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  // const navigate = useNavigate();
  const [authData, setAuthData] = useState(() => {
    const storedData = window.localStorage.getItem(MY_AUTH_APP);
    if (storedData) {
      const bytes = CryptoJS.AES.decrypt(storedData, SECRET_KEY);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
    return { isAuthenticated: false, user: null, role: null };
  });

  const login = useCallback(function (username) {
    const authInfo = { isAuthenticated: true, user: username, role:"normal" };
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(authInfo), SECRET_KEY).toString();
    window.localStorage.setItem(MY_AUTH_APP, encryptedData);
    sessionStorage.removeItem(SESSION_KEY);//se coloco el 04-07-24 se esta validaando****
    // window.sessionStorage.setItem('tabsa_session', true); borrar
    setAuthData(authInfo);

  }, []);

  const updateStatusUser = useCallback(function (newStatus) {
    const storedData = window.localStorage.getItem(MY_AUTH_APP);
    if (storedData) {
      const bytes = CryptoJS.AES.decrypt(storedData, SECRET_KEY);
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      decryptedData.user.usuario.estado = newStatus;
      console.log(decryptedData, "decryptedData");
      let usuario = encrypt(decryptedData.user.usuario);
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(decryptedData), SECRET_KEY).toString();
      window.localStorage.setItem(MY_AUTH_APP, encryptedData);
      return usuario;

    }
  }, []);

  //test login ejecutivo
  const login_executive = useCallback(function (username) {
    const authInfo = { isAuthenticated: true, user: username, role:"ejecutivo" };
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(authInfo), SECRET_KEY).toString();
    window.localStorage.setItem(MY_AUTH_APP, encryptedData);
    sessionStorage.removeItem(SESSION_KEY);//testing
    setAuthData(authInfo);

  }, []);


  const logout = useCallback( () => {
    window.localStorage.removeItem(MY_AUTH_APP);
    localStorage.removeItem("token");//QUITAR LUEGO DE LAS PRUEBAS
    setAuthData({ isAuthenticated: false, user: null , role: null});


  }, []);

  const usuarioSinPermisos = useCallback( () => {
    
    const storedData = window.localStorage.getItem(MY_AUTH_APP);
    if (storedData) {
      const bytes = CryptoJS.AES.decrypt(storedData, SECRET_KEY);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // console.log("usuarioSinPermisos test", decryptedData);
      const estadoActual = decryptedData.user.usuario.estado;
      if(estadoActual !== "2"){
        window.localStorage.removeItem(MY_AUTH_APP);
        localStorage.removeItem("token");//QUITAR LUEGO DE LAS PRUEBAS
      }
      return estadoActual
    }

  }, []);
  const encryptSession = useCallback((user) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(user), SECRET_KEY).toString();
    sessionStorage.setItem(SESSION_KEY, encryptedData);
  }, []);
  const decryptSession = useCallback(() => {
    const encryptedData = sessionStorage.getItem(SESSION_KEY);
    if (encryptedData) {
      const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
      console.log(bytes, "bytesbytesbytesbytes");
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log("decryptSession",decryptedData);
      return decryptedData;
    }
    return null;
  }, []);
  const removeSession = useCallback(() => {
    sessionStorage.removeItem(SESSION_KEY);
  }, []);
  


  const value = useMemo(
    () => ({
      login,
      logout,
      login_executive,
      updateStatusUser,
      usuarioSinPermisos,
      encryptSession,
      decryptSession,
      removeSession,
      isAuthenticated: authData.isAuthenticated,
      user: authData.user,
      role: authData.role
    }),
    [authData, login, logout,login_executive]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
  children: PropTypes.object,
};

export function useAuthContext() {
  return useContext(AuthContext);
}
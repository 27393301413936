import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";

import Logotipo from "../img/logo_two.svg";
import googleIcon from "../img/icon-google.png";
import {
  Button,
  Form,
  Input,
  ConfigProvider,
  Typography,
  Image,
  Modal,
} from "antd";
import {
  LockOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Col, Row, Container } from "react-bootstrap";
import { messages } from "../utils/messages";
import { useAuthContext } from "../contexts/authContext";
import { CREAR_CUENTA, LOGIN_ENTRY } from "../services/api";
import iconErrorModal from "../img/red-x.png";
import { ErrorModal, InfoModal, SuccessModal } from "../utils/modalMsg";
import { encrypt } from "../utils/cryptoUtils";

const { Paragraph, Text } = Typography;

const FormLogin = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { login, removeSession } = useAuthContext();
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cargando, setCargando] = useState(false); //btn cargando
  const [cargandoGoogle, setCargandoGoogle] = useState(false); //btn cargando
  const [isGoogle, setIsGoogle] = useState(false);
  const [visible, setVisible] = useState(false);
  // Modal error
  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  //Modal success
  const [modalVisibleSuccess, setModalVisibleSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  //NUEVO
  const [modalInfoVisible, setModalInfoVisible] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  // Modal info
  const handleOkInfo = () => {
    setModalInfoVisible(false);
  };

  // Modal error
  const handleOk = () => {
    setModalVisible(false);
  };
  //Modal success
  const handleOkSuccess = () => {
    setModalVisibleSuccess(false);
  };



  const customInputTheme = {
    components: {
      Input: {
        //configuracion adicional
      },
    },
  };


  useEffect(() => {
    //UNICA FORMA QUE ENCONTRE PARA BLOQUER FECHA DEL NAVEGADOR
    window.addEventListener('popstate', navigate("/login"));
    removeSession();

  }, []);


  const login_google = useGoogleLogin({

    onSuccess: async (tokenResponse) => {
      setCargandoGoogle(true);
      // console.log(tokenResponse);
      if (tokenResponse) {
        const url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`;
        const headers = {
          Authorization: `Bearer ${tokenResponse.access_token}`,
          Accept: 'application/json'
        };
  
        try {
          const response = await fetch(url, { headers });
          if (!response.ok) {
            throw new Error('Error al obtener datos del perfil');
          }
          const dataGoogleToken = await response.json();
          // console.log(dataGoogleToken, "data del token");
          const { email, family_name,given_name, name, verified_email} = dataGoogleToken;
          // console.log(email, " = ",name, " =verified_email",verified_email);

         //falta verificar endpoitn login primero
         //si no tiene se registra
         //si tiene dejalo pasar al dashboard
          const valuesEnviar = {
            email: email,
            password: "no-importa-la-clave",//no la guarda
            isGoogle: true,
            origen: "google"
          }
          // console.log(valuesEnviar);
          const respTieneCuenta = await LOGIN_ENTRY(valuesEnviar);
          // console.log(respTieneCuenta,"ver aqui ++++++");
          const {data, acceso, msg, existe} = respTieneCuenta;
 
          if(data === null && acceso === false && existe === false){
   
          //se crea cuenta de usuario con google
          // const { data, msg } = await CREAR_CUENTA(valuesEnviar);
          //primero se logea y se verfica que tenga cuenta googleLogin si es google 1 
          //si data login es 	"acceso": false crea cuenta
          //para crear cuenta se debe enviar solo el    "email_login" : "testsebastian321go@gmail.com",		"password_login" : "no afecta cual se mande",    "googleLogin" : true

            const respCrearCuenta = await CREAR_CUENTA(valuesEnviar);
            // console.log({data,msg},"Onfinish");
            if (respCrearCuenta.data === null) {
              setErrorMessage(respCrearCuenta.msg);
              setModalVisible(true);
          
              setCargandoGoogle(false);
            } else {
              setSuccessMessage(respCrearCuenta.msg);
              setModalVisibleSuccess(true);
   
              setCargandoGoogle(false);
              const estado = respCrearCuenta.data.usuario.estado;
              const usuario = respCrearCuenta.data.usuario;
              const encryptedUsuario = encrypt(usuario);
              //aqui ya se creo el usuario y como no tiene perfil se le redireccionara a la zona para crear uno (estado = 3 requerido)
              if(estado === 3){
          
                setTimeout(() => {
                  navigate(`/registrar-perfil/${encryptedUsuario}`);
                }, 2000);
               
              }else{
                setErrorMessage("Se creo cuenta de usuario con google. Pero su estado de creacion esta erroneo.");
                setModalVisible(true);
     
                setCargandoGoogle(false);
              }
   
            }
          }else{
            // console.log("AQUI PASSO POR EL FLUJO DEL HANDLE");
            handleSubmit(valuesEnviar, "google");
          }
   

        } catch (err) {
          //FALTAA COLOCAR MODAL PARA ERROR NO CONTROLADO DE LOGIN GOOGLE
          console.error('Error al realizar la solicitud fetch:', err);
        }
      }
      setCargandoGoogle(false);
      // handleSubmit(tokenResponse, "google");

    },
  });
  
  // const login_google = useGoogleLogin({
  //   scope: 'https://www.googleapis.com/auth/userinfo.profile',
  //   onSuccess: async(tokenResponse) => {
  //     console.log(tokenResponse);
  //     if (tokenResponse) {
  //       axios
  //           .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
  //               headers: {
  //                   Authorization: `Bearer ${tokenResponse.access_token}`,
  //                   Accept: 'application/json'
  //               }
  //           })
  //           .then((res) => {
  //               console.log(res.data);
  //               setProfile(res.data);
  //           })
  //           .catch((err) => console.log(err));
  //   }
  //     // handleSubmit(tokenResponse, "google");
  //     await handleSubmit(tokenResponse, "google");

  //     // Verificar si el tokenResponse tiene la propiedad profileObj y obtener el correo electrónico
  //     if (tokenResponse.profileObj && tokenResponse.profileObj.email) {
  //       const userEmail = tokenResponse.profileObj.email;
  //       console.log("Correo electrónico del usuario:", userEmail);
  //       // Aquí puedes hacer más lógica con el correo electrónico si es necesario
  //     } else {
  //       console.log("No se pudo obtener el correo electrónico del usuario.");
  //     }
  //   },
  // });

  const handleSubmitOLD = async (values, tipoCuenta) => {
    // setCargando(true);
    // setCargandoGoogle(true);
    //SE REMUEVE TODA INFORMCION DE SESION PARA NO AFECTAR FLUJO
    removeSession();
    // sessionStorage.setItem('tabsa_session', JSON.stringify(values));
    // console.log("handleSubmit Verificando", values, tipoCuenta);

    // setIsGoogle(respGoogle);
    
    if (tipoCuenta === "google") {
      setCargandoGoogle(true);
      // console.log("Iniciando sesión con Google...");
      const resp = await LOGIN_ENTRY(values);
      // console.log(resp, "handleSubmitresp");
      if (resp.data === null || resp.acceso === false) {
        setErrorMessage(resp.msg);
        setModalVisible(true);
        setCargandoGoogle(false);
      } else {
        const estado = resp.data.usuario.estado;
        const usuario = resp.data.usuario;
        const encryptedUsuario = encrypt(usuario);
        switch (estado) {
          case "2":
            console.log("Estado 2: Registro Completo", estado);
            setSuccessMessage(resp.msg);
            setModalVisibleSuccess(true);
            setCargandoGoogle(false);
            // aqui segun es estado hacer un switch
            login(resp.data); //solo dejar el funcion login navigate y timer
            navigate("/");
            break;
          case "3":
            console.log("Estado 3: Usuario Creado",estado);
            // navigate("/registrar-perfil");
            // const id = resp.data.usuario.id_usuarios

            setCargandoGoogle(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
            break;
          case "4":
            console.log("Estado 4: Usuario Persona Asociada",estado);
 
            setCargandoGoogle(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
              break;
          default:
            console.log("Estado: Desconocido",estado);
        }

      }


      //nuevo codigo
      // console.log(values, "isGoogle", isGoogle);
      //ejemplo basico
    
      // setTimeout(() => {
      //   localStorage.removeItem("token");
      //   sessionStorage.clear();

      //   navigate("/login");
      // }, 3600000); //1hr ///60000 (1min)
      // navigate("/read");
      // localStorage.setItem("token", true);// esto se tiene que quitar
      // login("seba_google"); //solo dejar el funcion login navigate y timer
      // navigate("/");
    } else {
      setCargando(true);
      console.log("Iniciando sesión con Tabsa...");
      // console.log(values, "isGoogle", isGoogle);
      localStorage.setItem("token", true);

      // setTimeout(() => {
      //   localStorage.removeItem("token");
      //   sessionStorage.clear();

      //   navigate("/login");
      // }, 60000);
      // navigate("/dashboard");

      ///
      const valuesFormulario = {
        ...values,
        origen:"formulario"
      }
      const resp = await LOGIN_ENTRY(valuesFormulario);
      // console.log(resp, "handleSubmitresp");
      if (resp.data === null || resp.acceso === false) {
        setErrorMessage(resp.msg);
        setModalVisible(true);
        setCargando(false);
      } else {
        const estado = resp.data.usuario.estado;
        const usuario = resp.data.usuario;
        const encryptedUsuario = encrypt(usuario);
        switch (estado) {
          case "2":
            console.log("Estado 2: Registro Completo", estado);
            setSuccessMessage(resp.msg);
            setModalVisibleSuccess(true);
            setCargando(false);
            // aqui segun es estado hacer un switch
            login(resp.data); //solo dejar el funcion login navigate y timer
            navigate("/");
            break;
          case "3":
            console.log("Estado 3: Usuario Creado",estado);
            setCargando(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
            break;
          case "4":
            console.log("Estado 4: Usuario Persona Asociada",estado);
            setCargando(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
              break;
          default:
            console.log("Estado: Desconocido",estado);
            setErrorMessage("Estado de cuenta del usuario desconocido.");
            setModalVisible(true);
            setCargando(false);
        }
        // setSuccessMessage(resp.msg);
        // setModalVisibleSuccess(true);
        // setCargando(false);
        // // aqui segun es estado hacer un switch
        // login(resp.data); //solo dejar el funcion login navigate y timer
        // navigate("/");
      }
    }
  };

  const handleRedirectAppOrWeb = (valuesSession) => {
    const userAgent = navigator.userAgent;

    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      console.log('Dispositivo iOS detectado');
      window.ReactNativeWebView.postMessage("Dispositivo iOS detectado");
    } else if (/Android/i.test(userAgent)) {
      console.log('Dispositivo Android detectado');
      window.ReactNativeWebView.postMessage(JSON.stringify(valuesSession));
    } else {
      console.log('Navegador de escritorio detectado');
      //INICIA SESION VIA WEB
      login(valuesSession); 
      navigate("/");
    }
  };

  const handleSubmit = async (values, tipoCuenta) => {

    //SE REMUEVE TODA INFORMCION DE SESION PARA NO AFECTAR FLUJO
    removeSession();
    if (tipoCuenta === "google") {
      setCargandoGoogle(true);
      const resp = await LOGIN_ENTRY(values);

      if (resp.data === null || resp.acceso === false) {
        setErrorMessage(resp.msg);
        setModalVisible(true);
        setCargandoGoogle(false);
      } else {
        const estado = resp.data.usuario.estado;
        const usuario = resp.data.usuario;
        const encryptedUsuario = encrypt(usuario);
        switch (estado) {
          case "2":
            console.log("Estado 2: Registro Completo", estado);
            setSuccessMessage(resp.msg);
            setModalVisibleSuccess(true);
            setCargandoGoogle(false);
            handleRedirectAppOrWeb(resp.data);//se envia todo el objeto del usuario
            //INICIAR SESION
            // login(resp.data); //solo dejar el funcion login navigate y timer
            // navigate("/");
            break;
          case "3":
            console.log("Estado 3: Usuario Creado",estado);
            setCargandoGoogle(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
            break;
          case "4":
            console.log("Estado 4: Usuario Persona Asociada",estado);
            setCargandoGoogle(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
              break;
          default:
            console.log("Estado: Desconocido",estado);
        }

      }

    } else {
      setCargando(true);
      console.log("Iniciando sesión con Tabsa...");
      //localStorage.setItem("token", true); /// ESTO LO COMENTE VER******SI LLEGA A FALLAR

      const valuesFormulario = {
        ...values,
        origen:"formulario"
      }
      const resp = await LOGIN_ENTRY(valuesFormulario);

      if (resp.data === null || resp.acceso === false) {
        setErrorMessage(resp.msg);
        setModalVisible(true);
        setCargando(false);
      } else {
        const estado = resp.data.usuario.estado;
        const usuario = resp.data.usuario;
        const encryptedUsuario = encrypt(usuario);
        console.log("CODIGO NUEVO ESTADO =>",estado);
        switch (estado) {
          case "2":
            console.log("Estado 2: Registro Completo", estado);
            setSuccessMessage(resp.msg);
            setModalVisibleSuccess(true);
            setCargando(false);
            handleRedirectAppOrWeb(resp.data);//se envia todo el objeto del usuario
            //INICIA SESION
            // login(resp.data); //solo dejar el funcion login navigate y timer
            // navigate("/");
            break;
          case "3":
            console.log("Estado 3: Usuario Creado",estado);
            setInfoMessage("Revise su último correo para completar su perfil y poder iniciar sesión.");
            setModalInfoVisible(true);
            setCargando(false);
            break;
          case "4":
            console.log("Estado 4: Usuario Persona Asociada",estado);
            // setInfoMessage("Revise su último correo y verifique su cuenta para poder iniciar sesión.");
            // setModalInfoVisible(true);
            // setCargando(false);
            //se cambio por motivo de que cuando el usuario cambia su contraseña y no valida codigo y lo intenta desde el correo falla el proceso (Preguntar?)
            console.log("Estado 4: Usuario Persona Asociada",estado);
            setCargando(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
              break;
          default:
            console.log("Estado: Desconocido",estado);
            setErrorMessage("Estado de cuenta del usuario desconocido.");
            setModalVisible(true);
            setCargando(false);
        }
        //FIN SWITCH
      }
    }
  };
  //esta bueno esta funcion se hace resplado para probar codigo de redireccionamiento a la App
  const handleSubmitBueno = async (values, tipoCuenta) => {

    //SE REMUEVE TODA INFORMCION DE SESION PARA NO AFECTAR FLUJO
    removeSession();
    if (tipoCuenta === "google") {
      setCargandoGoogle(true);
      const resp = await LOGIN_ENTRY(values);

      if (resp.data === null || resp.acceso === false) {
        setErrorMessage(resp.msg);
        setModalVisible(true);
        setCargandoGoogle(false);
      } else {
        const estado = resp.data.usuario.estado;
        const usuario = resp.data.usuario;
        const encryptedUsuario = encrypt(usuario);
        switch (estado) {
          case "2":
            console.log("Estado 2: Registro Completo", estado);
            setSuccessMessage(resp.msg);
            setModalVisibleSuccess(true);
            setCargandoGoogle(false);
            //INICIAR SESION
            login(resp.data); //solo dejar el funcion login navigate y timer
            navigate("/");
            break;
          case "3":
            console.log("Estado 3: Usuario Creado",estado);
            setCargandoGoogle(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
            break;
          case "4":
            console.log("Estado 4: Usuario Persona Asociada",estado);
            setCargandoGoogle(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
              break;
          default:
            console.log("Estado: Desconocido",estado);
        }

      }


      //nuevo codigo
      // console.log(values, "isGoogle", isGoogle);
      //ejemplo basico
    
      // setTimeout(() => {
      //   localStorage.removeItem("token");
      //   sessionStorage.clear();

      //   navigate("/login");
      // }, 3600000); //1hr ///60000 (1min)
      // navigate("/read");
      // localStorage.setItem("token", true);// esto se tiene que quitar
      // login("seba_google"); //solo dejar el funcion login navigate y timer
      // navigate("/");
    } else {
      setCargando(true);
      console.log("Iniciando sesión con Tabsa...");
      //localStorage.setItem("token", true); /// ESTO LO COMENTE VER******SI LLEGA A FALLAR

      const valuesFormulario = {
        ...values,
        origen:"formulario"
      }
      const resp = await LOGIN_ENTRY(valuesFormulario);

      if (resp.data === null || resp.acceso === false) {
        setErrorMessage(resp.msg);
        setModalVisible(true);
        setCargando(false);
      } else {
        const estado = resp.data.usuario.estado;
        const usuario = resp.data.usuario;
        const encryptedUsuario = encrypt(usuario);
        console.log("CODIGO NUEVO ESTADO =>",estado);
        switch (estado) {
          case "2":
            console.log("Estado 2: Registro Completo", estado);
            setSuccessMessage(resp.msg);
            setModalVisibleSuccess(true);
            setCargando(false);
            //INICIA SESION
            login(resp.data); //solo dejar el funcion login navigate y timer
            navigate("/");
            break;
          case "3":
            console.log("Estado 3: Usuario Creado",estado);
            setInfoMessage("Revise su último correo para completar su perfil y poder iniciar sesión.");
            setModalInfoVisible(true);
            setCargando(false);
            break;
          case "4":
            console.log("Estado 4: Usuario Persona Asociada",estado);
            // setInfoMessage("Revise su último correo y verifique su cuenta para poder iniciar sesión.");
            // setModalInfoVisible(true);
            // setCargando(false);
            //se cambio por motivo de que cuando el usuario cambia su contraseña y no valida codigo y lo intenta desde el correo falla el proceso (Preguntar?)
            console.log("Estado 4: Usuario Persona Asociada",estado);
            setCargando(false);
            navigate(`/registrar-perfil/${encryptedUsuario}`);
              break;
          default:
            console.log("Estado: Desconocido",estado);
            setErrorMessage("Estado de cuenta del usuario desconocido.");
            setModalVisible(true);
            setCargando(false);
        }
        //FIN SWITCH
      }
    }
  };

  //CAMBIA EL ESTADO DEL BOTON SI ES INICIO DE SESION NORMAL O GOOGLE
  const handleClick = (resp) => {
    setIsGoogle(resp);
  };

  // const handleSubmitTest = async (values) => {
  //   try {
  //     const response = await fetch("", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({}),
  //     });

  //     if (response.ok) {
  //       <Navigate to="/dashboard"/>
  //     } else {
  //     }
  //   } catch (error) {
  //     console.error("Error al iniciar sesión:", error);
  //   }
  // };

  return (
    <Container className="contenedor_login" fluid>
      <Row>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          className="login_logotipo"
        >
          <Image preview={false} width={200} src={Logotipo} />
        </Col>
      </Row>

      <Row className="login_conteiner">
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Typography>
            <Text className="titulo_white">Acceso cliente</Text>
          </Typography>
        </Col>
 
            
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Form
            form={form}
            className="form_content"
            name="normal_login"
            onFinish={handleSubmit}
            autoComplete="off"
            layout="vertical"
          >
            <ConfigProvider theme={customInputTheme}>
              <Form.Item
                id="email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: messages.fields.emailNotValid,
                  },
                  {
                    required: true,
                    message: messages.fields.email,
                  },
                ]}
              >
                <Input
                  id="email"
                  name="email"
                  // autoComplete="off"
                  prefix={<UserOutlined />}
                  placeholder={messages.placeholder.email}
                  onChange={(event) => setEmail(event.target.value)}
                  className="login_input"
                  autoComplete="new-email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: messages.fields.password,
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  // autoComplete="off"
                  placeholder={messages.placeholder.password}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  className="login_input"
                  autoComplete="new-password"
                />
              </Form.Item>
            </ConfigProvider>
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                <Typography>
                  <Link to="/recuperar">
                    <Paragraph className="boton_login_link">
                      ¿Olvidaste tu contraseña?
                    </Paragraph>
                  </Link>
                </Typography>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                <Typography>
                  <Link to="/crear-cuenta">
                    <Paragraph className="boton_login_link">
                      Crear usuario y contraseña
                    </Paragraph>
                  </Link>
                </Typography>
              </Col>
            </Row>
            <Form.Item className="fi_display margin_dt">
              <Button
                className="boton_login"
                onClick={(e) => handleClick(e, "normal")}
                htmlType="submit"
                loading={cargando}
              >
                Acceder
              </Button>
            </Form.Item>

            <Form.Item className="fi_display">
              <Button
                onClick={() => login_google()}
                className="boton_login_google"
                name="Acceder con Google"
                loading={cargandoGoogle}
              >
                <Image
                  preview={false}
                  width={33}
                  src={googleIcon}
                  className="icon_google_btn"
                />
                Acceder con Google
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <ErrorModal
        isVisible={modalVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
      <SuccessModal
        isVisible={modalVisibleSuccess}
        onClose={handleOkSuccess}
        errorMessage={successMessage}
      />
      <InfoModal
        isVisible={modalInfoVisible}
        onClose={handleOkInfo}
        errorMessage={infoMessage}
      />
      <Modal
        // title="Error"
        width={620}
        centered
        closable={false}
        open={visible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button
            key="back"
            type="primary"
            onClick={handleOk}
            style={{
              // width: "515px",
              height: "60px",

              borderRadius: "8px",
              display: "block",
              margin: "auto",
              marginTop: "50px",
            }}
          >
            Volver a intentarlo
          </Button>,
        ]}
        style={{
          width: "620px",
          height: "506px",
          borderRadius: "18px",
          textAlign: "center",
        }}
      >
        <Image
          src={iconErrorModal}
          preview={false}
          style={{ width: "93px", height: "92px", padding: "10px" }}
        />

        <h1
          style={{
            fontSize: "43px",
            fontWeight: "700",
            textAlign: "center",
            color: "#000000",
            marginTop: "40px",
          }}
        >
          Hubo un error
        </h1>
        <h2
          style={{
            fontSize: "25px",
            fontWeight: "500",
            textAlign: "center",
            color: "#000000",
            marginTop: "20px",
          }}
        >
          El código ingresado no es correcto.
        </h2>
      </Modal>
    </Container>
  );
};

export default FormLogin;

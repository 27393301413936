import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Upload, Button, message, Form, Image } from "antd";
import {FilePdfOutlined} from "@ant-design/icons";
const { Dragger } = Upload;

const DaggerTest = () => {
  const [fileList, setFileList] = useState([]);
  const [filePreviews, setFilePreviews] = useState({});

  const handleBeforeUpload = (file) => {
    if (fileList.length >= 3) {
      message.error("Solo puedes subir un máximo de 3 archivos.");
      return false;
    }

    const previewUrl = URL.createObjectURL(file);
    setFilePreviews((prevPreviews) => ({ ...prevPreviews, [file.uid]: previewUrl }));
    setFileList([...fileList, file]);

    return false; // Esto previene que el archivo sea subido automáticamente
  };

  const handleRemoveFile = (file) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
    setFilePreviews((prevPreviews) => {
      const newPreviews = { ...prevPreviews };
      delete newPreviews[file.uid];
      return newPreviews;
    });
  };

  const handlePreview = (uid) => {
    const previewUrl = filePreviews[uid];
    window.open(previewUrl, "_blank");
  };

  return (
    <Container style={{ padding: "5%" }} fluid>
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Form>
            <Form.Item
              name="documentos"
              className="floating-label-form-item"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione documentos!",
                },
              ]}
            >
              <Dragger
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
                accept=".jpg,.png,.pdf"
                multiple
              >
                <div>
                  <p className="ant-upload-drag-icon"></p>
                  <p className="ant-upload-text">
                    Haz clic o arrastra archivos aquí para cargar
                  </p>
                  <p className="ant-upload-hint">
                    Solo se permiten archivos JPG, PNG y PDF.
                  </p>
                </div>
              </Dragger>
            </Form.Item>
          </Form>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Row>
            {fileList.map((file) => (
              <Col
                xxl={3}
                xl={4}
                lg={4}
                md={4}
                xs={12}
                key={file.uid}
                style={{
                  margin: "45px 5px 0 0",
                  textAlign: "center",
                }}
              >
                <div key={file.uid} style={{ marginBottom: "10px" }}>
                  {file.type.startsWith("image") ? (
                    <Image
                      src={filePreviews[file.uid]}
                      alt={file.name}
                      width="100%"
                      height="150px"
                      style={{
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePreview(file.uid)}
                    />
                  ) : file.type === "application/pdf" ? (
                    <embed
                      src={filePreviews[file.uid]}
                      type="application/pdf"
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePreview(file.uid)}
                    />
           
                    // <div
                    // style={{
                    // width: "100%",
                    // height: "150px",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // border: "1px solid #d9d9d9",
                    // borderRadius: "4px",
                    // cursor: "pointer",
                    // }}
                    // onClick={() => handlePreview(file.uid)}
                    // >
                    // <FilePdfOutlined style={{ fontSize: "48px", color: "#f5222d" }} />
                    // </div>
                  ) : (
                    <p onClick={() => handlePreview(file.uid)} style={{ cursor: "pointer" }}>
                      {file.name}
                    </p>
                  )}
                  <Button type="link" onClick={() => handlePreview(file.uid)} >
                    Preview
                  </Button>
                  <Button type="link" onClick={() => handleRemoveFile(file)}>
                    Eliminar
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DaggerTest;

import React, { useEffect }from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import faro from "../img/logo-faro.png";
import { Button, Image } from "antd";
import  { useAuthContext } from "../contexts/authContext";

const ErrorView = () => {
  const navigate = useNavigate();
  const { role } = useAuthContext();

  const goToBack = () => {
    switch (role) {
        case "normal":
            navigate("/");
            break;
        case "ejecutivo":
            navigate("/login-ejecutivo");
            break;

        default:
          navigate("/");
          break;
    }
  
  };
  useEffect(() => {
    document.title = "Tabsa | No encontrada";
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          className="contenedor-error"
        >
          <Image src={faro} preview={false} className="error_img_view" />
        </Col>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          className="error_text_contenedor"
        >
          <h1 className="error_text">404</h1>
          <p className="error_subtext">ERROR. Destino desconocido.</p>
          <Button className="error_btn" onClick={goToBack}>
            Volver atrás
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorView;

import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import {
  Table,
  Button,
  Tag,
  Image,
  Divider,
  Tooltip,
  Form,
  Select,
  Collapse,
  Input,
  DatePicker,
  Upload,
  message,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import iconPreview from "../img/icon-preview.png";
import iconDelete from "../img/icon-delete.png";
import dayjs from "dayjs";
import LocaleES from "antd/lib/date-picker/locale/es_ES";
import "../../src/css/otherStyles.css"; // Importa tus estilos CSS personalizados
import "../../src/css/label.css"; // Importa tu archivo CSS personalizado
import { LinkOutlined } from "@ant-design/icons";
import {
  AGREGAR_CONTENIDO_EJECUTIVO,
  GUARDAR_DOCUMENTO_FUNCIONAL,
  LISTA_CONDICIONES_EJECUTIVO,
  TABLA_CONTENIDO_EJECUTIVO,
} from "../services/api";
import { ErrorModal, SuccessModal } from "../utils/modalMsg";
const { Option } = Select;
const { Panel } = Collapse;
const { Dragger } = Upload;

const MantExecutive = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [filePreviews, setFilePreviews] = useState({});
  const [tablaContenido, setTablaContenido] = useState([]);
  const [selectCondiciones, setSelectCondiciones] = useState([]);
  const [loadingContenido, setLoadingContenido] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [panelData, setPanelData] = useState({});
  const [tipoCondicion, setTipoCondicion] = useState(null); //para contolar si se muestra un dagger o un input
  const [previouslySelectedItems, setPreviouslySelectedItems] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);
  //MODALES
  const [modalErrorVisible, setModalErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalSuccessVisible, setModalSuccessVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleOkSuccess = () => {
    setModalSuccessVisible(false);
  };

  const handleOk = () => {
    setModalErrorVisible(false);
  };

  const handleSelectChange = (newSelected) => {
    console.log(newSelected, "newSelected");

    const removedItems = previouslySelectedItems.filter(
      (item) => !newSelected.includes(item)
    );
    const addedItems = newSelected.filter(
      (item) => !previouslySelectedItems.includes(item)
    );

    // Crear un nuevo objeto con los ítems seleccionados
    const updatedPanelData = { ...panelData };

    // Eliminar los ítems que ya no están seleccionados
    removedItems.forEach((id) => {
      delete updatedPanelData[id];
      form.setFieldsValue({ [`tipo-${id}`]: "", [`valor-${id}`]: "" });
    });

    // Agregar los ítems nuevos
    addedItems.forEach((id) => {
      if (!updatedPanelData[id]) {
        updatedPanelData[id] = { tipo: "", valor: "" };
      }
    });

    setSelectedItems(newSelected);
    setPanelData(updatedPanelData);
    setPreviouslySelectedItems(newSelected); // Actualiza el estado del anterior seleccionado

    if (addedItems.length > 0) {
      setActiveKeys([addedItems[0]]);
    }
  };

  //CUANDO CAMBIA LA CONDICION SE RESETEAN LOS CAMPOS DEPENDIENTES 1.Imagen Carrusel 2.Texto Notificación
  const handleTipoCondicionChange = (value) => {
    setTipoCondicion(value);
    form.resetFields(["contextText", "contextImage"]);
    //Borra contenido relacionado al cargar imagen
    setFileList([]);
    setFilePreviews({});
  };

  //CONTROLA LA SECCION DEL COLLAPSE DE LOS PANELES DE LAS CONDICIONES
  const handleInputChange = (id, field, value) => {
    //console.log(id, field, value);
    //actualiza estado panel
    //se copia todo estado previo del estado el obj queda 1: {tipo: '', valor: ''}
    //si se cambia el tipo se resetea campo valor
    setPanelData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: field === "tipo" ? value : prev[id][field],
        valor: field === "tipo" ? "" : value,
      },
    }));
    // resetear el campo valor en el formulario
    if (field === "tipo") {
      form.setFieldsValue({ [`valor-${id}`]: null });
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id_contenido",
      key: "id_contenido",

      render: (text) => <a>{text}</a>,
    },
    {
      title: "Contenido",
      dataIndex: "contenido",
      key: "contenido",

      render: (text, record) => {
        if (record.tipo === "1") {
          // Si el tipo es 1, renderiza una imagen
          return (
            <Image
              src={text}
              alt="contenido"
              style={{ width: "100px", height: "auto" }}
              preview={false}
            />
          );
        } else if (record.tipo === "2") {
          // Si el tipo es 2, renderiza texto
          return <span>{text}</span>;
        } else {
          // Si el tipo es otro, renderiza un mensaje por defecto o vacío
          return <span>No disponible</span>;
        }
      },
    },
    {
      title: "Condicion",
      dataIndex: "contenido_condicion",
      key: "contenido_condicion",

      render: (condiciones) => {
        if (Array.isArray(condiciones) && condiciones.length > 0) {
          return (
            <div>
              {condiciones.map((condicion, index) => {
                const tagText = `${condicion.condiciones.nombreCondicion} = ${condicion.valor}`;
                const color = "green";
                return (
                  <Tag color={color} key={index} title={tagText}>
                    {tagText}
                  </Tag>
                );
              })}
            </div>
          );
        } else {
          return <Tag color="volcano">No disponible</Tag>;
        }
      },
    },

    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (text) => {
        if (text === "1") {
          return <span>Activo</span>;
        } else if (text === "0") {
          return <span>Inactivo</span>;
        } else {
          return <span>No disponible</span>;
        }
      },
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      render: (text) => {
        if (text === "1") {
          return <span>Imagen Carrusel</span>;
        } else if (text === "2") {
          return <span>Texto Notificación</span>;
        } else {
          return <span>No disponible</span>;
        }
      },
    },
    {
      title: "URL",
      dataIndex: "href",
      key: "href",
      render: (href) => (
        <Tooltip title={`Ir a ${href}`} color="blue">
          <a href={href} target="_blank" rel="noopener noreferrer">
            <LinkOutlined />
          </a>
        </Tooltip>
      ),
    },
  ];
  //CONTENIDO NECESARIO PARA CONTROLAR LA SECCION DE IMAGEN CARRUSEL
  const handlePreview = (uid) => {
    const previewUrl = filePreviews[uid];
    window.open(previewUrl, "_blank");
  };
  const handleDelete = (uid) => {
    //como es una sola imagen se comenta el siguiente codigo que era para controlar de forma multiple
    // const updatedFileList = fileList.filter((file) => file.uid !== uid);
    // const updatedPreviews = { ...filePreviews };
    // delete updatedPreviews[uid];
    // setFileList(updatedFileList);
    // setFilePreviews(updatedPreviews);
    form.resetFields(["contextImage"]);
    setFileList([]);
    setFilePreviews({});
  };
  //SE HACE LA PETICION PARA GUARDAR LA IMAGEN Y TE DEVUELVE EL ULR DEL DOCUMENTO O UN NULL
  const uploadImageToApi = async (file) => {
    // console.log("llego uploadImageToApi=>", file);
    // return
    try {
      const respImg = await GUARDAR_DOCUMENTO_FUNCIONAL(file);

      if (respImg.data === null) {
        // message.error(respImg.msg);
        console.log(respImg, "mala peticion");
        return null;
      } else {
        // message.success(respImg.msg);
        console.log(respImg, "buena peticion");
        return respImg.data;
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  //ESTA FUNCION RESCATA EL VALOR PARA LUEGO ANALIZARLO "handleUpload"
  const documentoSave = async (documento) => {
    console.log(documento, "documento");
    const imageData = await uploadImageToApi(documento.originFileObj);
    console.log(imageData, "imageData");
    return imageData;
  };
  //FUNCIONALIDAD DINAMICA DEL UPLOAD CON SEPARACION DE IMAGENES
  const handleUpload = ({ fileList }) => {
    // Tipos de archivos permitidos
    const allowedTypes = ["image/jpeg", "image/png"];

    // Filtra solo los archivos permitidos
    const filteredFiles = fileList.filter((file) =>
      allowedTypes.includes(file.type)
    );

    // Archivos no permitidos
    const nonAllowedFiles = fileList.filter(
      (file) => !allowedTypes.includes(file.type)
    );
    if (nonAllowedFiles.length > 0) {
      message.error(
        `No se pueden cargar los siguientes tipos de archivo: ${nonAllowedFiles
          .map((file) => file.name)
          .join(", ")}`
      );
    }

    // Asigna un ID aleatorio a los archivos permitidos
    const updatedFileList = filteredFiles.map((file) => ({
      ...file,
      uid: file.uid || Math.random().toString(36).substring(7),
    }));

    // Obtiene URL de previsualización
    const previews = {};
    const validFilePromises = updatedFileList.map(async (file) => {
      const url_documento = await documentoSave(file);
      if (url_documento) {
        previews[file.uid] =
          file.type === "application/pdf"
            ? `${url_documento.url}#view=FitH`
            : url_documento.url;
        return file;
      } else {
        message.error(`No se pudo cargar el documento: ${file.name}`);
        return null;
      }
    });

    // Actualiza los estados después de verificar los archivos válidos
    Promise.all(validFilePromises).then((results) => {
      const validFiles = results.filter((file) => file !== null);
      setFileList(validFiles);
      setFilePreviews(previews);
    });
  };
  //<=

  const obtenerTablaContenido = async () => {
    setLoadingContenido(true);
    const { data, msg } = await TABLA_CONTENIDO_EJECUTIVO();
    // console.log(data, msg, "FRONT");
    if (data === null) {
      setErrorMessage(msg);
      setModalErrorVisible(true);
      setLoadingContenido(false);
    } else {
      let formattedData = [];

      if (data && data.length > 0) {
        formattedData = data.map((item) => ({
          key: item.idContenido,
          id_contenido: item.idContenido,
          contenido: item.contenido,
          contenido_condicion: item.contenido_condicion,
          estado: item.estado,
          tipo: item.tipo,
          href: item.href,
          prioridad: item.prioridad,
        }));
      }

      setTablaContenido(formattedData);
      setLoadingContenido(false);
    }
  };

  const obtenerCondiciones = async () => {
    const { data, msg } = await LISTA_CONDICIONES_EJECUTIVO();
    // console.log(data, msg, "FRONT");
    if (data === null) {
      setErrorMessage(msg);
      setModalErrorVisible(true);
    } else {
      setSelectCondiciones(data);
    }
  };

  useEffect(() => {
    obtenerTablaContenido();
    obtenerCondiciones();
  }, []);

  const refreshTable = async () => {
    setLoadingContenido(true);
    const { data, msg } = await TABLA_CONTENIDO_EJECUTIVO();
    // console.log(data, msg, "FRONT");
    if (data === null) {
      setErrorMessage(msg);
      setModalErrorVisible(true);
      setLoadingContenido(false);
    } else {
      let formattedData = [];

      if (data && data.length > 0) {
        formattedData = data.map((item) => ({
          key: item.idContenido,
          id_contenido: item.idContenido,
          contenido: item.contenido,
          contenido_condicion: item.contenido_condicion,
          estado: item.estado,
          tipo: item.tipo,
          href: item.href,
          prioridad: item.prioridad,
        }));
      }

      setTablaContenido(formattedData);
      setLoadingContenido(false);
    }
  };
  const resetFormAndReloadTable = () => {
    // Resetear los valores del formulario de antd
    form.resetFields();

    // Resetear otras variables
    setFileList([]);
    setFilePreviews({});
    setTipoCondicion(null);
    //seleccion de tipo condicion, panel, previoslySelect, active de collapse
    setSelectedItems([]);
    setPanelData({});
    setPreviouslySelectedItems([]);
    setActiveKeys([]);
    //reload table
    refreshTable();
  };
  //CREA CONTENIDO NUEVO
  const handleSubmit = async (values) => {
    console.log(fileList, "fileList");
    console.log("|||||||||||||||||||||||||||");
    console.log(filePreviews, "filePreviews");
    // console.log(values, "handleSubmit");
    // Abre todos los paneles del Collapse
    console.log(selectedItems);
    //setActiveKeys(selectedItems); // Abre todos los paneles correspondientes a selectedItems

    const condiciones = selectedItems.map((id) => ({
      idCondicion: id,
      tipo: panelData[id].tipo,
      valor: panelData[id].valor,
    }));

    const submitData = {
      ...values,
      condiciones,
    };

    console.log(submitData, "handleSubmit");
    const { tipo_contenido, contextText, contextImage, link, prioridad } =
      values;
    console.log(contextImage, "contextImage");
    const urlContext = contextImage ? Object.values(filePreviews)[0] : null;
    console.log(urlContext, "urlContext");
    const submitObj = {
      tipo_contenido,
      contenido: contextImage ? urlContext : contextText,
      link,
      prioridad,
      condiciones,
    };
    console.log("OBJETO FINAL=>", submitObj);

    const { data, msg } = await AGREGAR_CONTENIDO_EJECUTIVO(submitObj);
    if (data === null) {
      console.log("No se hizo la peticion bien");
      console.log(data);
      console.log(msg);
      setModalErrorVisible(true);
      setErrorMessage(msg);
    } else {
      resetFormAndReloadTable();
      console.log("Se hizo bien la peticion");
      console.log(data);
      console.log(msg);
      setModalSuccessVisible(true);
      setSuccessMessage(msg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    const failedKeys = errorInfo.errorFields
      .map((field) => {
        const match = field.name[0].match(/\d+/);
        console.log(match, "match");
        return match ? parseInt(match[0], 10) : null;
      })
      .filter((key) => key !== null);
    console.log(failedKeys, "failedKeys", failedKeys[0]);
    setActiveKeys(failedKeys[0]);
    console.log("Failed:", errorInfo);
    message.error(
      "Por favor, asegúrese de que todos los campos obligatorios estén correctamente llenados."
    );
  };

  const tipoContenidoRules = [
    {
      required: true,
      message: "Por favor seleccione tipo contenido!",
    },
  ];
  const contenidoImagenRules = [
    {
      required: true,
      message: "Por favor seleccione una imagen!",
    },
  ];
  const contenidoTextoRules = [
    {
      required: true,
      message: "Por favor selecciona tipo contenido!",
    },
  ];
  const linkRules = [
    {
      required: true,
      message: "Por favor ingrese link!",
    },
  ];
  const prioriodadRules = [
    {
      required: true,
      message: "Por favor ingrese prioridad!",
    },
    {
      pattern: /^\d+$/,
      message: "Solo se permiten números",
    },
  ];

  const condicionRules = [
    {
      required: true,
      message: "Por favor selecciona condiciones!",
    },
  ];
  const tipoRules = [
    {
      required: true,
      message: "Por favor seleccione tipo!",
    },
  ];
  const valorRules = [
    {
      required: true,
      message: "Por favor selecione/ingrese valor!",
    },
  ];
  return (
    <Container fluid>
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12} className="titulo">
          Crear contenido nuevo
        </Col>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            name="form_content"
            autoComplete="off"
            labelAlign="left"
            layout="vertical"
            requiredMark={false}
          >
            <Row>
              {/* se divide en dos col */}
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  style={{ marginBottom: "35px" }}
                >
                  <Form.Item
                    name="tipo_contenido"
                    label="Tipo contenido"
                    className="floating-label-form-item"
                    rules={tipoContenidoRules}
                  >
                    <Select
                      placeholder="Seleccionar tipo de condición"
                      allowClear
                      onChange={handleTipoCondicionChange}
                    >
                      <Option value="1">Imagen Carrusel</Option>
                      <Option value="2">Texto Notificación</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {tipoCondicion === "1" && (
                  <Row className="content-display-one">
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={12}
                      xs={12}
                      style={{ marginBottom: "35px" }}
                    >
                      <Form.Item
                        name="contextImage"
                        className="floating-label-form-item"
                        rules={contenidoImagenRules}
                      >
                        <Dragger
                          fileList={fileList}
                          showUploadList={false}
                          beforeUpload={() => false}
                          onChange={handleUpload}
                          accept=".jpg,.png"
                          multiple={false}
                          maxCount={1}
                        >
                          <div>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>

                            <p className="ant-upload-text">
                              Haz clic o arrastra archivos aquí para cargar
                            </p>
                            <p className="ant-upload-hint">
                              Solo se permiten archivos JPG y PNG.
                            </p>
                          </div>
                        </Dragger>
                      </Form.Item>
                    </Col>
                    {/* */}
                    {fileList.map((file) => (
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        xs={12}
                        key={file.uid}
                        style={{ marginBottom: "35px", textAlign: "center" }}
                        // style={{
                        //   margin: "0 5px 0 0",
                        //   textAlign: "center",
                        // }}
                      >
                        <div
                          //style={{ textAlign: "center", marginRight: "10px" }}
                          style={{
                            border: "1px solid whitesmoke",
                            borderRadius: "17px",
                            backgroundColor: "white",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            padding: "10px",
                          }}
                        >
                          <embed
                            src={filePreviews[file.uid]}
                            type={file.type}
                            style={{
                              width: "100%",
                              height: "150px",
                              objectFit: "contain",
                            }}
                          />
                          <div
                            //className="button-separate-benefist"
                            style={{ textAlign: "center" }}
                          >
                            <Button
                              onClick={() => handlePreview(file.uid)}
                              style={{
                                background: "transparent",
                                border: "none",
                                borderRadius: "none",
                                color: "black",
                              }}
                            >
                              <Image
                                src={iconPreview}
                                width={25}
                                preview={false}
                                style={{ marginLeft: "-20px" }}
                              />
                              Previsualizar
                            </Button>
                            <Button
                              onClick={() => handleDelete(file.uid)}
                              style={{
                                background: "transparent",
                                border: "none",
                                borderRadius: "none",
                                color: "black",
                              }}
                            >
                              <Image
                                src={iconDelete}
                                width={25}
                                preview={false}
                                style={{ marginLeft: "-20px" }}
                              />
                              Borrar
                            </Button>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
                {tipoCondicion === "2" && (
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    xs={12}
                    style={{ marginBottom: "35px" }}
                  >
                    <Form.Item
                      name="contextText"
                      label="Notificación"
                      className="floating-label-form-item"
                      rules={contenidoTextoRules}
                    >
                      <Input placeholder="Ingrese el texto de notificación" />
                    </Form.Item>
                  </Col>
                )}
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  style={{ marginBottom: "35px" }}
                >
                  <Form.Item
                    name="link"
                    label="Link"
                    className="floating-label-form-item"
                    rules={linkRules}
                  >
                    <Input placeholder="Ingrese el link" />
                  </Form.Item>
                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  style={{ marginBottom: "35px" }}
                >
                  <Form.Item
                    name="prioridad"
                    label="Prioridad"
                    className="floating-label-form-item"
                    rules={prioriodadRules}
                  >
                    <Input placeholder="Ingrese la prioridad" />
                  </Form.Item>
                </Col>

                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  style={{ marginBottom: "35px" }}
                >
                  <Form.Item
                    name="condicion"
                    label="Lista de condiciones"
                    className="floating-label-form-item"
                    rules={condicionRules}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Selecciona beneficio"
                      allowClear
                      style={{ width: "100%", height: "auto" }}
                      onChange={handleSelectChange}
                    >
                      {selectCondiciones.map((condicion) => (
                        <Option
                          key={condicion.idCondicion}
                          value={condicion.idCondicion}
                        >
                          {condicion.nombreCondicion}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Col>
              {tipoCondicion === "1" && (
                <Col
                  xxl={8}
                  xl={8}
                  lg={8}
                  md={8}
                  xs={12}
                  // style={{background:"whitesmoke", display:"none"}}
                  className="content-display-two"
                >
                  <Row>
                    <Col
                      xxl={{ span: 5, offset: 1 }}
                      xl={{ span: 5, offset: 1 }}
                      lg={{ span: 5, offset: 1 }}
                      md={{ span: 5, offset: 1 }}
                      xs={{ span: 12, offset: 0 }}
                    >
                      <Form.Item
                        name="contextImage"
                        className="floating-label-form-item"
                        rules={contenidoImagenRules}
                      >
                        <Dragger
                          showUploadList={false}
                          beforeUpload={() => false}
                          onChange={handleUpload}
                          accept=".jpg,.png,.jpeg" // Aceptar solo imágenes
                          multiple
                          maxCount={1}
                        >
                          <div>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>

                            <p className="ant-upload-text">
                              Haz clic o arrastra archivos aquí para cargar
                            </p>
                            <p className="ant-upload-hint">
                              Solo se permiten archivos JPG y PNG.
                            </p>
                          </div>
                        </Dragger>
                      </Form.Item>
                    </Col>
                    {fileList.map((file) => (
                      <Col
                        xxl={{ span: 4, offset: 1 }}
                        xl={{ span: 4, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        md={{ span: 5, offset: 1 }}
                        xs={{ span: 12, offset: 0 }}
                        key={file.uid}
                        style={{
                          margin: "0 5px 0 0",
                          textAlign: "center",
                        }}
                      >
                        <div
                          //style={{ textAlign: "center", marginRight: "10px" }}
                          style={{
                            border: "1px solid whitesmoke",
                            borderRadius: "17px",
                            backgroundColor: "white",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            padding: "10px",
                          }}
                        >
                          <embed
                            src={filePreviews[file.uid]}
                            type={file.type}
                            style={{
                              width: "100%",
                              height: "150px",
                              objectFit: "contain",
                            }}
                          />
                          <div
                            //className="button-separate-benefist"
                            style={{ textAlign: "center" }}
                          >
                            <Button
                              onClick={() => handlePreview(file.uid)}
                              style={{
                                background: "transparent",
                                border: "none",
                                borderRadius: "none",
                                color: "black",
                              }}
                            >
                              <Image
                                src={iconPreview}
                                width={25}
                                preview={false}
                                style={{ marginLeft: "-20px" }}
                              />
                              Previsualizar
                            </Button>
                            <Button
                              onClick={() => handleDelete(file.uid)}
                              style={{
                                background: "transparent",
                                border: "none",
                                borderRadius: "none",
                                color: "black",
                              }}
                            >
                              <Image
                                src={iconDelete}
                                width={25}
                                preview={false}
                                style={{ marginLeft: "-20px" }}
                              />
                              Borrar
                            </Button>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              )}
            </Row>
            <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                <Collapse
                  accordion
                  className="content-collapse"
                  activeKey={activeKeys}
                  onChange={(keys) => setActiveKeys(keys)}
                >
                  {selectedItems.map((item) => {
                    const condition = selectCondiciones.find(
                      (cond) => cond.idCondicion === item
                    );
                    return (
                      <Panel
                        header={condition.nombreCondicion}
                        key={item}
                        className="content-panel"
                      >
                        <Row>
                          <Col xxl={6} xl={6} lg={6} md={6} xs={12}>
                            <Form.Item
                              label="Tipo"
                              name={`tipo-${item}`}
                              rules={tipoRules}
                              className="floating-label-form-item"
                            >
                              <Select
                                value={panelData[item]?.tipo}
                                onChange={(value) =>
                                  handleInputChange(item, "tipo", value)
                                }
                                placeholder="Seleccione tipo"
                              >
                                <Option value="=">{`=`}</Option>
                                <Option value="<">{`<`}</Option>
                                <Option value=">">{`>`}</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xxl={6} xl={6} lg={6} md={6} xs={12}>
                            <Form.Item
                              label="Valor"
                              name={`valor-${item}`}
                              rules={valorRules}
                              className="floating-label-form-item"
                            >
                              {panelData[item]?.tipo === "=" ? (
                                  <Input
                                  value={panelData[item]?.valor}
                                  onChange={(e) =>
                                    handleInputChange(
                                      item,
                                      "valor",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Ingrese valor"
                                  />
                              ) : (
                                <DatePicker
                                  // format="DD-MM-YYYY"
                                  locale={LocaleES}
                                  style={{ width: "100%" }}
                                  value={
                                    panelData[item]?.valor
                                      ? dayjs(panelData[item].valor)
                                      : null
                                  }
                                  onChange={(date, dateString) =>
                                    handleInputChange(item, "valor", dateString)
                                  }
                                  placeholder="Seleccione fecha"
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Panel>
                    );
                  })}
                </Collapse>
              </Col>
            </Row>

            <Row>
              <Form.Item
                className="fi_display margin_dt"
                style={{ marginTop: "50px" }}
              >
                <Button
                  className="boton_primario btn_medidas"
                  htmlType="submit"
                >
                  Crear contenido
                </Button>
              </Form.Item>
            </Row>
          </Form>
          <Divider />
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12} className="subtitulo">
          Mantenedor de contenido
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Table
            columns={columns}
            dataSource={tablaContenido}
            bordered={true}
            loading={loadingContenido}
            style={{ marginTop: "20px" }}
            scroll={{
              x: 1000,
              y: 400,
            }}
          />
        </Col>
      </Row>
      <ErrorModal
        isVisible={modalErrorVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
      <SuccessModal
        isVisible={modalSuccessVisible}
        onClose={handleOkSuccess}
        errorMessage={successMessage}
      />
    </Container>
  );
};

export default MantExecutive;

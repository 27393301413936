import {
  API_LOGIN,
  API_PAISES,
  API_INFO_PERSONA,
  API_CREATE_CUENTA,
  API_CREATE_PERFIL_CUENTA,
  API_VALIDATE_SMS,
  API_REENVIAR_CODIGO,
  API_CAMBIAR_TELEFONO,
  API_EDIT_PERFIL_CUENTA,
  API_RESTABLECER_CLAVE,
  API_CAMBIAR_CLAVE,
  API_TABLA_RESERVAS_CUPONES,
  API_CONTENIDO_PERSONA,
  // API_EDIT_USER,
  // API_SIGNUP,
  API_BENEFICIOS,
  API_SAVE_DOC,
  API_BENEFICIO_LISTA,
  API_BENEFICIO_CREAR,
  API_BENEFICIO_APROBAR,
  API_BENEFICIO_RECHAZAR,
  API_BENEFICIOS_ALL,//EJECUTIVO
  API_EJECUTIVO_CONTENIDO,
  API_EJECUTIVO_CONDICIONES,
  API_EJECUTIVO_AGREGAR_CONTENIDO,
  API_EJECUTIVO_DESACTIVAR_CONTENIDO,
  API_LOGIN_EJECUTIVO,

} from "../shared/endpoints";
import { messages } from "../utils/messages";

//INICIO DE SESION NORMAL Y CON GOOGLE | LOGIN
export const LOGIN_ENTRY = async (values) => {
  //NOTA 1: SI EL ORIGEN ES IGUAL "formulario" EL USUARIO INICIA SESION NORMAL
  //NOTA 2: SI EL ORIGEN ES "google" EL USUARIO INICIA SESION CON CREDENCIALES DE GOOGLE OMITIENDO LA CLAVE
  try {
    const { email, password, origen } = values;

    const objBody = {
      email_login: email,
      password_login: password,
      origen: origen
    };
    
    const response = await fetch(API_LOGIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });
    
    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }

    const jsonData = await response.json();
    console.log(jsonData);
    ///El campo "estado" se agrego despues validar bien si funciona o intefiere con el flujo de momento se usa solo en form perfil correo validar****
    if (jsonData.acceso) {
      return {
        data: jsonData.data,
        acceso: jsonData.acceso,
        estado: jsonData.data.usuario.estado,
        msg: jsonData.message,
      };
    } else {
      return { data: null, acceso: jsonData.acceso, msg: jsonData.message, existe: jsonData.usuarioExiste};
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};

//OBTIENE VALORES DEL FORMULARIOS SELECCIONABLES COMO NACIONALIDAD | PAIS | CODIGO DE AREA
export const GET_PAISES = async () => {
  try {
    const response = await fetch(API_PAISES);

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const data = await response.json();

    if (!data || Object.keys(data).length === 0) {
      return {
        data: null,
        msg: `${messages.error.empty} "PAISES/NACIONALIDAD"`,
      };
    }

    //PREGUNTAR SI SE CAMBIA MENSAJE
    return { data, msg: `${messages.error.empty} "PAISES/NACIONALIDAD"` };
  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      return {
        data: null,
        msg: `${messages.error.network} "PAISES/NACIONALIDAD"`,
      };
    }
    return { data: null, msg: `${messages.error.default} ${error.message}` };
  }
};

//OBTIENE INFORMACION DEL PERFIL DE USUARIO ACTUAL | SE USA EN EL DASHBOARD | ACTUALIZAR PERFIL | CREACION DE PERFIL USUARIO SIN VALIDACION DE CUENTA/VINCULACION
export const INFORMACION_PERSONA = async (values) => {
  try {
    const { rut, email } = values;

    const objBody = {
      rut: rut,
      email: email,
    };

    const response = await fetch(API_INFO_PERSONA, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });
  
    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }

    const jsonData = await response.json();
 
    return { data: jsonData.data, msg: jsonData.message };
  } catch (error) {

    return { data: null, msg: `${messages.error.default}` };
  }
};


//FLUJO DE USUARIO 
//PASO 1.1° SE CREAR CUENTA USUARIO | 
//=> SI "isGoogle" ES "true" SE REGISTRA CON UNA CLAVE GENERICA INDEPENDIENTE SI SE MANDA UNA CLAVE O NO (REGISTRO CON GOOGLE*)
//=> SI "isGoogle" ES "false" SOLO SE CREA CUENTA USUARIO NORMAL CON CLAVE ELEGIDA POR EL USUARIO
//PASO 2.1° SE REGISTRA PERFIL DE USUARIO A LA CUENTA Y SE ENVIAR UN CODIGO SMS AL TELEFONO REGISTRADO (SE VALIDA EN EL PASO 3.1°)
//PASO 3.1° LUEGO SE VALIDA EL CODIGO SMS CON LA FUNCION "VARIFICAR_CUENTA"
//PASO 4.1° SI EL USUARIO AUN NO A VALIDADO SU CUENTA SE USA LA FUNCION "CAMBIAR_TELEFONO" NOS ENTREGA CODIGO SMS PARA VALIDAR LA CUENTA(PASO 3.1°)
//PASO 5.1° SE PUEDE REENVIAR EL CODIGO SMS AL TELEFONO ESCOGIDO (OPCIONAL)
export const CREAR_CUENTA = async (values) => {
  //PASO 1.1°
  try {
    //SE AGREGA "token", SOLO PARA LOS "REGISTROS NORMALES DE CREACION DE CUENTA" 
    //***SE LE AVISO A TABSA QUE ESTA LOGICA "NO ESTA HABILITADA PARA REGISTROS CON GOOGLE" YA QUE ES AUTOMATICO***
    //SE ENVIA CORREO Y EL USUARIO VALIDA SU PERFIL POR MEDIO DE UN BOTON REDIRECCION CON LA INFORMACION QUE FUE RELLENADA EN EL TOKEN

    //EN CASO DE NO VENIR EL TOKEN SERA NULL EL CAMPO * ESTE CASO PASA SOLO PARA LO DE GOOGLE***
    const { email, password,isGoogle, token } = values;

    const tokenExiste = token ? token : null;
    const objBody = {
      email_login: email,
      password_login: password,
      googleLogin : isGoogle,
      token: tokenExiste
    };

    const response = await fetch(API_CREATE_CUENTA, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });
   

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCodeSave} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    const { data, message, exception } = jsonData;

    if (exception !== null) {
      return { data: null, msg: `${message}. *${exception}` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};

export const CREAR_PERFIL_CUENTA = async (values) => {
  //PASO 2.1°
  try {

    const {
      rut,
      pasaporte,
      nombre,
      apellido,
      sexo,
      nacionalidad,
      fecha_nacimiento,
      email,
      telefono_completo,
      residencia_direccion,
      residencia_ciudad,
      residencia_comuna,
      residencia_pais,
    } = values;

    const objBody = {
      email_login: email, //Correo del usuario para asignar con la persona
      id_pos: 3006, //Hardcodear
      rut: rut ? rut : null,
      pasaporte: pasaporte ? pasaporte : null,
      nombre: nombre,
      apellido: apellido,
      estado: 8, //Hardcodear
      sexo: sexo,
      nacionalidad: nacionalidad,
      fecha_nacimiento: fecha_nacimiento,
      email: email,
      telefono: telefono_completo,
      residencia_direccion: residencia_direccion,
      residencia_ciudad: residencia_ciudad,
      residencia_comuna: residencia_comuna,
      residencia_pais: residencia_pais,
    };
    
    const response = await fetch(API_CREATE_PERFIL_CUENTA, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCodeSave} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    const { data, message, exception } = jsonData;

    if (exception !== null) {
      // return { data: null, msg: `${message}. *${exception}` };
      return { data: null, msg: `${message}` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};

export const CAMBIAR_TELEFONO = async (values) => {
  //PASO 4.1°
  try {
    const { id_pos, id_persona, email_login, telefono} = values;
    const objBody = {
      "id_pos" : id_pos,
      "id_persona" : id_persona,
      "email_login" : email_login,
      "telefono" : telefono
    }

    const response = await fetch(API_CAMBIAR_TELEFONO, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });


    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    const { data, message} = jsonData;


    if (!data) {
      return { data: null, msg: `${message}.` };
    } else {
      return { data: jsonData, msg: `${message}. Y se le envió el código verificador de cuenta vía SMS.` };
    }
  } catch (error) {

    return { data: null, msg: `${messages.error.default}` };
  }
};

export const REENVIAR_CODIGO = async (values) => {
  //PASO 5.1°
  try {
    const { telefono } = values;

    const objBody = {
      telefono: telefono,
    }

    const response = await fetch(API_REENVIAR_CODIGO, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();

    if (jsonData.code === null) {
      return { data: null, msg: `${jsonData.message}` };
      // return { data: null, msg: `No se logró enviar el código de verificación de cuenta a su teléfono.` };
    } else {
      return { data: jsonData, msg: `Se envió un código de verificación de cuenta a su teléfono.` };
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};

//OLVIDE CONTRASEÑA || DESDE EL BOTON LINK DEL LOGIN
//PASO 1° SE RESTABLECE LA CLAVE "API_RESTABLECER_CLAVE" (ENVIA SMS)
//PASO 2* SE VALIDA CODIGO SMS "API_VALIDATE_SMS" (SI COINCIDE => IR AL PASO 3°)
//PASO 3° LUEGO SE USAR ENDPOINT API_CAMBIAR_CLAVE CON ORIGEN "restablecer" LO CUAL OMITE LA PASSWORD Y DEJA INICIAR SESION DIRECTAMENTE
export const RESTABLECER_CLAVE = async (values) => {
  //PASO 1° 
  try {
    const { email_login } = values;

    const objBody = {
      "email_login" : email_login
    }
  
    const response = await fetch(API_RESTABLECER_CLAVE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();

    const { data, message  } = jsonData;

    if (data === null) {
      return { data: null, msg: `${message}. ` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {
  
    return { data: null, msg: `${messages.error.default}` };
  }
};

//VERIFICAR CUENTA CON CODIGO SMS SE USA EN LOS SIGUIEN MANTENEDORES: REGISTRAR PERFIL(PUBLICO) | ACTUALIZAR PERFIL(PRIVADO) | OLVIDE CLAVE
export const VARIFICAR_CUENTA = async (values) => {
  //PASO 2*
  //PASO 3.1°
  try {
    const { email, code} = values;

    const objBody = {
      "email_login": email,
      "code" : code
    }

    const response = await fetch(API_VALIDATE_SMS, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });


    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    const { input, access, message } = jsonData;

    if (access === false) {
      return { data: null, msg: `${message}. *Codigo: ${input}` };
    } else {
      return { data: jsonData, msg: `${message}` };
    }
  } catch (error) {
  
    return { data: null, msg: `${messages.error.default}` };
  }
};

//NO ESTA EN USO AUN PERO SE DEBE APLICAR EN LOGIN "OLDIDE CONTRASEÑA" Y EN EL "FORMULARIO DEL ACTUALIZAR PERFIL DESDE EL BOTON DE ACCION"
export const CAMBIAR_CLAVE_OR_OMITE_LOGIN = async (values) => {
  //PASO 3°
  //NOTA 1: SI EL ORIGEN ES IGUAL "restablecer" OMITE CLAVE Y SE LOGEA
  //NOTA 2: SI EL ORIGEN ES "clave" o ES DISTINTO A "restablecer" CAMBIAR LA CLAVE DEL USUARIO.
  try {
    const {email_login, password_login, password_new, origen} = values;
    const objBody = {
      "email_login" : email_login,
      "password_login" : password_login,
      "password_new" : password_new,
      "origen" : origen //Solo desde formulario de restablecer este parámetro para omitir password_login
    }
  
    const response = await fetch(API_CAMBIAR_CLAVE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();

    const { data, message  } = jsonData;

    if (data === null) {
      return { data: null, msg: `${message}. ` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};



//VISTA DE BENEFICIOS
///AUN NO SE ESTAN USUANDO PREGUNTAR CUANDO SEA EL FLUJO PERTINENTE DE LOS BENEFICIOS*****Importante!!!!!
//BENEFICIOS *** QUITAR EL HARDCODE PREGUTAR ANTES
export const CREAR_BENEFICIO = async (values) => {
  try {
    console.log(values, "values CREAR_BENEFICIO");
    const { id_pos, id_persona, tipo, urls} = values;
    const objBodyNuevo = {
      "id_pos" : id_pos,
      "id_persona" : id_persona,
      "tipo" : tipo,
      "url" : urls
    }
    console.log(objBodyNuevo, "obj bueno CREAR_BENEFICIO");
    //perguntar con los datos asi da error puede que sea el id pos el cual no correspond
    // const objBody = {
    //   "id_pos" : 3006,//HARDCODE
    //   "id_persona" : 33,//HARDCODE jquisnchen@tabsa.cl
    //   "tipo" : 1,
    //   "url" : [
    //       "https://recursos.tabsa.cl/storage/documents/HxDe45L8.pdf", 
    //       "https://recursos.tabsa.cl/storage/documents/HxDe45L7.pdf", 
    //       "https://recursos.tabsa.cl/storage/documents/HxDe45L6.pdf"
    //   ]
    // }

    const response = await fetch(API_BENEFICIO_CREAR, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBodyNuevo),
    });
   
    console.log(response, "response");
    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    console.log(jsonData, "jsonData");
    const { data, message } = jsonData;

    if (data === null) {
      return { data: null, msg: `${message}` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {
   
    return { data: null, msg: `${messages.error.default}` };
  }
};
export const APROBAR_BENEFICIO = async (values) => {
  try {
    const {id_solicitud, fecha_vencimiento}= values;

    const objBody = {
      "id_solicitud" : id_solicitud,
      "fecha_vencimiento" : fecha_vencimiento
    }
    console.log(objBody, "objBody APROBAR_BENEFICIO");
    const response = await fetch(API_BENEFICIO_APROBAR, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });


    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    console.log(jsonData, "jsonData APROBAR_BENEFICIO");
    const { data, message } = jsonData;

    if (data === null) {
      return { data: null, msg: `${message}` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};
export const RECHAZAR_BENEFICIO = async (values) => {
  try {
    const {id_solicitud, motivo_rechazo} = values;
   
    const objBody = {
      "id_solicitud" : id_solicitud,
      "observacion" : motivo_rechazo
    }
    console.log("RECHAZAR_BENEFICIO",objBody);
    const response = await fetch(API_BENEFICIO_RECHAZAR, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });
    

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    console.log(jsonData, "jsonData RECHAZAR_BENEFICIO");
    const { data, message } = jsonData;

    if (data === null) {
      return { data: null, msg: `${message}` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};



export const GET_BENEFICIOS = async () => {
  try {
    const response = await fetch(API_BENEFICIOS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });
    
    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const dataJSON = await response.json();

    if (!dataJSON.data || Object.keys(dataJSON.data).length === 0) {
      return {
        data: null,
        msg: `${messages.error.empty} "LISTADO BENEFICIOS"`,
      };
    }
    // return { data: dataJSON , msg: `Se obtuvo el listado de beneficios.`};
    return { data: dataJSON.data , msg: dataJSON.message};
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};
/// IMPORTANTE!!! se debe cambiar body estatico y se debe verificar su funcionalidad
export const LISTA_BENEFICIO = async (values) => {
  try {
    const { id_pos, id_persona } = values;
  
    const objBody = {
      "id_pos" : id_pos ,
      "id_persona" : id_persona
    }

    const response = await fetch(API_BENEFICIO_LISTA, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();


    if (!jsonData || (Array.isArray(jsonData) && jsonData.length === 0)) {
      return { data: null, msg: "No hay datos disponibles" };
    } else {
      return { data: jsonData, msg: "Datos obtenidos con éxito" };
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};

export const GUARDAR_DOCUMENTO = async ( BASE64 ) => {

  try {
    const type = BASE64.split(';')[0].split('/')[1];
    const responseBase64 = await fetch(BASE64);
    const blob = await responseBase64.blob();
    const file = new File([blob], `newImage.${type}`,{ type: `image/${type}` })

    const formData = new FormData();
    formData.append('image', file);

    const response = await fetch(API_SAVE_DOC, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
       return { data: null, msg: `${messages.error.statusCode} ${response.status}`}
    }
    
    const data = await response.json();

    if (!data || Object.keys(data).length === 0) {
      return { data: null, msg: `${messages.error.dontSaveImage}` };
    }

    return { data, msg: `${messages.success.saveImg}` };

  } catch (error) {
    return { data: null, msg: `${messages.error.default} ${error.message}` };
  }
};

export const GUARDAR_DOCUMENTO_FUNCIONAL = async (file) => {
  console.log("llego api GUARDAR_DOCUMENTO_FUNCIONAL");
  try {
    const formData = new FormData();
    formData.append('pdf', file);

    const response = await fetch(API_SAVE_DOC, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
       return { data: null, msg: `${messages.error.statusCode} ${response.status}`}
    }
    
    const data = await response.json();
    
    if (!data || Object.keys(data).length === 0) {
      return { data: null, msg: `${messages.error.dontSaveImage}` };
    }

    return { data, msg: `${messages.success.saveImg}` };

  } catch (error) {

    return { data: null, msg: `${messages.error.default} ${error.message}` };
  }
};

//FUNCIONALIDAD DEL DASHBOARD
export const EDITAR_PERFIL_CUENTA = async (values) => {
  try {
    const {
      id_pos,
      id_persona,
      nombre,
      apellido,
      sexo,
      nacionalidad,
      fecha_nacimiento,
      email_cuenta,
      telefono_completo,
      residencia_direccion,
      residencia_ciudad,
      residencia_comuna,
      residencia_pais,
    } = values;
    
    const objBody = {
      // cambiar id pos y persona
      id_pos : id_pos, 
      id_persona : id_persona,
      nombre: nombre,
      apellido: apellido,
      sexo : sexo,
      nacionalidad : nacionalidad,
      fecha_nacimiento: fecha_nacimiento,
      email :email_cuenta,
      telefono : telefono_completo,
      residencia_direccion : residencia_direccion,
      residencia_ciudad : residencia_ciudad,
      residencia_comuna : residencia_comuna,
      residencia_pais : residencia_pais
    }

    const response = await fetch(API_EDIT_PERFIL_CUENTA, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });
    
    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    const { data, message} = jsonData;
    
    if (data === null) {
      return { data: null, msg: `${message}.` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};

export const TABLA_RESERVAS_CUPONES = async (values) => {
  try {
    const {id_pos, id_persona, pag} = values;

    const objBody = {
      "id_pos": id_pos,
      "id_persona":id_persona,
      "pag": pag
    };
    
    const response = await fetch(API_TABLA_RESERVAS_CUPONES, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    
    if (!jsonData || Object.keys(jsonData).length === 0) {
      return { data: null, msg: `${messages.error.empty}` };
    }

    return { data: jsonData, msg: `${messages.success.dataObtained}` };


  } catch (error) {

    return { data: null, msg: `${messages.error.default} ${error.message}` };
  }
};

//CARRUSEL Y ALERTAS
export const CONTENIDO_PERSONA = async (values) => {

  try {

    const { id_pos, id_persona } = values;

    const objBody = {
      "id_pos" : id_pos,
      "id_persona" : id_persona
    }

    const response = await fetch(API_CONTENIDO_PERSONA, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    const { data, message } = jsonData;

    if (data === null) {
      return { data: null, msg: `${message}.` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {

    return { data: null, msg: `${messages.error.default}` };
  }
};




//* VISTA EJECUTIVA*/
export const TABLA_BENEFICIOS_EJECUTIVO = async () => {
  try {
    const response = await fetch(API_BENEFICIOS_ALL);

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }

    const { data, message } = await response.json();

    if (!data || Object.keys(data).length === 0) {
      return {
        data: [],
        msg: `${messages.error.empty}`,
      };
    }
    if(data === null){
      return { data: data, msg: message };
    }
    return { data: data, msg: message };

  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      return {
        data: null,
        msg: `${messages.error.network} "BENEFICIOS_EJECUTIVO"`,
      };
    }
    return { data: null, msg: `${messages.error.default} ${error.message}` };
  }
};


//OBTIENE TABLA DE DOCUMENTOS SOLICITADOS EN VISTA EJECUTIVA
export const TABLA_CONTENIDO_EJECUTIVO = async () => {
  try {
    const response = await fetch(API_EJECUTIVO_CONTENIDO);

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }

    const { data, message } = await response.json();

    if (!data || Object.keys(data).length === 0) {
      return {
        data: null,
        msg: `${messages.error.empty}`,
      };
    }
    if(data === null){
      return { data: data, msg: message };
    }
    return { data: data, msg: message };

  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      return {
        data: null,
        msg: `${messages.error.network} "CONTENIDO_EJECUTIVO"`,
      };
    }
    return { data: null, msg: `${messages.error.default} ${error.message}` };
  }
};


export const AGREGAR_CONTENIDO_EJECUTIVO = async (values) => {
  try {
    console.log("AGREGAR_CONTENIDO_EJECUTIVO");
    console.log(values, "values");
    const {
      tipo_contenido,
      contenido,
      link,
      prioridad,
      condiciones,
    } = values;
    const objBody = {
      "contenido" : contenido,
      "tipo" : tipo_contenido, //1 Imagen, 2 Texto
      "prioridad" : prioridad,
      "href" : link,
      "condiciones" : condiciones
    }
    console.log(objBody, "objBody");
    
    const response = await fetch(API_EJECUTIVO_AGREGAR_CONTENIDO, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });
    console.log(response, "response");
    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }
    const jsonData = await response.json();
    console.log(jsonData, "jsonData");
    const { data, message } = jsonData;

    if (data === null) {
      return { data: null, msg: `${message}` };
    } else {
      return { data: data, msg: `${message}` };
    }
  } catch (error) {
   
    return { data: null, msg: `${messages.error.default}` };
  }
};
export const DESACTIVAR_CONTENIDO_EJECUTIVO = async () => {
  try {
    const objBody = {

    }
    const response = await fetch(API_EJECUTIVO_DESACTIVAR_CONTENIDO, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });
  } catch (error) {

  }
};


export const LOGIN_ENTRY_EJECUTIVO = async (values) => {

  try {

    const { usuario, password } = values;

    const objBody = {
      user: usuario,
      password: password
    };

    const response = await fetch(API_LOGIN_EJECUTIVO, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objBody),
    });
    console.log(response, "response");
    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }

    const jsonData = await response.json();
    const { auth, user, token, error } = jsonData;

    if (auth === 0 || user === null || token === null || error !== false) {
      return {
        data: null,
        msg: `Error de ingreso de ${error}`
      };
    }
   
    console.log("Autenticación exitosa");
    return { data: jsonData, msg: "Acceso correcto"};
    
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};

export const LISTA_CONDICIONES_EJECUTIVO = async () => {
  try {
    const response = await fetch(API_EJECUTIVO_CONDICIONES);

    if (!response.ok) {
      return {
        data: null,
        msg: `${messages.error.statusCode} ${response.status}`,
      };
    }

    const jsonData = await response.json();

    if (!jsonData || (Array.isArray(jsonData) && jsonData.length === 0)) {
      return { data: null, msg: "No hay datos disponibles" };
    } else {
      return { data: jsonData, msg: "Datos obtenidos con éxito" };
    }
  } catch (error) {
    
    return { data: null, msg: `${messages.error.default}` };
  }
};
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/authContext";
import { Container, Row, Col } from "react-bootstrap";
import { Input, Form, Image, Button, DatePicker, Select, Radio } from "antd";
import greencheck from "../img/green-check.png";
import bluex from "../img/blue-x.png";

import "../css/profile.css"; // Importa tus estilos CSS personalizados
import { GET_PAISES } from "../services/api";
import { ErrorModal } from "../utils/modalMsg";
const { Option } = Select;

const UserProfile = () => {
  const [form] = Form.useForm();
  const [emailUrl, setEmail] = useState("sebas@gmail.cl");
  const [passwordUrl, setPasswordUrl] = useState("X*algo9");
  const [radioValue, setRadioValue] = useState("a");
  const [nacionalidades, setNacionalidades] = useState([]);
  const [paises, setPaises] = useState([]);
  // Modal error
  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Modal error
  const handleOk = () => {
    setModalVisible(false);
  };
  const ObtenerNacionalidad = async () => {
    try {
      const { data, msg } = await GET_PAISES();
      if (data === null) {
        setErrorMessage(msg);
        setModalVisible(true);
      } else {
        setNacionalidades(data);
        setPaises(data);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setModalVisible(true);
    }
  };

  useEffect(() => {
    document.title = "Tabsa | Registrar Perfil";
    ObtenerNacionalidad();
    const apiResponse = { "radio-group": "a", email: emailUrl, password: passwordUrl };
    form.setFieldsValue(apiResponse);
  }, []);

  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  const handleDateChange = (date, dateString) => {
    console.log({ date, dateString });
    form.setFieldsValue({
      fechanacimiento: dateString === "" ? undefined : dateString,
    });
  };

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    console.log("Seleccionado:", e.target.value); // Opcional: para verificación
  };

  const emailRules = [
    {
      type: "email",
      message: "¡No es un email válido!",
    },
    {
      required: true,
      message: "¡Por favor ingrese su correo electrónico!",
    },
    {
      max: 50,
      message: "'Email' no puede tener más de 50 caracteres",
    },
  ];
  const passwordRules = [
    { required: true, message: "¡Por favor ingrese su contraseña!" },
    {
      pattern: new RegExp(
        "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*\\d)[A-Za-z\\d!@#$&*]{8,15}$"
      ),
      message:
        "La contraseña debe tener entre 8 y 15 caracteres, incluir al menos una mayúscula, un número y un caracter especial (!@#$&*).",
    },
  ];

  const rutRules = [
    { required: true, message: "¡Por favor ingrese su RUT!" },
    {
      pattern: new RegExp("^\\d{7,8}-[kK\\d]$"),
      message: "El RUT debe estar en el formato correcto (Ej: 12345678-K).",
    },
  ];
  const pasaporteRules = [ { required: true, message: "¡Por favor ingrese su pasaporte!" }];
  const areaRules = [
    { required: false, message: "¡Por favor ingrese su codigo de area!" },
  ];

  const telefonoRules = [
    { required: true, message: "¡Por favor ingrese su teléfono!" },
    {
      pattern: new RegExp("^\\d{7,8}-[kK\\d]$"),
      message: "El RUT debe estar en el formato correcto (Ej: 12345678-K).",
    },
    {
      max: 20,
      message: "'Teléfono' no puede tener más de 20 caracteres",
    },
  ];

  const nombreRules = [
    { required: false, message: "¡Por favor ingrese su nombre!" },
  ];
  const apellidoRules = [
    { required: false, message: "¡Por favor ingrese su apellido!" },
  ];
  const fechaNacimientoRules = [
    {
      required: false,
      message: "¡Por favor seleccione una fecha de nacimiento!",
    },
  ];
  const sexoRules = [
    {
      required: false,
      message: "¡Por favor seleccione un sexo!",
    },
  ];
  const nacionalidadRules = [
    {
      required: false,
      message: "¡Por favor seleccione su nacionalidad!",
    },
  ];

  const direccionRules = [
    { required: false, message: "¡Por favor ingrese su dirección!" },
  ];
  const ciudadRules = [
    { required: false, message: "¡Por favor ingrese su ciudad!" },
  ];
  const comunaRules = [
    { required: false, message: "¡Por favor ingrese su comuna!" },
  ];

  const paisRules = [
    {
      required: false,
      message: "¡Por favor seleccione su país!",
    },
  ];
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  const [inicioConGoogleStatic, setInicioConGoogleStatic] = useState(true); //borrar controlar con varibale de auth
  const redirectDashboard = () => {
    console.log("tiene sesion=> ", isAuthenticated);
    navigate("/");
  };

  return (
    <Container
      style={{
        // padding: "1% 5% 0 5%",
        padding: "5%",
      }}
      fluid
    >
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              xs={12}
              style={{ textAlign: "right" }}
            >
              {isAuthenticated ? (
                <Image
                  preview={false}
                  src={bluex}
                  width={68}
                  style={{ cursor: "pointer" }}
                  onClick={redirectDashboard}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} xs={6}>
              <h1 className="titulo">Perfil</h1>
            </Col>
          </Row>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
              <Form
                form={form}
                initialValues={{
                  "radio-group": "a",
                }}
                name="floating_input"
                onFinish={onFinish}
                labelAlign="left"
                layout="vertical"
              >
                <Row>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="email"
                      className="form-item"
                      rules={emailRules}
                    >
                      <div className="custom-floating-label-input">
                        <Input id="emailInput" value={emailUrl} disabled/>
                        <label htmlFor="emailInput">Correo electrónico</label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="password"
                      className="form-item"
                      rules={passwordRules}
                    >
                      <div 
                      //className="custom-floating-label-input"
                    className="custom-floating-label-input-password"
                      >
                        <Input.Password id="passwordInput" value={passwordUrl} disabled/>
                        <label htmlFor="passwordInput">Contraseña</label>
                      </div>
                    </Form.Item>
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    {inicioConGoogleStatic ? (
                      <div
                        className="texto_extra"
                        style={{ marginTop: "20px" }}
                      >
                        <Image preview={false} src={greencheck} width={43} />{" "}
                        Usuario iniciado sesión a través de Google.
                      </div>
                    ) : (
                      <></>
                    )}
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Row>
                      {/* <Col xxl={4} xl={4} lg={4} md={4} xs={5}> */}
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5}>
                        <Form.Item
                          name="area"
                          className="form-item"
                          rules={areaRules}
                        >
                          <div className="custom-floating-label-input">
                            <Select
                              id="codAreaInput"
                              placeholder="Seleccionar código de área"
                              allowClear
                              onChange={(e) => {
                                form.setFieldsValue({
                                  ...form.getFieldValue(),
                                  area: e,
                                });
                              }}
                            >
                              <Option value="56">+56</Option>
                              <Option value="555">555</Option>
                              <Option value="444">444</Option>
                            </Select>

                            <label htmlFor="codAreaInput">Código de área</label>
                          </div>
                        </Form.Item>
                      </Col>
                      {/* <Col xxl={8} xl={8} lg={8} md={8} xs={7}> */}
                      <Col xxl={7} xl={7} lg={7} md={7} xs={7}>
                        <Form.Item name="telefono" className="form-item" rules={telefonoRules}>
                          <div className="custom-floating-label-input">
                            <Input id="telefonoInput" />
                            <label htmlFor="telefonoInput">Teléfono</label>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="radio-group"
                      label=" "
                      onChange={handleRadioChange}
                      value={radioValue}
                    >
                      <Radio.Group id="radioCheck" name="radioCheck">
                        <Radio
                          value="a"
                          checked
                          style={{ marginRight: "30px" }}
                        >
                          RUT
                        </Radio>
                        <Radio value="b">PASAPORTE</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    {radioValue === "a" ? (
                      <Form.Item
                        name="rut"
                        className="form-item"
                        rules={rutRules}
                      >
                        <div className="custom-floating-label-input">
                          <Input id="rutInput" />
                          <label htmlFor="rutInput">RUN/DNI</label>
                        </div>
                      </Form.Item>
                    ) : (
                      <Form.Item name="pasaporte" className="form-item" rules={pasaporteRules}>
                        <div className="custom-floating-label-input">
                          <Input id="pasaporteInput" />
                          <label htmlFor="pasaporteInput">Pasaporte</label>
                        </div>
                      </Form.Item>
                    )}
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item name="nombres" className="form-item" rules={nombreRules}>
                      <div className="custom-floating-label-input">
                        <Input id="nombresInput" />
                        <label htmlFor="nombresInput">Nombres</label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item name="apellidos" className="form-item" rules={apellidoRules}>
                      <div className="custom-floating-label-input">
                        <Input id="apellidosInput" />
                        <label htmlFor="apellidosInput">Apellidos</label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="fechanacimiento"
                      className="form-item"
                      rules={fechaNacimientoRules}
                    >
                      <div className="custom-floating-label-input">
                        <DatePicker
                          id="fechanacimientoInput"
                          placeholder="Seleccionar fecha"
                          onChange={handleDateChange}
                          allowClear
                        />
                        <label htmlFor="fechanacimientoInput">
                          Fecha de nacimiento
                        </label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item name="sexo" className="form-item" rules={sexoRules}>
                      <div className="custom-floating-label-input">
                        <Select
                          id="sexoInput"
                          placeholder="Seleccionar sexo"
                          allowClear
                          onChange={(e) => {
                            form.setFieldsValue({
                              ...form.getFieldValue(),
                              sexo: e,
                            });
                          }}
                        >
                          <Option value="1">Hombre</Option>
                          <Option value="2">Mujer</Option>
                          {/* <Option value="other">other</Option> */}
                        </Select>

                        <label htmlFor="sexoInput">Sexo</label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item name="nacionalidad" className="form-item" rules={nacionalidadRules}>
                      <div className="custom-floating-label-input">
                        <Select
                          id="nacionalidadInput"
                          placeholder="Seleccionar nacionalidad"
                          allowClear
                          onChange={(e) => {
                            form.setFieldsValue({
                              ...form.getFieldValue(),
                              nacionalidad: e,
                            });
                          }}
                        >
                          {nacionalidades.map((nacionalidad) => (
                            <Option
                              key={nacionalidad.codigo}
                              value={nacionalidad.codigo}
                            >
                              {nacionalidad.nombre}
                            </Option>
                          ))}
                        </Select>

                        <label htmlFor="nacionalidadInput">Nacionalidad</label>
                      </div>
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    xs={12}
                    style={{ paddingTop: "60px" }}
                  >
                    <h3 className="subtitulo">Residencia</h3>
                    <Row>
                      <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                        <Form.Item name="direccion" className="form-item" rules={direccionRules}>
                          <div className="custom-floating-label-input">
                            <Input id="direccionInput" />
                            <label htmlFor="direccionInput">Dirección</label>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                        <Form.Item name="ciudad" className="form-item" rules={ciudadRules}>
                          <div className="custom-floating-label-input">
                            <Input id="ciudadInput" />
                            <label htmlFor="ciudadInput">Ciudad</label>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                        <Form.Item name="comuna" className="form-item" rules={comunaRules}>
                          <div className="custom-floating-label-input">
                            <Input id="comunaInput" />
                            <label htmlFor="comunaInput">Comuna</label>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                        <Form.Item name="pais" className="form-item" rules={paisRules}>
                          <div className="custom-floating-label-input">
                            <Select
                              id="paisInput"
                              placeholder="Seleccionar país"
                              allowClear
                              onChange={(e) => {
                                form.setFieldsValue({
                                  ...form.getFieldValue(),
                                  pais: e,
                                });
                              }}
                            >
                              {paises.map((pais) => (
                                <Option key={pais.codigo} value={pais.nombre}>
                                  {pais.nombre}
                                </Option>
                              ))}
                            </Select>
                            <label htmlFor="paisInput">País</label>
                          </div>
                        </Form.Item>
                      </Col>

                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        xs={12}
                        style={{ textAlign: "center", marginTop: "20px" }}
                      >
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="boton_primario btn_medidas"
                          >
                            Guardar
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>

      <ErrorModal
        isVisible={modalVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
    </Container>
  );
};

export default UserProfile;

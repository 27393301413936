// import React from 'react'
import React, { useEffect,useState } from "react";
import "../css/floatingbuttons.css";
import { message } from "antd";
import PayIcon from "../img/floatingButtonsImg/pay-icon.svg";
import PrintIcon from "../img/floatingButtonsImg/print-icon.svg";
import PrimeraAngosturaIcon from "../img/floatingButtonsImg/primera-angostura-icon.svg";
import { Row, Col } from "react-bootstrap";
import { Tooltip } from "antd";


import { useTranslation } from "react-i18next";
import { encryptReservaPreventa } from '../utils/cryptoUtils';
import { useAuthContext } from '../contexts/authContext';
import { useNavigate } from "react-router-dom";

const FloatingButtons = ({ clickOut }) => {
    // const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [tokenFloating, setTokenFloating] = useState(null);
    const tokenBotonesFlotante = () => {
        console.log(user);
        // console.log(user.usuario.usuario_cuenta[0].id_usuarios_cuentas);
   
        if (!user || !user.usuario || !user.usuario.usuario_cuenta || !user.usuario.usuario_cuenta[0].personas) {
            navigate("/login");
            return;
        }
          const usuarioCuenta = user.usuario.usuario_cuenta[0].id_usuarios_cuentas;
        const objetoToken = {
          "id_usuarios_cuentas": usuarioCuenta
        }
        console.log(objetoToken, "boton flotante");
        const tokenSave = encryptReservaPreventa(objetoToken);
        console.log(tokenSave);
        setTokenFloating(tokenSave);
        // console.log(tokenFloating, "tokenFloating");
    };
    useEffect(() => {
        tokenBotonesFlotante();
    }, [user]);

    const handleButtonClick = (url) => {
        if (!tokenFloating) {
            message.error("Token no disponible. No se puede abrir la página.");
            return;
        }
        // console.log(`${url}${tokenFloating}`);
        window.open(`${url}${tokenFloating}`, "_blank");
    };

    // return (
    //     <>
    //         <div className="primera-angostura-button" onClick={() => {
    //             clickOut(false, false, false, false, false)
    //             window.open("https://preventa.tabsa.cl", "_blank");
    //         }}>
    //             <Row style={{textAlign:"center"}}>
    //                 <Col>
    //                     <img className="primera-angostura-icon" src={PrimeraAngosturaIcon} alt={"Primera angostura"} />
    //                 </Col>
    //             </Row>
   
    //             <Row style={{textAlign:"center", marginTop:"5px"}}>
    //                 <Col>
    //                     <p className="primera-angostura-text">TICKET PRIMERA ANGOSTURA</p>
    //                 </Col>
    //             </Row>
    //         </div>
    //         <div className="print-button" onClick={() => {
    //             clickOut(false, false, false, false, false)
    //             window.open("https://milodon.tabsa.cl/", "_blank");
    //         }}>
    //             <Row style={{textAlign:"center"}}>
    //                 <Col>
    //                     <img className="print-icon" src={PrintIcon} alt={"imprimir"} />
    //                 </Col>
    //             </Row>
    //             <Row style={{textAlign:"center" , marginTop:"5px"}}>
    //                 <Col>
    //                     <p className="print-text">IMPRIMIR TICKET</p>
    //                 </Col>
    //             </Row>
    //         </div>
    //         <div className="pay-button" onClick={() => {
    //             clickOut(false, false, false, false, false)
    //             window.open("https://milodon.tabsa.cl/", "_blank");
    //         }}>
    //             <Row style={{textAlign:"center"}}>
    //                 <Col>
    //                     <img className="pay-icon" src={PayIcon} alt={"pagar"} />
    //                 </Col>
    //             </Row>
    //             <Row style={{textAlign:"center", marginTop:"5px"}}>
    //                 <Col>
    //                     <p className="pay-text">PAGAR RESERVA</p>
    //                 </Col>
    //             </Row>
    //         </div>
    //     </>
    // )

    // return (
    //     <>
    //         <div className="primera-angostura-button" onClick={() => {
    //             clickOut(false, false, false, false, false)
    //             window.open("https://preventa.tabsa.cl", "_blank");
    //         }}>
    //             <Row style={{textAlign:"center"}} >
    //                 <Col>
    //                     <img className="primera-angostura-icon" src={PrimeraAngosturaIcon} alt={"Primera angostura"} />
    //                 </Col>
    //             </Row>
    //             <Row style={{textAlign:"center", marginTop:"5px"}}>
    //                 <Col>
    //                     <p className="primera-angostura-text">PREVENTA TABSA</p>
    //                 </Col>
    //             </Row>
    //         </div>
    //         <div className="print-button" onClick={() => {
    //             clickOut(false, false, false, false, false)
    //             window.open("https://milodon.tabsa.cl/", "_blank");
    //         }}>
    //             <Row style={{textAlign:"center"}}>
    //                 <Col>
    //                     <img className="print-icon" src={PayIcon} alt={"pagar"} />
    //                 </Col>
    //             </Row>
    //             <Row style={{textAlign:"center", marginTop:"5px"}}>
    //                 <Col>
    //                     <p className="print-text">PAGO DE RESERVA</p>
    //                 </Col>
    //             </Row>
                
    //         </div>

    //     </>
        
    // )
    return (
        <>
            <div className="fixed-buttons">
                <Tooltip title="TICKET PRIMERA ANGOSTURA" color='blue'>
                    {/* <div className="first-button" onClick={() => window.open("https://preventa.tabsa.cl", "_blank")}> */}
                    {/* <div className="first-button" onClick={() => handleButtonClick("https://preventa.tabsa.cl/compra")}> */}
                    <div className="first-button" onClick={() => handleButtonClick("https://preventa.dev.tabsa.cl/?compra=")}>
                        <img className="icon" src={PrimeraAngosturaIcon} alt="Primera Angostura" />
                        <p className="button-text">TICKET PRIMERA ANGOSTURA</p>
                    </div>
                </Tooltip>
                <Tooltip title="PAGO DE RESERVA" color='blue'>
                    {/* <div className="second-button" onClick={() => window.open("", "_blank")}> */}
                    {/* <div className="second-button" onClick={() => handleButtonClick("https://reserva.tabsa.cl/compra")}> */}
                    <div className="second-button" onClick={() => handleButtonClick("https://reservas.dev.tabsa.cl/?cuenta=")}>
                        <img className="icon" src={PayIcon} alt="Pagar" />
                        <p className="button-text">PAGO DE RESERVA</p>
                    </div>
                </Tooltip>
            </div>
        </>
    )
  
}

export default FloatingButtons
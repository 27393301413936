import React,  { useEffect }  from 'react'
import { Col, Row, Container } from "react-bootstrap";
import FormAccount from '../components/FormAccount';

const Login = () => {
  useEffect(() => {
    document.title = "Tabsa | Crear cuenta"
  }, [])
  
  return (
    <Container 
    style={{
        padding: "5%",
    }}
    fluid
    >
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <FormAccount />
        </Col>
      </Row>
    </Container>
  )
}

export default Login
import React,  { useEffect }  from 'react'
import { Col, Row, Container } from "react-bootstrap";
import FormLoginExecutive from '../components/FormLoginExecutive';

const LoginExecutive = () => {
    useEffect(() => {
        document.title = "Tabsa | Login Ejecutivo"
      }, [])
      
      return (
        <Container 
        className='login_executive_background'
        fluid
        >
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
              <FormLoginExecutive />
            </Col>
          </Row>
        </Container>
      )
}

export default LoginExecutive

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, DatePicker, Select, Radio, Image } from "antd";
import { INFORMACION_PERSONA, GET_PAISES } from "../services/api";
import greencheck from "../img/green-check.png";
import bluex from "../img/blue-x.png";
import "../css/label.css"; // Importa tu archivo CSS personalizado
// import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/es"; // import locale for dayjs
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ErrorModal } from "../utils/modalMsg";
import { useAuthContext } from "../contexts/authContext";
// Extend dayjs with custom parse format
dayjs.extend(customParseFormat);

const { Option } = Select;

const MyForm = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  const [idType, setIdType] = useState("rut");
  const [formData, setFormData] = useState({});
  const formKey = formData ? JSON.stringify(formData) : "default";
  const [nacionalidades, setNacionalidades] = useState([]);
  const [paises, setPaises] = useState([]);
  const [codigoAreas, setCodigoAreas] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [inicioConGoogleStatic, setInicioConGoogleStatic] = useState(false); //borrar controlar con varibale de auth
  // Modal error
  const [modalErrorVisible, setModalErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Modal error
  const handleOk = () => {
    setModalErrorVisible(false);
  };
  const redirectDashboard = () => {
    console.log("tiene sesion=> ", isAuthenticated);
    navigate("/");
  };
  //quitar obj estatico y colocar el que venga del login del localstorage******
  const ObtenerPerfilPersona = async () => {
    try {
      const obj = {
        rut: "19989652-0",
        email: "jquinchen@tabsa.cl",
      };
      const { data, msg } = await INFORMACION_PERSONA(obj);
      if (data === null) {
        setErrorMessage(msg);
        setModalErrorVisible(true);
        setBtnDisabled(true);
      } else {
        const {
          email,
          telefono,
          rut,
          pasaporte,
          nombre,
          apellido,
          fecha_nacimiento,
          sexo,
          nacionalidad,
          residencia_direccion,
          residencia_ciudad,
          residencia_comuna,
          residencia_pais,
        } = data;
        setFormData(data);
        !rut ? setIdType("pasaporte") : setIdType("rut");
        setBtnDisabled(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setModalErrorVisible(true);
      setBtnDisabled(true);
    }
  };
  const ObtenerNacionalidad = async () => {
    try {
      const { data, msg } = await GET_PAISES();
      if (data === null) {
        setErrorMessage(msg);
        setModalErrorVisible(true);
        setBtnDisabled(true);
      } else {
        setNacionalidades(data);
        setPaises(data);
        setCodigoAreas(data);

        setBtnDisabled(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setModalErrorVisible(true);
      setBtnDisabled(true);
    }
  };
  // Valores iniciales para el formulario
  // const initialValues = {
  //   nombre: "Javier",
  //   apellido: "Quinchén Arjel",
  //   email: "jquinchen@tabsa.cl",
  //   fecha_nacimiento: dayjs("2000-01-04"),
  //   nacionalidad: "152",
  //   rut: "19989652-0",
  //   telefono: "988118308",
  //   residencia_pais: "",
  //   residencia_ciudad: "",
  //   residencia_comuna: "",
  //   residencia_direccion: "",
  //   sexo: "1", // Inicialmente vacío, se seleccionará desde el formulario
  // };
  let initialValues = {
    email: formData.email || undefined,
    telefono: formData.telefono || undefined,
    nombre: formData.nombre || undefined,
    apellido: formData.apellido || undefined,
    fecha_nacimiento: dayjs(formData.fecha_nacimiento) || undefined,
    sexo: formData.sexo || undefined,
    nacionalidad: formData.nacionalidad
      ? parseInt(formData.nacionalidad)
      : undefined,
    rut: formData.rut || "",
    pasaporte: formData.pasaporte || "",
    residencia_pais: formData.residencia_pais || undefined,
    residencia_ciudad: formData.residencia_ciudad || undefined,
    residencia_comuna: formData.residencia_comuna || undefined,
    residencia_direccion: formData.residencia_direccion || undefined,
    Identificacion: !formData.rut ? "pasaporte" : "rut",
    area: undefined,
  };

  // Función para manejar el envío del formulario
  const onFinish = (values) => {
    // Convertir fecha de nacimiento a string en formato 'YYYY-MM-DD'
    const formattedValues = {
      ...values,
      fecha_nacimiento: values.fecha_nacimiento.format("YYYY-MM-DD"),
    };
    console.log("Received values of form: ", formattedValues);
  };

  // Función para manejar errores en el envío
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    document.title = "Tabsa | Test";
    ObtenerPerfilPersona();
    ObtenerNacionalidad();
  }, []);

  return (
    <Container
      style={{
        padding: "5%",
      }}
      fluid
    >
      <Row>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          style={{ textAlign: "right" }}
        >
          {isAuthenticated ? (
            <Image
              preview={false}
              src={bluex}
              width={68}
              style={{ cursor: "pointer" }}
              onClick={redirectDashboard}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>

      <Row>
        <Col xxl={6} xl={6} lg={6} md={6} xs={6} style={{marginBottom:"20px"}}>
          <h1 className="titulo">Perfil</h1>
        </Col>
      </Row>

      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Form
            key={formKey}
            name="basic"
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            layout="vertical"
            requiredMark={false} // Esto oculta todos los asteriscos
          >
            <Row>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message:
                        "Por favor ingresa un correo electrónico válido!",
                    },
                  ]}
                >
                  <Input placeholder=" " />
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Row>
                  <Col xxl={6} xl={6} lg={6} md={6} xs={6}>
                    <Form.Item
                      label="Area"
                      name="area"
                      className="floating-label-form-item"
                      rules={[
                        {
                          required: true,
                          message: "codigo area requerido!",
                        },
                      ]}
                    >
                      <Select placeholder="Selecciona area" allowClear>
                        {codigoAreas.map((nacionalidad) => (
                          <Option
                            key={nacionalidad.codigo}
                            value={nacionalidad.codigo_telefono}
                          >
                            {nacionalidad.codigo_telefono +
                              " " +
                              nacionalidad.nombre}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} xs={6}>
                    <Form.Item
                      className="floating-label-form-item"
                      label="Teléfono"
                      name="telefono"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa tu número de teléfono!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {inicioConGoogleStatic ? (
                <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                  <div className="texto_extra" style={{ marginTop: "5px" }}>
                    <Image preview={false} src={greencheck} width={43} />{" "}
                    Usuario iniciado sesión a través de Google.
                  </div>
                </Col>
              ) : (
                <></>
              )}
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  label={"Identificación"}
                  name={"Identificacion"}
                  className="floating-label-radio-form-item"
                >
                  <Radio.Group
                    // defaultValue="rut"
                    onChange={(e) => setIdType(e.target.value)}
                  >
                    <Radio value="rut">RUT</Radio>
                    <Radio value="pasaporte">Pasaporte</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                {idType === "rut" && (
                  <Form.Item
                    className="floating-label-form-item"
                    label="RUT"
                    name="rut"
                    rules={[
                      { required: true, message: "Por favor ingresa tu RUT!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                {idType === "pasaporte" && (
                  <Form.Item
                    className="floating-label-form-item"
                    label="Pasaporte"
                    name="pasaporte"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa tu número de pasaporte!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
              </Col>

              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="Nombre"
                  name="nombre"
                  rules={[
                    { required: true, message: "Por favor ingresa tu nombre!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="Apellido"
                  name="apellido"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tu apellido!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="Fecha de Nacimiento"
                  name="fecha_nacimiento"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona tu fecha de nacimiento!",
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD" // Establecer el formato de fecha
                  />
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="Sexo"
                  name="sexo"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona tu sexo!",
                    },
                  ]}
                >
                  <Select placeholder="Selecciona tu sexo" allowClear>
                    <Option value="1">Masculino</Option>
                    <Option value="2">Femenino</Option>
                    {/* Agregar más opciones según sea necesario */}
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="Nacionalidad"
                  name="nacionalidad"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona tu nacionalidad!",
                    },
                  ]}
                >
                  <Select placeholder="Selecciona tu nacionalidad" allowClear>
                    {/* <Option value="152">Chile</Option>
                    <Option value="32">Argentina</Option>
                    <Option value="604">Perú</Option> */}
                    {nacionalidades.map((nacionalidad) => (
                      <Option
                        key={nacionalidad.codigo}
                        value={nacionalidad.codigo}
                      >
                        {nacionalidad.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="País de Residencia"
                  name="residencia_pais"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona tu país de residencia!",
                    },
                  ]}
                >
                  <Select placeholder="Selecciona un país" allowClear>
                    {/* <Option value="CL">Chile</Option>
                    <Option value="AR">Argentina</Option>
                    <Option value="PE">Perú</Option> */}
                    {paises.map((pais) => (
                      <Option key={pais.codigo} value={pais.nombre}>
                        {pais.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="Ciudad de Residencia"
                  name="residencia_ciudad"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tu ciudad de residencia!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="Comuna de Residencia"
                  name="residencia_comuna"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tu comuna de residencia!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                <Form.Item
                  className="floating-label-form-item"
                  label="Dirección de Residencia"
                  name="residencia_direccion"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tu dirección de residencia!",
                    },
                  ]}
                >
                  <Input placeholder="direccion ingresa" />
                </Form.Item>
              </Col>
            </Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              xs={12}
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={btnDisabled}
                  className="boton_primario btn_medidas"
                >
                  Editar Perfil
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Col>
      </Row>

      <ErrorModal
        isVisible={modalErrorVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
    </Container>
  );
};

export default MyForm;

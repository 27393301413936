import icon_home from "../img/icon-house.png";
import icon_folder from "../img/icon-folder.png";
//paths
export const LINK_INICIO = "/";
export const LINK_LOGIN = "/login";
export const LINK_ACCESO_EMPRESA = "/dashboard";
// /recuperar-contrasena
// /solicitar-usuario

//menu
export const LINKS_MENU = [
  {
    label: "Dashboard",
    route: "/",//"/dashboard",
    title: "dashboard",
    description: "Page Dashboard",
    imagen: icon_home
  },
  {
    label: "Beneficios",
    route: "/beneficios",
    title: "beneficios",
    description: "Page Beneficios",
    imagen: icon_folder
  },
  // {
  //   label: "Read",
  //   route: "/read",
  //   title: "read",
  //   description: "Page Read",
  // },
  // {
  //   label: "Login",
  //   route: "/login",
  //   title: "login",
  //   description: "Page Login",
  // },
  
];

//menu ejecutivo
export const LINKS_MENU_EXECUTIVE = [
  {
    label: "Read",
    route: "/read",
    title: "read",
    description: "Page Read",
    imagen: icon_folder
  },
  {
    label: "Documentos",
    route: "/validacion-documentos",
    title: "Documentos",
    description: "Page Documentos",
    imagen: icon_folder
  }
 
];


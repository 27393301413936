import React, { useState } from "react";
import Logotipo from "../img/logo_two.svg";
import { Button, Form, Input, ConfigProvider, Typography, Image } from "antd";
import { Col, Row, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { messages } from "../utils/messages";
import { ErrorModal, InfoModal, SuccessModal } from "../utils/modalMsg";
import { CREAR_CUENTA } from "../services/api";
import { decrypt, encrypt, encryptURLEmail, decryptURLEmail } from "../utils/cryptoUtils";
const { Paragraph, Text } = Typography;

const FormAccount = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // Modal error
  const [modalErrorVisible, setModalErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Modal success
  const [modalSuccessVisible, setModalSuccessVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // Modal info
  const [modalInfoVisible, setModalInfoVisible] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  // Modal error
  const handleOk = () => {
    setModalErrorVisible(false);
  };
  // Modal success
  const handleOkSuccess = () => {

    setModalSuccessVisible(false);
    setModalInfoVisible(true);//aqui se abre modal de notificacion
    setInfoMessage("Para continuar con tu validación de cuenta, sigue los pasos enviados a tu correo electrónico.");//mensaje estandar
  };
  const handleOkInfo = () => {
    //cierra modal informacion y te redirige al login
    setModalInfoVisible(false);
    //CONTROLA VISTA MOBILE LOGIN APP
    window.ReactNativeWebView.postMessage("/login");
    navigate("/login");
  };

  const emailRules = [
    { required: true, message: "¡Por favor, ingrese su correo electrónico!" },
    { type: "email", message: "El correo electrónico no es válido." },
  ];

  // const passwordRules = [
  //   { required: true, message: "Por favor ingrese su contraseña!" },
  //   {
  //     pattern: new RegExp(
  //       "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*\\d)[A-Za-z\\d!@#$&*]{8,15}$"
  //     ),
  //     message:
  //       "La contraseña debe tener entre 8 y 15 caracteres, incluir al menos una mayúscula, un número y un caracter especial (!@#$&*).",
  //   },
  // ];
  const passwordRules = [
    { required: true, message: "Por favor ingrese su contraseña!" },
    {
      pattern: new RegExp("^.{8,15}$"),
      message: "La contraseña debe tener entre 8 y 15 caracteres.",
    },
    {
      pattern: new RegExp("^(?=.*[A-Z]).+$"),
      message: "La contraseña debe incluir al menos una mayúscula.",
    },
    {
      pattern: new RegExp("^(?=.*\\d).+$"),
      message: "La contraseña debe incluir al menos un número.",
    },
    // {
    //   pattern: new RegExp("^(?=.*[!@#$&*.,+-]).+$"),
    //   message: "La contraseña debe incluir al menos un carácter especial (!@#$&*.,+-).",
    // },
    {
      pattern: new RegExp("^(?=.*[!@#$%^&*(),.?\:{}|<>\\[\\];~_+=-]).+$"),
      message: "La contraseña debe incluir al menos un carácter especial (!@#$%^&*.).",
    },
  ];

  const confirmPasswordRules = [
    {
      required: true,
      message: "¡Por favor, ingrese la contraseña de confirmación!",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error("¡La nueva contraseña que ingresó no coincide!")
        );
      },
    }),
  ];

  const customInputTheme = {
    components: {
      Input: {
        // padding: "10px 10px 10px 10px",
        colorTextPlaceholder: "#FFFFFF",
        colorBgContainer: "#0885C7",
        colorText: "#FFFFFF",
        colorError: "#ff4d4f",
      },
    },
  };

  // Función para manejar el envío del formulario
  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    //se mod. logica se debe agregar campo adicional para comprobar si es un registro de google o no
    //login normal = false y login google un true
    
    //se crear objeto para guardar token
    const tokenObj = {
      email_login: values.email,
      password_login: values.password,
      googleLogin : false,
      origen:"formulario"
    }

    //estaban danto probemas
    // const encryptedToken = encrypt(tokenObj);
    // //Valida que el token se encrypto bien
    // const decryptedTokenObj = decrypt(encryptedToken);

    const encryptedToken = encryptURLEmail(tokenObj);
    const decryptedTokenObj = decryptURLEmail(encryptedToken);
    console.log(encryptedToken, "encryptedToken");
    console.log(decryptedTokenObj, "decryptedTokenObj");
    if (JSON.stringify(tokenObj) === JSON.stringify(decryptedTokenObj)) {
      console.log('Encriptación y desencriptación son correctas.');
    } else {
      console.error('Error en la encriptación/desencriptación.');
    }
    const valuesEnviar = {
      ...values,
      isGoogle: false,
      token: encryptedToken
    }
    // console.log(valuesEnviar, "valuesEnviar");
    // return
    const { data, msg } = await CREAR_CUENTA(valuesEnviar);
    // console.log({data,msg},"Onfinish");
    if (data === null) {
      setModalErrorVisible(true);
      setErrorMessage(msg);
    } else {
      
      setModalSuccessVisible(true);
      setSuccessMessage(msg);
      form.resetFields();
      //se quito porq luego que se cierra el modal de info/notificacion te redirige al login
      // setTimeout(() => {
      //   navigate("/login");
      // }, 2000);
    }
  };

  // Función para manejar errores en el envío
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    setModalErrorVisible(true);
    setErrorMessage("Verifique los campos requeridos del formulario.");
  };


  const handlePreventAction = (e) => {
    e.preventDefault();
  };
  return (
    <Container className="contenedor_account" fluid>
      <Row>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          style={{ background: "white", textAlign: "center" }}
        >
          <Image preview={false} width={200} src={Logotipo} />
        </Col>
      </Row>

      <Row className="account_form_conteiner">
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Typography>
            <Text className="titulo">Crear cuenta</Text>
          </Typography>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12} className="account_text">
          Crea tu contraseña para tu cuenta Tabsa. Elige una clave que sea fácil
          de recordar y asegura tu acceso.
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Form
            form={form}
            name="dependencies"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="form_account_content"
            layout="vertical"
          >
            <Row>
              <ConfigProvider theme={customInputTheme}>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <Form.Item name="email" rules={emailRules}>
                    <Input
                      id="emailInput"
                      placeholder={"Dirección correo electrónico"}
                      className="account_input"
                    />
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <Form.Item name="password" rules={passwordRules}>
                    <Input.Password
                      id="passwordInput"
                      placeholder={"Ingresa contraseña"}
                      className="account_input account_icon_pass"
                      onCopy={handlePreventAction}
                      onPaste={handlePreventAction}
                      onCut={handlePreventAction}
                    />
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <Form.Item
                    name="password2"
                    dependencies={["password"]}
                    rules={confirmPasswordRules}
                  >
                    <Input.Password
                      id="password2Input"
                      placeholder={"Confirmar contraseña"}
                      className="account_input account_icon_pass"
                      onCopy={handlePreventAction}
                      onPaste={handlePreventAction}
                      onCut={handlePreventAction}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  xs={6}
                  //   style={{ textAlign: "left", color: "#919EAB" }}
                  className="account_leyend"
                >
                  • Min. 8 caracteres
                </Col>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  xs={6}
                  //   style={{ textAlign: "left", color: "#919EAB" }}
                  className="account_leyend"
                >
                  • 1 símbolo (+,*#$.)
                </Col>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  xs={6}
                  //   style={{ textAlign: "left", color: "#919EAB" }}
                  className="account_leyend"
                >
                  • Max. 15 caracteres
                </Col>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  xs={6}
                  //   style={{ textAlign: "left", color: "#919EAB" }}
                  className="account_leyend"
                >
                  • 1 mayúscula
                </Col>
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  xs={6}
                  //   style={{ textAlign: "left", color: "#919EAB" }}
                  className="account_leyend"
                >
                  • 1 número
                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  style={{ marginTop: "5%" }}
                >
                  <Form.Item>
                    <Button className="boton_account" htmlType="submit">
                      Crear
                    </Button>
                  </Form.Item>
                </Col>
              </ConfigProvider>
            </Row>
          </Form>
        </Col>
      </Row>
      <ErrorModal
        isVisible={modalErrorVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
      <SuccessModal
        isVisible={modalSuccessVisible}
        onClose={handleOkSuccess}
        errorMessage={successMessage}
      />
      <InfoModal
        isVisible={modalInfoVisible}
        onClose={handleOkInfo}
        errorMessage={infoMessage}
      />
    </Container>
  );
};

export default FormAccount;

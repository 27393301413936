import React, { useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import MantExecutive from "../components/MantExecutive";

const ContentExcutive = () => {
  useEffect(() => {
    document.title = "Tabsa | Contenido Ejecutivo";
  }, []);

  return (
    <Container
      style={{
        padding: "2% 5% 0 5%",
      }}
      fluid
    >
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <MantExecutive />
        </Col>
      </Row>
    </Container>
  );
};

export default ContentExcutive;

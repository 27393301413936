import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Radio,
  Image,
  message
} from "antd";
import {
  LOGIN_ENTRY,
  GET_PAISES,
  CREAR_PERFIL_CUENTA,
  CAMBIAR_TELEFONO,
} from "../services/api";
import greencheck from "../img/green-check.png";
import bluex from "../img/blue-x.png";
import "../css/label.css"; // Importa tu archivo CSS personalizado
import {
  ErrorModal,
  InfoModal,
  SmsVerificationModal,
  SuccessModal,
} from "../utils/modalMsg";
import { useAuthContext } from "../contexts/authContext";

import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import updateLocale from "dayjs/plugin/updateLocale";
import LocaleES from "antd/lib/date-picker/locale/es_ES";
import { decrypt, decryptURLEmail } from "../utils/cryptoUtils";
dayjs.extend(updateLocale);

const { Option } = Select;
const FormProfilePublicEmail = () => {
    const navigate = useNavigate();
    //const { id: encryptedUsuario } = useParams(); // Cambia el nombre del parámetr
    // const usuario = decrypt(encryptedUsuario);//decrypt antiguo sin la logica del /
    const usuario = decryptURLEmail(new URLSearchParams(useLocation().search).get("id")); //nuevo
    const { isAuthenticated, encryptSession, decryptSession} = useAuthContext();

    const [form] = Form.useForm();
    const [codigoCuenta, setCodigoCuenta] = useState(null);
    const [bloqEmail, setBloqEmail] = useState(true);//siempre debe estar bloqueado
    const [idType, setIdType] = useState("rut");
    const [formData, setFormData] = useState({});
    const formKey = formData ? JSON.stringify(formData) : "default";
    const [nacionalidades, setNacionalidades] = useState([]);
    const [paises, setPaises] = useState([]);
    const [codigoAreas, setCodigoAreas] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [isFormDisabled, setIsFormDisabled] = useState(false); //para deshabilitar todo el formurlario
    const [inicioConGoogleStatic, setInicioConGoogleStatic] = useState(false); //borrar controlar con varibale de auth
    // Modal Error | Success | SMS
    const [modalErrorVisible, setModalErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
  
    const [modalSuccessVisible, setModalSuccessVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
  
    const [modalSMSVisible, setModalSMSVisible] = useState(false);
    const [emailMessage, setEmailMessage] = useState("");
    const [phoneMessage, setPhoneMessage] = useState("");
    const [maskedPhoneMessage, setMaskedPhoneMessage] = useState("");
    const [primerTelefono, setPrimerTelefono] = useState("");//ese valor es para verificar si cambio el telefono en formulario
    const [infoState, setInfoState] = useState({
      id_pos: null,
      id_persona: null,
      email: ''
    }); //utilizado para solo cambiar el telefono
    const [modalInfoVisible, setModalInfoVisible] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    // Modal info
    const handleOkInfo = () => {
      //cierra modal informacion y te redirige al login
      setModalInfoVisible(false);
      navigate("/login");
    };

    const handleOk = () => {
      setModalErrorVisible(false);
    };
    const handleOkSMS = () => {
      setModalSMSVisible(false);
    };
  
    const handleOkSuccess = () => {
      setModalSuccessVisible(false);
      setModalSMSVisible(true); // Abrir el modal SMS
    };
  

    const redirectLogin = () => {
      console.log("tiene sesion=> ", isAuthenticated);
      // sessionStorage.removeItem('tabsa_session');  // Limpia solo 'tabsa_session'
      navigate("/login");
    };

    const ObtenerNacionalidad = async () => {
      try {
        const { data, msg } = await GET_PAISES();
        if (data === null) {
          setErrorMessage(msg);
          setModalErrorVisible(true);
          setBtnDisabled(true);
        } else {
          setNacionalidades(data);
          setPaises(data);
          setCodigoAreas(data);
  
          setBtnDisabled(false);
        }
      } catch (error) {
        setErrorMessage(error.message);
        setModalErrorVisible(true);
        setBtnDisabled(true);
      }
    };
  

  
    // Función para manejar el envío del formulario
    const onFinish = async (values) => {
      console.log(values, "onFinish");
      console.log(codigoCuenta, "onFinish code");
      // Convertir fecha de nacimiento a string en formato 'YYYY-MM-DD'
  
      const formattedValues = {
        ...values,
        fecha_nacimiento: values.fecha_nacimiento.format("YYYY-MM-DD"),
        telefono_completo: `${values.area.trim()} ${values.telefono.trim()}`,
      };
  
      console.log("formattedValues ", formattedValues);
  
      const { data, msg } = await CREAR_PERFIL_CUENTA(formattedValues);
      console.log({ data, msg }, "Onfinish");
      if (data === null) {
        setModalErrorVisible(true);
        setErrorMessage(msg);
      } else {
        setModalSuccessVisible(true);
        setSuccessMessage(msg);
        //guardar valiable de session de data
        console.log("CREAR_PERFIL_CUENTA", data);
        encryptSession(data)
        //cuando se completa la peticion se recarga el infoState o sino usar lo de session
        setInfoState({
          id_pos: data.id_pos,
          id_persona: data.id_persona,
          email: data.email
        });

        // form.resetFields();
        //aqui quiero que se bloqueen los input
        //**cuando pasa el flujo correcto se despliega el modal de sms y se debe bloquear el bton guardar y tambien los input excepto el de telefono */
        //modificar esta logica cuando este completo el flujo
       
        console.log("Received values of form: ", formattedValues);
        const telefono_completo = formattedValues.telefono_completo;
        const emailSMS = formattedValues.email;

        setEmailMessage(emailSMS)
        setPhoneMessage(telefono_completo);
        setIsFormDisabled(true); // Deshabilitar el formulario
      }
  
    };
  
    // Función para manejar errores en el envío
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
      message.success("Complete todos los campos marcados como requeridos.")
    };
  
    const maskedPhoneFunction = (numero) => {
      console.log(numero,"numero maskedPhoneFunction");
      const numStr = numero.toString();
      console.log(numStr, "numStr");
      const separatePhone = numStr.split(" ");
      const prefix = separatePhone[0]; // "+56"
      const numbers = separatePhone[1]; // "912345678"
      // Mantener solo los últimos 3 dígitos
      const lastDigits = numbers.slice(-3);
      // Enmascarar los dígitos anteriores
      const maskedNumbers = "xxxxxx" + lastDigits;
      // Construir el número de teléfono enmascarado
      const maskedPhone = prefix + " " + maskedNumbers;
      return maskedPhone;
    };

    const verificarCodigo = async(values) => {
        //falta hacer la comparacion con el numero anterior si es el mismo verificar si es otro aplicar endpoint de cambio de numero****
        console.log(primerTelefono, "primer telefono sin modificar");
    
    
        const emailObtenido = form.getFieldValue('email'); 
        const areaObtenido = form.getFieldValue('area'); 
        const telefonoObtenido = form.getFieldValue('telefono');
        console.log(emailObtenido);
        console.log(areaObtenido);
        console.log(telefonoObtenido); // Verifica que obtienes el valor esperado
        let telefonoCompletoEnviar = `${areaObtenido} ${telefonoObtenido}`
        const telefonoMascara = maskedPhoneFunction(telefonoCompletoEnviar);
        setMaskedPhoneMessage(telefonoMascara);
   
    
        const {id_pos, id_persona, email} = infoState;
        let objEnviar = {
        "id_pos" : id_pos,
        "id_persona" : id_persona,
        "email_login" : email,
        "telefono" : telefonoCompletoEnviar
        }
        console.log("es distinto",primerTelefono ,"!==" ,telefonoCompletoEnviar);
        const respTelefono = await CAMBIAR_TELEFONO(objEnviar);
        const {data, msg} = respTelefono;
        if(data === null){
        console.log("no cambio el telefono",data);
        console.log(msg);
        // setPrimerTelefono(data.telefono);
        message.error(msg)
        setModalErrorVisible(true)
        setErrorMessage(msg)
    
        }else{
        console.log("CAMBIO telefono good!! Y se le envio codigo sms", data);
        console.log(msg);
        message.success(msg)

   
        setEmailMessage(emailObtenido);
        setPhoneMessage(telefonoCompletoEnviar);
   
        setModalSMSVisible(true)
        }
    
    
    
    };
  
    const obtenerUsuarioLogin = async() => {
        console.log(usuario, "objeto que llega desde el params encriptado");
        const objParams = {
            "email" : usuario.email_login,
            "password" : usuario.password_login,
            "origen": usuario.origen,
        }

        const resp = await LOGIN_ENTRY(objParams)
        console.log(resp, "obtenerUsuarioLogin aqui se obtiene al usuario");
        console.log(resp.data, "data resp");
        console.log(resp.acceso, "acceso resp");
        if (!resp?.data && resp?.acceso === false) {
          setInfoMessage("El usuario no está autorizado. Revise su último correo para continuar con el registro.");
          setModalInfoVisible(true);
          setBloqEmail(true);
          setBtnDisabled(true);
          return null;
        }
        // if (resp.data === null && resp.acceso === false) {
        //     //modal error y para el login expulsar
        //     setErrorMessage("El usuario no está autorizado");
        //     setModalErrorVisible(true);
        //     setBtnDisabled(true);
        //     //cambiar modal y redireccionar a login
        //     return resp.data
        // } 

        return resp
    };
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            const sessionUser = decryptSession();
            const datosUsuario = await obtenerUsuarioLogin();
            console.log(datosUsuario,"fetchData");
            // Sobresalidación
            if (datosUsuario === null) {
              setInfoMessage("No tienes permisos para continuar.");
              setModalInfoVisible(true);
              setBloqEmail(true); 
              setBtnDisabled(true);
              return;
            }

            // Si `datosUsuario` no es null, puedes continuar con el flujo aquí

            const estadoCorreo = datosUsuario.estado.toString();
            const emailUsuario = datosUsuario.data.usuario.email_login
            //verificar este punto
            console.log("|||||||||||||||||||||||||||||||||");
            console.log("datosUsuario==>", datosUsuario);
            console.log(estadoCorreo, "estadoCorreo");
            console.log(emailUsuario,"emailUsuario");
            //console.log(estadoCorreo);
            // const estado = datosUsuario.estado.toString();
            const inicioGoogle = false;
            // console.log(datosUsuario);
            // console.log(estado);
      
            ObtenerNacionalidad();
      
            let initialValues = {};
      
            switch (estadoCorreo) {
              case "2":
                console.log("Estado 2: Registro Completo", estadoCorreo);
                setInfoMessage("El usuario ya tiene su perfil completo. Puede iniciar sesión.");
                setModalInfoVisible(true);
                setBloqEmail(true); // Se bloquea email
                setBtnDisabled(true);
                break;
              case "3":
                console.log("Estado 3: Usuario Creado", estadoCorreo);
                setBloqEmail(true); // Se bloquea email
                setCodigoCuenta(datosUsuario.data.id_usuarios);
                setInicioConGoogleStatic(inicioGoogle);
      
                if (sessionUser) {
                  console.log(sessionUser, "sessionUser si existe");
                  const partesTelefonoSession = sessionUser.telefono.split(" ");
                  const codigoAreaSession = partesTelefonoSession[0];
                  const numeroTelefonoSession = partesTelefonoSession[1];
                  setInfoState({
                    id_pos: sessionUser.id_pos,
                    id_persona: sessionUser.id_persona,
                    email: sessionUser.email,
                  });
                  initialValues = {
                    email: sessionUser.email || undefined,
                    telefono: numeroTelefonoSession || undefined,
                    nombre: sessionUser.nombre || undefined,
                    apellido: sessionUser.apellido || undefined,
                    fecha_nacimiento: dayjs(sessionUser.fecha_nacimiento) || undefined,
                    sexo: sessionUser.sexo || undefined,
                    nacionalidad: sessionUser.nacionalidad
                      ? parseInt(sessionUser.nacionalidad)
                      : undefined,
                    rut: sessionUser.rut || "",
                    pasaporte: sessionUser.pasaporte || "",
                    residencia_pais: sessionUser.residencia_pais || undefined,
                    residencia_ciudad: sessionUser.residencia_ciudad || undefined,
                    residencia_comuna: sessionUser.residencia_comuna || undefined,
                    residencia_direccion: sessionUser.residencia_direccion || undefined,
                    Identificacion: !sessionUser.rut ? "pasaporte" : "rut",
                    area: codigoAreaSession,
                  };
                  setIsFormDisabled(true);
                } else {
                  console.log(sessionUser, "sessionUser no existe");
                  initialValues = {
                    email: emailUsuario || undefined,
                    Identificacion: "rut",
                    area: "+56",
                  };
                }
                setFormData(initialValues);
                break;
              case "4":
                console.log("Estado 4: Usuario Persona Asociada", estadoCorreo);
                console.log(isAuthenticated, "isAuthenticated");
                console.log(datosUsuario);
                const initials = datosUsuario.data.usuario.usuario_cuenta[0].personas;
                const emailCuenta = usuario.email_login; // directo del params
      
                const partesTelefono = initials.telefono.split(" ");
                const codigoArea = partesTelefono[0];
                const numeroTelefono = partesTelefono[1];
                const primerTelefonoCompleto = `${codigoArea} ${numeroTelefono}`;
                setPrimerTelefono(primerTelefonoCompleto);
      
                setInfoState({
                  id_pos: initials.id_pos,
                  id_persona: initials.id_persona,
                  email: emailCuenta,
                });
                initialValues = {
                  email: emailCuenta || undefined,
                  telefono: numeroTelefono || undefined,
                  nombre: initials.nombre || undefined,
                  apellido: initials.apellido || undefined,
                  fecha_nacimiento: dayjs(initials.fecha_nacimiento) || undefined,
                  sexo: initials.sexo || undefined,
                  nacionalidad: initials.nacionalidad
                    ? parseInt(initials.nacionalidad)
                    : undefined,
                  rut: initials.rut || "",
                  pasaporte: initials.pasaporte || "",
                  residencia_pais: initials.residencia_pais || undefined,
                  residencia_ciudad: initials.residencia_ciudad || undefined,
                  residencia_comuna: initials.residencia_comuna || undefined,
                  residencia_direccion: initials.residencia_direccion || undefined,
                  Identificacion: !initials.rut ? "pasaporte" : "rut",
                  area: codigoArea,
                };
                setFormData(initialValues);
                setBloqEmail(true); // Se bloquea email
                setInicioConGoogleStatic(inicioGoogle);
                setIsFormDisabled(true);
                break;
              default:
                console.log("Estado: Desconocido", estadoCorreo)
                setErrorMessage(`El usuario no está autorizado. Codigo de estado: ${estadoCorreo}`);
                setModalErrorVisible(true);
                setBloqEmail(true); // Se bloquea email
                setBtnDisabled(true);
                // navigate("/");//OLD
                navigate("/login");
            }
          } catch (error) {
            console.error("Error en useEffect:", error);
            setModalErrorVisible(true);
            setErrorMessage(error.message);
            setBtnDisabled(true);
          }
        };
      
        fetchData();
      }, [decryptSession, navigate]);
      
  
    const emailRules = [
      {
        type: "email",
        message: "¡No es un email válido!",
      },
      {
        required: true,
        message: "¡Por favor ingrese su correo electrónico!",
      },
      {
        max: 50,
        message: "'Email' no puede tener más de 50 caracteres",
      },
    ];

    const rutRules = [
      { required: true, message: "¡Por favor ingrese su RUT!" },
      {
        pattern: new RegExp("^\\d{7,8}-[kK\\d]$"),
        message: "El RUT debe estar en el formato correcto (Ej: 12345678-K).",
      },
    ];
    const pasaporteRules = [
      { required: true, message: "¡Por favor ingrese su pasaporte!" },
    ];
    const areaRules = [

      { required: true,message: "¡Codigo area requerido!",},
    ];
  
    const telefonoRules = [
      {
        required: true,
        message: "Por favor ingresa tu número de teléfono!",
      },
      {
        pattern: /^[0-9]+$/,
        message: "El número de teléfono solo debe contener números!",
      },
      {
        max: 20,
        message: "'Teléfono' no puede tener más de 20 caracteres",
      },
    ];
  
    const nombreRules = [
      { required: true, message: "¡Por favor ingrese su nombre!" },
    ];
    const apellidoRules = [
      { required: true, message: "¡Por favor ingrese su apellido!" },
    ];
    const fechaNacimientoRules = [
      {
        required: true,
        message: "¡Por favor seleccione una fecha de nacimiento!",
      },
    ];
    const sexoRules = [
      {
        required: true,
        message: "¡Por favor seleccione un sexo!",
      },
    ];
    
    const nacionalidadRules = [
      {
        required: true,
        message: "¡Por favor seleccione su nacionalidad!",
      },
    ];
    const direccionRules = [
      { required: true, message: "¡Por favor ingrese su dirección!" },
    ];
    const ciudadRules = [
      { required: true, message: "¡Por favor ingrese su ciudad!" },
    ];
    const comunaRules = [
      { required: true, message: "¡Por favor ingrese su comuna!" },
    ];
    const paisRules = [
      {
        required: true,
        message: "¡Por favor seleccione su país!",
      },
    ];
  
    return (
      <Container
        fluid
      >
        <Row>
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            xs={12}
            style={{ textAlign: "right" }}
          >
  
              <Image
                preview={false}
                src={bluex}
                width={68}
                style={{ cursor: "pointer" }}
                onClick={redirectLogin}
              />
  
          </Col>
        </Row>
  
        <Row>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            xs={6}
            style={{ marginBottom: "20px" }}
          >
            <h1 className="titulo">Registrar Perfil</h1>
          </Col>
        </Row>
  
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
            <Form
              form={form}//se coloco despues 11-06-24 fecha ref borrar test
              key={formKey}
              initialValues={formData}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              labelAlign="left"
              layout="vertical"
              requiredMark={false} // Esto oculta todos los asteriscos
              disabled={isFormDisabled}
            >
              <Row>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="Email"
                    name="email"
                    rules={emailRules}
                  >
                    <Input placeholder=" " disabled={bloqEmail} />
                  </Form.Item>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                  <Row>
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={6}
                      xs={6}
                      className="col-floating"
                    >
                      <Form.Item
                        label="Area"
                        name="area"
                        className="floating-label-form-item"
                        rules={areaRules}
                      >
                        <Select placeholder="Selecciona área" allowClear disabled={false}>
                          {codigoAreas
                            .filter((cod) => cod.codigo_telefono !== null)
                            .map((cod) => (
                              <Option
                                key={cod.codigo}
                                value={cod.codigo_telefono}
                              >
                                {cod.codigo_telefono + " " + cod.nombre}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={6}
                      xs={6}
                      className="col-floating"
                    >
                      <Form.Item
                        className="floating-label-form-item"
                        label="Teléfono"
                        name="telefono"
                        rules={telefonoRules}
                      >
                        <Input disabled={false}/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {inicioConGoogleStatic ? (
                  <Col
                    xxl={4}
                    xl={4}
                    lg={4}
                    md={4}
                    xs={12}
                    className="col-floating"
                  >
                    <div className="texto_extra" style={{ marginTop: "5px" }}>
                      <Image preview={false} src={greencheck} width={43} />{" "}
                      Usuario iniciado sesión a través de Google.
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    label={"Identificación"}
                    name={"Identificacion"}
                    className="floating-label-radio-form-item"
                  >
                    <Radio.Group
                      // defaultValue="rut"
                      onChange={(e) => setIdType(e.target.value)}
                    >
                      <Radio value="rut" >RUT</Radio>
                      <Radio value="pasaporte">Pasaporte</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  {idType === "rut" && (
                    <Form.Item
                      className="floating-label-form-item"
                      label="RUT"
                      name="rut"
                      rules={rutRules}
                    >
                      <Input  />
                    </Form.Item>
                  )}
  
                  {idType === "pasaporte" && (
                    <Form.Item
                      className="floating-label-form-item"
                      label="Pasaporte"
                      name="pasaporte"
                      rules={pasaporteRules}
                    >
                      <Input  />
                    </Form.Item>
                  )}
                </Col>
  
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="Nombre"
                    name="nombre"
                    rules={nombreRules}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="Apellido"
                    name="apellido"
                    rules={apellidoRules}
                  >
                    <Input  />
                  </Form.Item>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="Fecha de Nacimiento"
                    name="fecha_nacimiento"
                    rules={fechaNacimientoRules}
                  >
                    <DatePicker
                      // format="YYYY-MM-DD" // Establecer el formato de fecha
                        format="DD-MM-YYYY"
                      locale={LocaleES}
               
                    />
                  </Form.Item>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="Sexo"
                    name="sexo"
                    rules={sexoRules}
                  >
                    <Select placeholder="Selecciona tu sexo" allowClear >
                      <Option value="1">Masculino</Option>
                      <Option value="2">Femenino</Option>
                      {/* Agregar más opciones según sea necesario */}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs="12" style={{marginBottom:"15px"}}>
                <h3 className="subtitulo">Residencia</h3>
                </Col>
              
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="Nacionalidad"
                    name="nacionalidad"
                    rules={nacionalidadRules}
                  >
                    <Select placeholder="Selecciona tu nacionalidad" allowClear >
                      {nacionalidades.map((nacionalidad) => (
                        <Option
                          key={nacionalidad.codigo}
                          value={nacionalidad.codigo}
                        >
                          {nacionalidad.nombre}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                
           
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="Ciudad de Residencia"
                    name="residencia_ciudad"
                    rules={ciudadRules}
                  >
                    <Input  />
                  </Form.Item>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="Comuna de Residencia"
                    name="residencia_comuna"
                    rules={comunaRules}
                  >
                    <Input/>
                  </Form.Item>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="Dirección de Residencia"
                    name="residencia_direccion"
                    rules={direccionRules}
                  >
                    <Input placeholder="direccion ingresa" />
                  </Form.Item>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  xs={12}
                  className="col-floating"
                >
                  <Form.Item
                    className="floating-label-form-item"
                    label="País de Residencia"
                    name="residencia_pais"
                    rules={paisRules}
                  >
                    <Select placeholder="Selecciona un país" allowClear >
                      {paises.map((pais) => (
                        <Option key={pais.codigo} value={pais.nombre}>
                          {pais.nombre}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                xs={12}
                style={{ textAlign: "center", marginTop: "20px" }}
              >
                {
                  !isFormDisabled ?
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={btnDisabled}
                        className="boton_primario btn_medidas"
                      >
                        Guardar
                      </Button>
                  </Form.Item>
                  :
                  <Form.Item>
                  <Button
                    type="primary"
                    // onClick={()=> setModalSMSVisible(true)}
                    onClick={(data)=>verificarCodigo(data)}
                    disabled={modalSMSVisible ? true : false}
                    className="boton_primario btn_medidas"
                  >
                    Verificar codigo
                  </Button>
              </Form.Item>
                }
       
              </Col>
            </Form>
          </Col>
        </Row>
        <SuccessModal
          isVisible={modalSuccessVisible}
          onClose={handleOkSuccess}
          errorMessage={successMessage}
        />
        <ErrorModal
          isVisible={modalErrorVisible}
          onClose={handleOk}
          errorMessage={errorMessage}
        />
        <SmsVerificationModal
          isVisible={modalSMSVisible}
          onClose={handleOkSMS}
          phoneMessage={phoneMessage}
          emailMessage={emailMessage}
          phoneMaskedMessage={maskedPhoneMessage}
        />
        <InfoModal
          isVisible={modalInfoVisible}
          onClose={handleOkInfo}
          errorMessage={infoMessage}
        />
      </Container>
    );
}

export default FormProfilePublicEmail
import React, {useEffect, useState}from "react";
import { Modal, Button, Image, Form, Input,DatePicker,Flex, message} from "antd";
import { Col, Row, Container } from "react-bootstrap";
import iconErrorModal from "../img/red-x.png";
import iconSuccessModal from "../img/green-check.png";
import iconInfoModal from "../img/icon-info.png";
import "../css/modal.css";
import { CAMBIAR_CLAVE_OR_OMITE_LOGIN, REENVIAR_CODIGO, VARIFICAR_CUENTA } from "../services/api";
import { useAuthContext} from "../contexts/authContext";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import LocaleES from "antd/lib/date-picker/locale/es_ES";
const ErrorModal = ({ isVisible, onClose, errorMessage }) => {
  return (
    <Modal
      centered
      closeIcon={false}
      open={isVisible}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button
          key="back"
          onClick={onClose}
          // style={{ display: 'block', margin: 'auto' }}
          className="modal_btn"
        >
          Volver a intentarlo
        </Button>,
      ]}
      // style={{ textAlign: "center", borderRadius: "18px",height:"500px" }}
      className="modal_conteiner"
    >
      <Image
        src={iconErrorModal}
        preview={false}
        // style={{ width: "93px", height: "92px", padding: "10px" }}
        className="modal_img"
      />
      <h1
        // style={{ fontSize: "43px", fontWeight: "700", color: "#000000" }}
        className="modal_titulo"
      >
        Hubo un error
      </h1>
      <h2
        // style={{ fontSize: "25px", fontWeight: "500", color: "#000000" }}
        className="modal_subtitulo"
      >
        {errorMessage}
      </h2>
    </Modal>
  );
};

const SuccessModal = ({ isVisible, onClose, errorMessage }) => {
  return (
    <Modal
      centered
      closeIcon={false}
      open={isVisible}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button
          key="back"
          type="primary"
          onClick={onClose}
          className="modal_btn"
          // style={{ display: 'block', margin: 'auto' }}
        >
          Continuar
        </Button>,
      ]}
      // style={{ textAlign: "center", borderRadius: "18px" }}
      className="modal_conteiner"
    >
      <Image
        src={iconSuccessModal}
        preview={false}
        // style={{ width: "93px", height: "92px", padding: "10px" }}
        className="modal_img"
      />
      <h1
        // style={{ fontSize: "43px", fontWeight: "700", color: "#000000" }}
        className="modal_titulo"
      >
        ¡Exito!
      </h1>
      <h2
        // style={{ fontSize: "25px", fontWeight: "500", color: "#000000" }}
        className="modal_subtitulo"
      >
        {errorMessage}
      </h2>
    </Modal>
  );
};

//VERIFICACION CODIGO SMS MODO PUBLICO / MODO PUBLICO VIA CORREO  SmsVerificationModal el antiguo
const SmsVerificationModalOLD = ({ isVisible, onClose, phoneMessage, emailMessage ,phoneMaskedMessage}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [timer, setTimer] = useState(3600); // Tiempo en segundos (3600 = 1 hora)
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [checkCode, setCheckCode] = useState("");
  const [telefonoSMS, setTelefonoSMS] = useState("");

  const onChange = (text) => {
    console.log('onChange:', text);
    setCheckCode(text)
  };
  const sharedProps = {
    onChange,
  };

  const onFinish = async(values) => {
    // Aquí puedes manejar la lógica para validar el código SMS
    console.log(values,"Código SMS:", values.code,"||checkCode||",checkCode);
    let objEnviar = {
      email: emailMessage,
      code: checkCode
    }
    const respuesta = await VARIFICAR_CUENTA(objEnviar);
    console.log(respuesta, "onfinish del modal sms");
    if(respuesta.data === null){
      message.error(respuesta.msg);
    }else{
      message.success(respuesta.msg);
      onClose();
      navigate("/login")
    }
    // Cierra el modal después de validar el código
    //onClose(); 
  };
  // Función para actualizar el temporizador cada segundo
  const updateTimer = () => {
    setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsTimerExpired(true);
      }
    }, 1000);
  };

  const handleResendCode = async(values) => {
  console.log(values, "handleResendCode");
  console.log(telefonoSMS,"telefonoSMS");
    const objEnviar = {
      telefono: values
    }
    const respReenvioCodigo = await REENVIAR_CODIGO(objEnviar);
    const {data, msg} = respReenvioCodigo;
    console.log("handleResendCode =>",data, "Telefono =>",phoneMessage);
    if(data === null){
      message.error(msg);
    }else{
      message.success(msg);
    }

  };
  // Iniciar el temporizador cuando el componente se monta
  useEffect(() => {

    setTelefonoSMS(phoneMessage);
    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval);
  }, [timer]); // Agregamos timer como dependencia para que se vuelva a ejecutar cuando cambie el temporizador

  // Formatear el tiempo restante en formato MM:SS
  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const codeRules = [
    { required: true, message: "Por favor ingresa el código SMS",},
  ];
  return (
    <Modal
      centered
      closeIcon={false}
      open={isVisible}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
      className="modal_conteiner"
    >
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h1 className="modal_titulo">Se envió un código al teléfono registrado:</h1>
          </Col>
          <Col xs={12}>
            <h2 className="modal_subtitulo">{phoneMaskedMessage}</h2>

          </Col>

          <Col xs={12}>
            <Form key="form" form={form} onFinish={onFinish}>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <Form.Item
                    name="code"
                    rules={codeRules}
                  >
                    <Flex gap="middle" justify="center" align="center" vertical>
                      {/* <Input.OTP length={8} formatter={(str) => str.toUpperCase()} {...sharedProps} size="large" variant={"filled"}/> */}
                      <Input.OTP length={8} formatter={(str) => str.toUpperCase()} {...sharedProps} size="large" />
                    </Flex>
      
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "30px"
                  }}>
                    <span onClick={() => handleResendCode(telefonoSMS)} 
                    style={{cursor:"pointer",textAlign:"left",color:"#0885C780", fontWeight:"bold", textDecoration:"underline"}}
                    >Reenviar código</span>
                    <span  style={{textAlign:"right"}}>Tiempo de expiración {formatTime()}</span>
                  </div>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <Button type="primary" htmlType="submit" className="modal_btn">
                      Validar Código
                    </Button>
                </Col>
              </Row>
              {/* <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa el código SMS",
                  },
                ]}
              >
                   <Flex gap="middle" align="flex-start" vertical>
                    <Input.OTP length={8} formatter={(str) => str.toUpperCase()} {...sharedProps} size="large" variant={"filled"} />
                
             
                   </Flex>
  
              </Form.Item>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "30px"
                    }}>
                      <span onClick={() => handleResendCode(telefonoSMS)} 
                      style={{cursor:"pointer",textAlign:"left",color:"#0885C780", fontWeight:"bold", textDecoration:"underline"}}
                      >Reenviar código</span>
                      <span  style={{textAlign:"right"}}>Tiempo de expiración {formatTime()}</span>
                    </div>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="modal_btn">
                  Validar Código
                </Button>
              </Form.Item> */}
            </Form>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};
//NUEVO CON MODAL DE CONFIMACION Y REDIRECCION
const SmsVerificationModal = ({ isVisible, onClose, phoneMessage, emailMessage, phoneMaskedMessage }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [timer, setTimer] = useState(3600); // Tiempo en segundos (3600 = 1 hora)
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [checkCode, setCheckCode] = useState("");
  const [telefonoSMS, setTelefonoSMS] = useState("");
  const [isUserReadyModalVisible, setIsUserReadyModalVisible] = useState(false);

  const onChange = (text) => {
    console.log('onChange:', text);
    setCheckCode(text);
  };
  const sharedProps = {
    onChange,
  };

  const onFinish = async (values) => {
    console.log(values, "Código SMS:", values.code, "||checkCode||", checkCode);
    let objEnviar = {
      email: emailMessage,
      code: checkCode
    };
    const respuesta = await VARIFICAR_CUENTA(objEnviar);
    console.log(respuesta, "onfinish del modal sms");
    if (respuesta.data === null) {
      message.error(respuesta.msg);
    } else {
      message.success(respuesta.msg);
      setIsUserReadyModalVisible(true);
    }
  };

  const handleUserReadyModalClose = () => {
    setIsUserReadyModalVisible(false);
    navigate("/login");
  };

  const updateTimer = () => {
    setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsTimerExpired(true);
      }
    }, 1000);
  };

  const handleResendCode = async (values) => {
    console.log(values, "handleResendCode");
    console.log(telefonoSMS, "telefonoSMS");
    const objEnviar = {
      telefono: values
    };
    const respReenvioCodigo = await REENVIAR_CODIGO(objEnviar);
    const { data, msg } = respReenvioCodigo;
    console.log("handleResendCode =>", data, "Telefono =>", phoneMessage);
    if (data === null) {
      message.error(msg);
    } else {
      message.success(msg);
    }
  };

  useEffect(() => {
    setTelefonoSMS(phoneMessage);
    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const codeRules = [
    { required: true, message: "Por favor ingresa el código SMS" },
  ];

  return (
    <>
      <Modal
        centered
        closeIcon={false}
        open={isVisible}
        onOk={onClose}
        onCancel={onClose}
        footer={null}
        className="modal_conteiner"
      >
        <Container fluid>
          <Row>
            <Col xs={12}>
              <h1 className="modal_titulo">Se envió un código al teléfono registrado:</h1>
            </Col>
            <Col xs={12}>
              <h2 className="modal_subtitulo">{phoneMaskedMessage}</h2>
            </Col>

            <Col xs={12}>
              <Form key="form" form={form} onFinish={onFinish}>
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <Form.Item
                      name="code"
                      rules={codeRules}
                    >
                      <Flex gap="middle" justify="center" align="center" vertical>
                        <Input.OTP length={8} formatter={(str) => str.toUpperCase()} {...sharedProps} size="large" />
                      </Flex>
                    </Form.Item>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "30px"
                    }}>
                      <span onClick={() => handleResendCode(telefonoSMS)}
                        style={{ cursor: "pointer", textAlign: "left", color: "#0885C780", fontWeight: "bold", textDecoration: "underline" }}
                      >Reenviar código</span>
                      <span style={{ textAlign: "right" }}>Tiempo de expiración {formatTime()}</span>
                    </div>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <Button type="primary" htmlType="submit" className="modal_btn">
                      Validar Código
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal>
           
      <Modal
        centered
        open={isUserReadyModalVisible}
        onOk={handleUserReadyModalClose}
        onCancel={handleUserReadyModalClose}
        footer={[
          <Button key="ok" type="primary" className="modal_btn" onClick={handleUserReadyModalClose}>
            Continuar
          </Button>,
        ]}
        className="modal_conteiner"
      >
        <Image
          src={iconSuccessModal}
          preview={false}
          className="modal_img"
        />
          <h1 className="modal_titulo">¡Exito!</h1>
          <h2 className="modal_subtitulo">Usuario está listo para poder iniciar sesión.</h2>
      </Modal>
    </>
  );
};
//VERIFICACION CODIGO SMS MODO PRIVADO
const SmsVerificationInternoModal = ({ isVisible, onClose, phoneMessage, emailMessage }) => {
  const { updateStatusUser } = useAuthContext();
  const [form] = Form.useForm();
  const [timer, setTimer] = useState(3600); // Tiempo en segundos (3600 = 1 hora)
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [checkCode, setCheckCode] = useState("");
  const [telefonoSMS, setTelefonoSMS] = useState("");
  const onChange = (text) => {
    console.log('onChange:', text);
    setCheckCode(text)
  };
  const sharedProps = {
    onChange,
  };
  const onFinish = async(values) => {
    // Aquí puedes manejar la lógica para validar el código SMS
    console.log(values,"Código SMS:", values.code,"||checkCode||",checkCode);
    let objEnviar = {
      email: emailMessage,
      code: checkCode
    }
    const respuesta = await VARIFICAR_CUENTA(objEnviar);
    console.log(respuesta, "onfinish del modal sms");
    if(respuesta.data === null){
      message.error(respuesta.msg);
    }else{
      updateStatusUser("2");
      message.success(respuesta.msg);
      onClose(); 
    }
    // Cierra el modal después de validar el código
    //onClose(); 
  };
  // Función para actualizar el temporizador cada segundo
  const updateTimer = () => {
    setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsTimerExpired(true);
      }
    }, 1000);
  };

  const handleResendCode = async(values) => {
  console.log(values, "handleResendCode");
  console.log(telefonoSMS,"telefonoSMS");
    const objEnviar = {
      telefono: values
    }
    const respReenvioCodigo = await REENVIAR_CODIGO(objEnviar);
    const {data, msg} = respReenvioCodigo;
    console.log("handleResendCode =>",data, "Telefono =>",phoneMessage);
    if(data === null){
      message.error(msg);
    }else{
      message.success(msg);
    }

  };
  // Iniciar el temporizador cuando el componente se monta
  useEffect(() => {
    setTelefonoSMS(phoneMessage);
    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval);
  }, [timer]); // Agregamos timer como dependencia para que se vuelva a ejecutar cuando cambie el temporizador

  // Formatear el tiempo restante en formato MM:SS
  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const codeRules = [
    { required: true, message: "Por favor ingresa el código SMS",},
  ];


  return (
    <Modal
      centered
      closeIcon={false}
      open={isVisible}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
      className="modal_conteiner"
    >
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h1 className="modal_titulo">Se envió un código al teléfono registrado:</h1>
          </Col>
          <Col xs={12}>
            <h2 className="modal_subtitulo">{phoneMessage}</h2>
          </Col>

          <Col xs={12}>

            <Form key="form" form={form} onFinish={onFinish}>
              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <Form.Item
                    name="code"
                    rules={codeRules}
                  >
                    <Flex gap="middle" justify="center" align="center" vertical>
                      <Input.OTP length={8} formatter={(str) => str.toUpperCase()} {...sharedProps} size="large"/>
                    </Flex>
      
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "30px"
                  }}>
                    <span onClick={() => handleResendCode(telefonoSMS)} 
                    style={{cursor:"pointer",textAlign:"left",color:"#0885C780", fontWeight:"bold", textDecoration:"underline"}}
                    >Reenviar código</span>
                    <span  style={{textAlign:"right"}}>Tiempo de expiración {formatTime()}</span>
                  </div>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <Button type="primary" htmlType="submit" className="modal_btn">
                      Validar Código
                    </Button>
                </Col>
              </Row>



              {/* <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa el código SMS",
                  },
                ]}
              >
                   <Flex gap="middle" align="flex-start" vertical>
                    <Input.OTP length={8} formatter={(str) => str.toUpperCase()} {...sharedProps} size="large" variant={"filled"} />
                
             
                   </Flex>
  
              </Form.Item>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "30px"
                    }}>
                      <span onClick={() => handleResendCode(telefonoSMS)} 
                      style={{cursor:"pointer",textAlign:"left",color:"#0885C780", fontWeight:"bold", textDecoration:"underline"}}
                      >Reenviar código</span>
                      <span  style={{textAlign:"right"}}>Tiempo de expiración {formatTime()}</span>
                    </div>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="modal_btn">
                  Validar Código
                </Button>
              </Form.Item> */}
            </Form>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

//VERIFICACION CODIGO SMS MODO RECUPERAR CONTRASEÑA
const SmsVerificationRecoverModal = ({ isVisible, onClose, emailMessage }) => {
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [checkCode, setCheckCode] = useState("");
  // const newPasswordRules = [
  //   { required: true, message: "Por favor ingrese su nueva contraseña!" },
  //   {
  //     pattern: new RegExp(
  //       "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*\\d)[A-Za-z\\d!@#$&*]{8,15}$"
  //     ),
  //     message:
  //       "La nueva contraseña debe tener entre 8 y 15 caracteres, incluir al menos una mayúscula, un número y un caracter especial (!@#$&*).",
  //   },
  // ];

  const codeRules = [
    { required: true, message: "Por favor ingresa el código SMS",},
  ];

  const newPasswordRules = [
    { required: true, message: "Por favor ingrese su nueva contraseña!" },
    {
      pattern: new RegExp("^.{8,15}$"),
      message: "La contraseña debe tener entre 8 y 15 caracteres.",
    },
    {
      pattern: new RegExp("^(?=.*[A-Z]).+$"),
      message: "La contraseña debe incluir al menos una mayúscula.",
    },
    {
      pattern: new RegExp("^(?=.*\\d).+$"),
      message: "La contraseña debe incluir al menos un número.",
    },
    // {
    //   pattern: new RegExp("^(?=.*[!@#$&*.,+-]).+$"),
    //   message: "La contraseña debe incluir al menos un carácter especial (!@#$&*.,+-).",
    // },
    {
      pattern: new RegExp("^(?=.*[!@#$%^&*(),.?\:{}|<>\\[\\];~_+=-]).+$"),
      message: "La contraseña debe incluir al menos un carácter especial (!@#$%^&*.).",
    },
  ];

  const confirmNewPasswordRules = [
    {
      required: true,
      message: "¡Por favor, ingrese la contraseña de confirmación!",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("newPassword") === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error("¡La nueva contraseña que ingresó no coincide!")
        );
      },
    }),
  ];
  const handlePreventAction = (e) => {
    e.preventDefault();
  };
  const onChange = (text) => {
    console.log('onChange:', text);
    setCheckCode(text)
  };
  const sharedProps = {
    onChange,
  };
  const onFinish = async(values) => {

    console.log(values,"Código SMS:", values.code,"||checkCode||",checkCode);
    console.log(values.newPassword, "values.newPassword");
    let objEnviar = {
      email: emailMessage,
      code: checkCode
    }
    const respuesta = await VARIFICAR_CUENTA(objEnviar);

    if(respuesta.data === null){
      message.error(respuesta.msg);
    }else{
      if(respuesta.data.access === true){
        //codigo coincide
        // const objOmitirLoginEnviar = {
        //   email_login: emailMessage,
        //   // password_login: "omitir_password",//borrar
        //   password_new: "omitir_password",///ingrese dinamico*****
        //   origen: "restablecer",
        // };
        const objOmitirLoginEnviar = {
          email_login: emailMessage,
          password_new: values.newPassword,
          origen: "restablecer",
        };
        const respLogin = await CAMBIAR_CLAVE_OR_OMITE_LOGIN(objOmitirLoginEnviar);
        if (respLogin.data === null) {
          //fallo el flujo mostrar modal
          message.error(respLogin.msg);
        } else {
          console.log(respLogin.data, "front");
          //flujo correcto y inicia sesion omitiendo clave
          message.success(respLogin.msg);
          form.resetFields();
          login(respLogin.data);///no esta completa la data
          navigate("/");
          onClose(); 
        }
      }else{
        //codigo no coincide
        message.error(respuesta.msg);

      }
   
    }

  };
  const onFinishFailed = ({ errorFields }) => {
    errorFields.forEach((error) => {
      if (error.name[0] === 'code') {
        message.error('El código de verificación es requerido.');
      } else if (error.name[0] === 'newPassword') {
        message.error('La nueva contraseña es requerida.');
      }
    });

  };

  const textoDescrip = "Por favor ingrese una nueva contraseña para restablecer su cuenta."
  return (
    <Modal
      centered
      closeIcon={false}
      open={isVisible}
      onOk={null}
      onCancel={null}
      footer={null}
      className="modal_conteiner"
    >
      <Container fluid>
        <Row>
          <Col xs={12}>
            {/* <h1 className="modal_titulo">Se envió un código SMS al teléfono registrado que está vinculado al siguiente email: {emailMessage}</h1> */}
            <h1 className="modal_titulo">¡Se ha enviado un código SMS al teléfono registrado!</h1>
          </Col>
          <Col xs={12}>
            <h2 className="modal_subtitulo">Por favor, ingrese el código de verificación y una nueva contraseña para restablecer su cuenta</h2>
          </Col>

          <Col xs={12}>
            <Form key="form" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>

              {/* <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa el código SMS",
                  },
                ]}
              >
                <Flex gap="middle" align="flex-start" vertical>
                  <Input.OTP length={8} formatter={(str) => str.toUpperCase()} {...sharedProps} size="large" variant={"filled"} />
                </Flex>
  
              </Form.Item>
              <Form.Item name="newPassword" rules={newPasswordRules}>
                  <Input
                    id="newPasswordInput"
                    placeholder={"Nueva contraseña"}
                  />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="modal_btn">
                  Validar Código
                </Button>
              </Form.Item> */}
              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <Form.Item
                    name="code"
                    rules={codeRules}
                  >
                    <Flex gap="middle" justify="center" align="center" vertical>
                      <Input.OTP length={8} formatter={(str) => str.toUpperCase()} {...sharedProps} size="large"/>
                    </Flex>
      
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <Form.Item name="newPassword" rules={newPasswordRules}>
                    <Input
                      id="newPasswordInput"
                      placeholder={"Ingresa nueva contraseña"}
                      className="modal_input"
                      onCopy={handlePreventAction}
                      onPaste={handlePreventAction}
                      onCut={handlePreventAction}
                    />
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                  <Form.Item
                    name="newPassword2"
                    dependencies={["newPassword"]}
                    rules={confirmNewPasswordRules}
                  >
                    <Input
                      id="password2Input"
                      placeholder={"Confirmar nueva contraseña"}
                      className="modal_input"
                      onCopy={handlePreventAction}
                      onPaste={handlePreventAction}
                      onCut={handlePreventAction}
                    />
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12} >
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="modal_btn">
                      Validar Código
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
           


            </Form>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

const InfoModal = ({ isVisible, onClose, errorMessage }) => {
  return (
    <Modal
      centered
      closeIcon={false}
      open={isVisible}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button
          key="back"
          type="primary"
          onClick={onClose}
          className="modal_btn"

        >
          Continuar
        </Button>,
      ]}
      className="modal_conteiner"
    >
      <Image
        src={iconInfoModal}
        preview={false}
        className="modal_img"
      />
      <h1
        className="modal_titulo"
      >
        Notificación
      </h1>
      <h2
        className="modal_subtitulo"
      >
        {errorMessage}
      </h2>
    </Modal>
  );
};

const ConfirmApprovalModal = ({ isVisible, onClose, onApprove, solicitudId }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (isVisible) {
      form.resetFields();
    }
  }, [isVisible]);
  const onFinish = (values) => {
    const approvalData = {
      id_solicitud: solicitudId,
      fecha_vencimiento: values.expirationDate.format('YYYY-MM-DD')
    };
    console.log("Datos de Aprobación:", approvalData);
    //se manda el objeto para hacer la peticion
    onApprove(approvalData);
    onClose();
  };

  return (
    <Modal
      centered
      open={isVisible}
      onCancel={onClose}
      footer={null}
      className="modal_conteiner"
    >
      <Container fluid>
        <Row>
          <Col xs={12} >
            <h1 className="modal_titulo">¿Quieres aprobar la solicitud?</h1>
          </Col>
          <Col xs={12}>
            <h2 className="modal_subtitulo">N.º de solicitud: {solicitudId}</h2>
          </Col>
          <Col xs={12}>
            <Form key="form" form={form} onFinish={onFinish}>
              <Row>
                <Col xs={12}>
                  <Form.Item
                    name="expirationDate"
                    rules={[{ required: true, message: "Por favor ingresa una fecha de vencimiento" }]}
                  >
                    <DatePicker 
                      placeholder="Fecha de Vencimiento" 
                      format="YYYY-MM-DD"
                      locale={LocaleES}
                      disabledDate={(current) => current && current < dayjs().endOf('day')}
                      className="modal_input_confirm"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} className="d-flex justify-content-end">
                  <Form.Item>
                    <Button 
                      htmlType="submit"
                      className="modal_btn_confirm"
                    >
                      OK
                    </Button>
                    <Button 
                      onClick={onClose} 
                      // style={{ backgroundColor: '#f44336', borderColor: '#f44336', color: 'white' }}
                      className="modal_btn_cancel"
                    >
                      Cancelar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

const ConfirmRejectionModal = ({ isVisible, onClose, onReject, solicitudId }) => {
  const [form] = Form.useForm();

  // Resetear el formulario cuando el modal se vuelve visible
  useEffect(() => {
    if (isVisible) {
      form.resetFields();
    }
  }, [isVisible]); // Dependencia en isVisible

  const onFinish = (values) => {
    const rejectionData = {
      id_solicitud: solicitudId,
      motivo_rechazo: values.observation ? values.observation : null
    };
    console.log("Datos de Rechazo:", rejectionData);
    onReject(rejectionData);
    onClose();
  };

  return (
    <Modal
      centered
      open={isVisible}
      onCancel={onClose}
      footer={null}
      className="modal_conteiner"
    >
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h1 className="modal_titulo">¿Quieres rechazar la solicitud?</h1>
          </Col>
          <Col xs={12}>
            <h2 className="modal_subtitulo">N.º de solicitud: {solicitudId}</h2>
          </Col>
          <Col xs={12}>
            <Form key="form" form={form} onFinish={onFinish}>
              <Row>
                <Col xs={12}>
                  <Form.Item
                    name="observation"
                    rules={[{ required: false, message: "Por favor ingresa motivo de rechazo" }]}
                  >
                    <Input 
                      placeholder="Motivo de Rechazo"
                      className="modal_input_confirm"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} className="d-flex justify-content-end">
                  <Form.Item>
                    <Button 
                      htmlType="submit"
                      className="modal_btn_confirm"
                    >
                      OK
                    </Button>
                    <Button 
                      onClick={onClose} 
                      className="modal_btn_cancel"
                    >
                      Cancelar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export { ErrorModal, SuccessModal, SmsVerificationModal,SmsVerificationInternoModal,SmsVerificationRecoverModal, InfoModal, ConfirmApprovalModal, ConfirmRejectionModal};

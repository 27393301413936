import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/authContext";
import { Container, Row, Col } from "react-bootstrap";
import { Input, Form, Image, Button, DatePicker, Select, Radio } from "antd";
import greencheck from "../img/green-check.png";
import bluex from "../img/blue-x.png";

import "../css/profile.css"; // Importa tus estilos CSS personalizados
import { INFORMACION_PERSONA, GET_PAISES } from "../services/api";
import { ErrorModal } from "../utils/modalMsg";
const { Option } = Select;

const FormProfile = () => {
  const [form] = Form.useForm();
  const [perfilValues, setPerfilValues] = useState({
    radioGroup_perfil: "a",
    email_perfil: "",
    password_perfil: "",
    area_perfil: "",
    telefono_perfil: "",
    rut_perfil: "",
    pasaporte_perfil: "",
    nombres_perfil: "",
    apellidos_perfil: "",
    fechanacimiento_perfil: "",
    sexo_perfil: "",
    nacionalidad_perfil: "",
    direccion_perfil: "",
    ciudad_perfil: "",
    comuna_perfil: "",
    pais_perfil: "",
  });
  const [emailUrl, setEmail] = useState("sebas@gmail.cl");
  const [passwordUrl, setPasswordUrl] = useState("X*algo91");
  const [radioValue, setRadioValue] = useState("a");
  const [nacionalidades, setNacionalidades] = useState([]);
  const [paises, setPaises] = useState([]);
  // Modal error
  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Modal error
  const handleOk = () => {
    setModalVisible(false);
  };
  const ObtenerNacionalidad = async () => {
    try {
      const { data, msg } = await GET_PAISES();
      if (data === null) {
        setErrorMessage(msg);
        setModalVisible(true);
      } else {
        setNacionalidades(data);
        setPaises(data);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setModalVisible(true);
    }
  };

  const ObtenerPerfilPersona = async () => {
    try {
      const obj = {
        rut: "19989652-0",
        email: "jquinchen@tabsa.cl",
      };
      const { data, msg } = await INFORMACION_PERSONA(obj);
      if (data === null) {
        console.log("Pers=>mal", data, msg);
      } else {
        console.log("Pers=>bien", data, msg);
        const {
          email,
          telefono,
          rut,
          pasaporte,
          nombre,
          apellido,
          fecha_nacimiento,
          sexo,
          nacionalidad,
          residencia_direccion,
          residencia_ciudad,
          residencia_comuna,
          residencia_pais,
        } = data.data;
        console.log(data.data, "Aqui!!!!!!!!!!!", fecha_nacimiento,nacionalidad);
        setPerfilValues({
            radioGroup_perfil: "a",
            email_perfil: email,
            // password_perfil: null,
            // area_perfil: null,
            telefono_perfil: telefono,
            rut_perfil: rut,
            pasaporte_perfil: pasaporte,
            nombres_perfil: nombre,
            apellidos_perfil: apellido,
            // fechanacimiento_perfil: fecha_nacimiento,
            sexo_perfil: sexo,
            nacionalidad_perfil: nacionalidad,
            direccion_perfil: residencia_direccion,
            ciudad_perfil: residencia_ciudad,
            comuna_perfil: residencia_comuna,
            pais_perfil: residencia_pais
        })
        // const apiResponse = { "radio-group": "a", email: email, password: passwordUrl,rut:"1111", telefono: telefono };
        // form.setFieldsValue(apiResponse);

        form.setFieldValue({
          email: email,
        })
      }
    } catch (error) {
      console.log("Pers=>catch", error);
    }
  };

  useEffect(() => {
    // document.title = "Tabsa | Perfil";
    ObtenerNacionalidad();
    ObtenerPerfilPersona();
    // const apiResponse = { "radio-group": "a", email: emailUrl, password: passwordUrl };
    // form.setFieldsValue(apiResponse);
    // form.resetFields()
    // form.setFieldsValue({
    //     email: '1',
    //     password: 1,
    //     "rut": "1212"
    // });
  }, []);

  const onFinish = (values) => {
    console.log("Received values:", values);

    const formData = {
      ...form.getFieldsValue(), // Obtiene todos los valores de los campos habilitados
      email: perfilValues.email_perfil // Asegúrate de incluir manualmente el email deshabilitado
    };
    console.log(formData, "formData");
  };

  const handleDateChange = (e,date, dateString) => {

    console.log({date, dateString },"handleDateChange");

    form.setFieldsValue({
      fechanacimiento: dateString === "" ? undefined : dateString,
    });
  };

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setPerfilValues({...perfilValues , radioGroup_perfil: e.target.value})
    console.log("Seleccionado:", e.target.value); // Opcional: para verificación
  };

  const emailRules = [
    {
      type: "email",
      message: "¡No es un email válido!",
    },
    {
      required: true,
      message: "¡Por favor ingrese su correo electrónico!",
    },
    {
      max: 50,
      message: "'Email' no puede tener más de 50 caracteres",
    },
  ];
  const passwordRules = [
    { required: true, message: "¡Por favor ingrese su contraseña!" },
    {
      pattern: new RegExp(
        "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*\\d)[A-Za-z\\d!@#$&*]{8,15}$"
      ),
      message:
        "La contraseña debe tener entre 8 y 15 caracteres, incluir al menos una mayúscula, un número y un caracter especial (!@#$&*).",
    },
  ];
  const rutRules = [
    { required: true, message: "¡Por favor ingrese su RUT!" },
    {
      pattern: new RegExp("^\\d{7,8}-[kK\\d]$"),
      message: "El RUT debe estar en el formato correcto (Ej: 12345678-K).",
    },
  ];
  const pasaporteRules = [
    { required: true, message: "¡Por favor ingrese su pasaporte!" },
  ];
  const areaRules = [
    { required: false, message: "¡Por favor ingrese su codigo de area!" },
  ];
  const telefonoRules = [
    { required: true, message: "¡Por favor ingrese su teléfono!" },
    {
      max: 20,
      message: "'Teléfono' no puede tener más de 20 caracteres",
    },
  ];
  const nombreRules = [
    { required: false, message: "¡Por favor ingrese su nombre!" },
  ];
  const apellidoRules = [
    { required: false, message: "¡Por favor ingrese su apellido!" },
  ];
  const fechaNacimientoRules = [
    {
      required: false,
      message: "¡Por favor seleccione una fecha de nacimiento!",
    },
  ];
  const sexoRules = [
    {
      required: false,
      message: "¡Por favor seleccione un sexo!",
    },
  ];
  const nacionalidadRules = [
    {
      required: false,
      message: "¡Por favor seleccione su nacionalidad!",
    },
  ];
  const direccionRules = [
    { required: false, message: "¡Por favor ingrese su dirección!" },
  ];
  const ciudadRules = [
    { required: false, message: "¡Por favor ingrese su ciudad!" },
  ];
  const comunaRules = [
    { required: false, message: "¡Por favor ingrese su comuna!" },
  ];
  const paisRules = [
    {
      required: false,
      message: "¡Por favor seleccione su país!",
    },
  ];

  function handleNumberInput(e) {
    const value = e.target.value;
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setPerfilValues({...perfilValues, numero: value});
    }
  }
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  const [inicioConGoogleStatic, setInicioConGoogleStatic] = useState(true); //borrar controlar con varibale de auth
  const redirectDashboard = () => {
    console.log("tiene sesion=> ", isAuthenticated);
    navigate("/");
  };

  return (
    <Container fluid>
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              xs={12}
              style={{ textAlign: "right" }}
            >
              {isAuthenticated ? (
                <Image
                  preview={false}
                  src={bluex}
                  width={68}
                  style={{ cursor: "pointer" }}
                  onClick={redirectDashboard}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} xs={6}>
              <h1 className="titulo">Perfil Privado</h1>
            </Col>
          </Row>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
              <Form
                form={form}
                initialValues={perfilValues}
                // initialValues={{ email: 'se', password: 'aaaa' }}
                // initialValues={{
                // "email": "s@s.com",
                //   "radio-group": "a",
                //   rut: "123-2",
                //   telefono: "1234",
                // }}
    
                name="floating_input"
                onFinish={onFinish}
                labelAlign="left"
                layout="vertical"
                autoComplete="off"
              >
                <Row>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="email"
                      className="form-item"
                      rules={emailRules}
                    >
                      <div className="custom-floating-label-input">
                        <Input
                          id="emailInput"
                          value={perfilValues.email_perfil}
                          onChange={(e)=>{  console.log("Nuevo valor de email:", e.target.value); setPerfilValues({...perfilValues , email_perfil: e.target.value})}}
                          
            
                        />
                        <label htmlFor="emailInput">Correo electrónico</label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    {/* <Form.Item
                      name="password"
                      className="form-item"
                      rules={passwordRules}
                    >
                      <div
                        //className="custom-floating-label-input"
                        className="custom-floating-label-input-password"
                      >
                        <Input.Password
                          id="passwordInput"
                          autoComplete="new-password"
                        //   value={passwordUrl}
                        //   disabled
                        />
                        <label htmlFor="passwordInput">Contraseña</label>
                      </div>
                    </Form.Item> */}
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    {inicioConGoogleStatic ? (
                      <div
                        className="texto_extra"
                        style={{ marginTop: "20px" }}
                      >
                        <Image preview={false} src={greencheck} width={43} />{" "}
                        Usuario iniciado sesión a través de Google.
                      </div>
                    ) : (
                      <></>
                    )}
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Row>
                      {/* <Col xxl={4} xl={4} lg={4} md={4} xs={5}> */}
                      <Col xxl={5} xl={5} lg={5} md={5} xs={5}>
                        <Form.Item
                          name="area"
                          className="form-item"
                          rules={areaRules}
                        >
                          <div className="custom-floating-label-input">
                            <Select
                              id="codAreaInput"
                              placeholder="Seleccionar código de área"
                              allowClear
                              onChange={(e) => {
                                form.setFieldsValue({
                                  ...form.getFieldValue(),
                                  area: e.value,
                                });
                              }}
                            >
                              <Option value="56">+56</Option>
                              <Option value="555">555</Option>
                              <Option value="444">444</Option>
                            </Select>

                            <label htmlFor="codAreaInput">Código de área</label>
                          </div>
                        </Form.Item>
                      </Col>
                      {/* <Col xxl={8} xl={8} lg={8} md={8} xs={7}> */}
                      <Col xxl={7} xl={7} lg={7} md={7} xs={7}>
                        <Form.Item
                          name="telefono"
                          className="form-item"
                          rules={telefonoRules}
                        >
                          <div className="custom-floating-label-input">
                            <Input
                              id="telefonoInput"
                              type="number"
                              value={perfilValues.telefono_perfil}
                              onChange={(e)=>{setPerfilValues({...perfilValues , telefono_perfil: e.target.value})}}
                              disabled
                            />
                            <label htmlFor="telefonoInput">Teléfono</label>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="radio-group"
                      label=" "
                      onChange={(e) => {handleRadioChange(e)}}
                      value={perfilValues.radioGroup_perfil}
                    >
                      <Radio.Group id="radioCheck" name="radioCheck">
                        <Radio
                          value="a"
                          checked
                          style={{ marginRight: "30px" }}
                        >
                          RUT
                        </Radio>
                        <Radio value="b">PASAPORTE</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    {radioValue === "a" ? (
                      <Form.Item
                        name="rut"
                        className="form-item"
                        rules={rutRules}
                      >
                        <div className="custom-floating-label-input">
                          <Input
                            id="rutInput"
                            value={perfilValues.rut_perfil}
                            onChange={(e)=>{setPerfilValues({...perfilValues , rut_perfil: e.target.value})}}
                          />
                          <label htmlFor="rutInput">RUN/DNI</label>
                        </div>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name="pasaporte"
                        className="form-item"
                        rules={pasaporteRules}
                      >
                        <div className="custom-floating-label-input">
                          <Input
                            id="pasaporteInput"
                            value={perfilValues.pasaporte_perfil}
                            onChange={(e)=>{setPerfilValues({...perfilValues , pasaporte_perfil: e.target.value})}}
                          />
                          <label htmlFor="pasaporteInput">Pasaporte</label>
                        </div>
                      </Form.Item>
                    )}
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="nombres"
                      className="form-item"
                      rules={nombreRules}
                    >
                      <div className="custom-floating-label-input">
                        <Input
                          id="nombresInput"
                          value={perfilValues.nombres_perfil}
                          onChange={(e)=>{setPerfilValues({...perfilValues , nombres_perfil: e.target.value})}}
                        />
                        <label htmlFor="nombresInput">Nombres</label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="apellidos"
                      className="form-item"
                      rules={apellidoRules}
                    >
                      <div className="custom-floating-label-input">
                        <Input
                          id="apellidosInput"
                          value={perfilValues.apellidos_perfil}
                          onChange={(e)=>{setPerfilValues({...perfilValues , apellidos_perfil: e.target.value})}}
                        />
                        <label htmlFor="apellidosInput">Apellidos</label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="fechanacimiento"
                      className="form-item"
                      rules={fechaNacimientoRules}
                      initialValue={perfilValues.fechanacimiento_perfil}
                    >
                      <div className="custom-floating-label-input">
                        <DatePicker
                          id="fechanacimientoInput"
                          placeholder="Seleccionar fecha"
                          onChange={handleDateChange}
                          allowClear
                          value={perfilValues.fechanacimiento_perfil}
                        />
                        <label htmlFor="fechanacimientoInput">
                          Fecha de nacimiento
                        </label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="sexo"
                      className="form-item"
                      rules={sexoRules}
                    >
                      <div className="custom-floating-label-input">
                        <Select
                          id="sexoInput"
                          placeholder="Seleccionar sexo"
                          allowClear
                          value={perfilValues.sexo_perfil}
                          onChange={(e)=>{setPerfilValues({...perfilValues , sexo_perfil: e.target.value})}}
                          // onChange={(e) => {
                          //   form.setFieldsValue({
                          //     ...form.getFieldValue(),
                          //     sexo: e,
                          //   });
                          // }}
                        >
                          <Option value="1">Hombre</Option>
                          <Option value="2">Mujer</Option>
                          {/* <Option value="other">other</Option> */}
                        </Select>

                        <label htmlFor="sexoInput">Sexo</label>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                    <Form.Item
                      name="nacionalidad"
                      className="form-item"
                      rules={nacionalidadRules}
                    >
                      <div className="custom-floating-label-input">
                        <Select
                          id="nacionalidadInput"
                          placeholder="Seleccionar nacionalidad"
                          allowClear
                          onChange={(e) => {
                            form.setFieldsValue({
                              ...form.getFieldValue(),
                              nacionalidad: e,
                            });
                          }}
                          value={perfilValues.nacionalidad_perfil}
                        >
                          {nacionalidades.map((nacionalidad) => (
                            <Option
                              key={nacionalidad.codigo}
                              value={nacionalidad.codigo}
                            >
                              {nacionalidad.nombre}
                            </Option>
                          ))}
                          
                        </Select>

                        <label htmlFor="nacionalidadInput">Nacionalidad</label>
                      </div>
                    </Form.Item>
                  </Col>

                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    xs={12}
                    style={{ paddingTop: "60px" }}
                  >
                    <h3 className="subtitulo">Residencia</h3>
                    <Row>
                      <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                        <Form.Item
                          name="direccion"
                          className="form-item"
                          rules={direccionRules}
                        >
                          <div className="custom-floating-label-input">
                            <Input
                              id="direccionInput"
                              value={perfilValues.direccion_perfil}
                              onChange={(e)=>{setPerfilValues({...perfilValues , direccion_perfil: e.target.value})}}
                            />
                            <label htmlFor="direccionInput">Dirección</label>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                        <Form.Item
                          name="ciudad"
                          className="form-item"
                          rules={ciudadRules}
                        >
                          <div className="custom-floating-label-input">
                            <Input
                              id="ciudadInput"
                              value={perfilValues.ciudad_perfil}
                              onChange={(e)=>{setPerfilValues({...perfilValues , ciudad_perfil: e.target.value})}}
                            />
                            <label htmlFor="ciudadInput">Ciudad</label>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                        <Form.Item
                          name="comuna"
                          className="form-item"
                          rules={comunaRules}
                        >
                          <div className="custom-floating-label-input">
                            <Input
                              id="comunaInput"
                              value={perfilValues.comuna_perfil}
                              onChange={(e)=>{setPerfilValues({...perfilValues , comuna_perfil: e.target.value})}}
                            />
                            <label htmlFor="comunaInput">Comuna</label>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col xxl={4} xl={4} lg={4} md={4} xs={12}>
                        <Form.Item
                          name="pais"
                          className="form-item"
                          rules={paisRules}
                        >
                          <div className="custom-floating-label-input">
                            <Select
                              id="paisInput"
                              placeholder="Seleccionar país"
                              allowClear
                              onChange={(e) => {
                                form.setFieldsValue({
                                  ...form.getFieldValue(),
                                  pais: e,
                                });
                              }}
                              value={perfilValues.pais_perfil}
                            >
                              {paises.map((pais) => (
                                <Option key={pais.codigo} value={pais.nombre}>
                                  {pais.nombre}
                                </Option>
                              ))}
                            </Select>
                            <label htmlFor="paisInput">País</label>
                          </div>
                        </Form.Item>
                      </Col>

                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        xs={12}
                        style={{ textAlign: "center", marginTop: "20px" }}
                      >
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="boton_primario btn_medidas"
                          >
                            Guardar
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>

      <ErrorModal
        isVisible={modalVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
    </Container>
  );
};

export default FormProfile;

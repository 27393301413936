import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import {
  Table,
  Space,
  Button,
  Tag,
  Image,
  Divider,
  Typography,
  Alert,
  Tooltip,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import iconUpload from "../img/icon-upload.png";
import { APROBAR_BENEFICIO, RECHAZAR_BENEFICIO, TABLA_BENEFICIOS_EJECUTIVO } from "../services/api";
import { ConfirmApprovalModal, ConfirmRejectionModal, ErrorModal, SuccessModal } from "../utils/modalMsg";
import { useAuthContext } from "../contexts/authContext";
const MantBenefitsExecutive = () => {
  const { user, role, isAuthenticated } = useAuthContext();
  const [userName, setUserName] = useState("Usuario");
  const [tablaBeneficios, setTablaBeneficios] = useState([]);
  const [loadingBeneficios, setLoadingBeneficios] = useState(false);
  const [modalErrorVisible, setModalErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isApprovalModalVisible, setApprovalModalVisible] = useState(false);
  const [selectedSolicitudId, setSelectedSolicitudId] = useState(null);
  const [isRejectionModalVisible, setRejectionModalVisible] = useState(false);
  const [modalSuccessVisible, setModalSuccessVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  
  const handleOkSuccess = () => {
    setModalSuccessVisible(false);
  };
  const refreshTable = async () => {
    setLoadingBeneficios(true);
    const { data, msg } = await TABLA_BENEFICIOS_EJECUTIVO();
    console.log(data, msg, "FRONT");
    if (data === null) {
      setErrorMessage(msg);
      setModalErrorVisible(true);
      setLoadingBeneficios(false);
    } else {
      let formattedData = [];

      if (data && data.length > 0) {
        formattedData = data.map((item) => ({
          key: item.id_solicitud_beneficios,
          id_solicitud: item.id_solicitud_beneficios,
          solicitante: item.id_persona,
          beneficio: item.tipo[0].descripcion,
          documentos: item.documentos,
          estado: item.estados_beneficios.descripcion,
          expira: item.fechaVencimiento,
          observacion: item.observacionEjecutivo,
        }));
      }

      setTablaBeneficios(formattedData);
      setLoadingBeneficios(false);
    }
  };
  const handleApprove = async(approvalData) => {
    console.log("OBJ Aprobacion", approvalData);
    // Lógica para enviar los datos
    const { data, msg } = await APROBAR_BENEFICIO(approvalData);
    console.log({data, msg});
    if (data === null) {
      setModalErrorVisible(true);
      setErrorMessage(msg);
    } else {
      refreshTable();
      setModalSuccessVisible(true);
      setSuccessMessage(msg);
    }
  };
  const handleReject = async(rejectionData) => {
        console.log("OBJ Rechazo", rejectionData);
        // Lógica para enviar los datos
        const { data, msg } = await RECHAZAR_BENEFICIO(rejectionData);
        console.log({data, msg});
        if (data === null) {
          setModalErrorVisible(true);
          setErrorMessage(msg);
        } else {
          refreshTable();
          setModalSuccessVisible(true);
          setSuccessMessage(msg);
        }

  };
  const handleOk = () => {
    setModalErrorVisible(false);
  };
  const columns = [
    {
      title: "ID Solicitud",
      dataIndex: "id_solicitud",
      key: "id_solicitud",

      render: (text) => <a>{text}</a>,
    },
    {
      title: "Solicitante",
      dataIndex: "solicitante",
      key: "solicitante",

      render: (text) => <a>{text}</a>,
    },
    {
      title: "Beneficio",
      dataIndex: "beneficio",
      key: "beneficio",
    },
    {
      title: "Documentos",
      dataIndex: "documentos",
      key: "documentos",
      render: (_, record) => (
        <Space>
          {record.documentos &&
            record.documentos.map((docs, index) => (
              <Tooltip
                key={index}
                title={`Ver documento ${index + 1}`}
                color="blue"
              >
                <div
                  key={docs.id_solicitud_documento}
                  onClick={() => window.open(docs.url, "_blank")}
                  style={{ cursor: "pointer", display: "inline-block" }}
                >
                  <Image
                    src={iconUpload}
                    width={36}
                    alt="Ver documento"
                    preview={false}
                  />
                </div>
              </Tooltip>
            ))}
        </Space>
      ),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
    },
    {
      title: "Expiración",
      dataIndex: "expira",
      key: "expira",
    },
    {
      title: "Observación",
      dataIndex: "observacion",
      key: "observacion",
    },
    {
      title: "Acciones",
      key: "action",
      // width: 180,
      // fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          <Button style={{ color: "white", background: "#10CA00" }} 
            onClick={() => {
              setSelectedSolicitudId(record.id_solicitud);
              setApprovalModalVisible(true);
            }}
          >
            Otorgar
          </Button>
          <Button style={{ color: "white", background: "#FF0000" }}
            onClick={() => {
              setSelectedSolicitudId(record.id_solicitud);
              setRejectionModalVisible(true);
            }}
          >
            Rechazar
          </Button>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: ["loser"],
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
  ];
  const obtenerTablaBeneficios = async () => {
    setUserName(user.nombre);
    console.log("se ejecutio obtenerTablaBeneficios");
    setLoadingBeneficios(true);
    const { data, msg } = await TABLA_BENEFICIOS_EJECUTIVO();
    console.log(data, msg, "FRONT");
    if (data === null) {
      setErrorMessage(msg);
      setModalErrorVisible(true);
      setLoadingBeneficios(false);
    } else {
      let formattedData = [];

      if (data && data.length > 0) {
        formattedData = data.map((item) => ({
          key: item.id_solicitud_beneficios,
          id_solicitud: item.id_solicitud_beneficios,
          solicitante: item.id_persona,
          beneficio: item.tipo[0].descripcion,
          documentos: item.documentos,
          estado: item.estados_beneficios.descripcion,
          expira: item.fechaVencimiento,
          observacion: item.observacionEjecutivo,
        }));
      }

      setTablaBeneficios(formattedData);
      setLoadingBeneficios(false);
    }
  };
  useEffect(() => {

    obtenerTablaBeneficios();
  }, []);
  return (
    <Container fluid>
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12} className="titulo">
          Perfil: {userName}
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12} className="subtitulo">
          Solicitud de Beneficios / Validación de documentos
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
          {/* <Table columns={columns} dataSource={data} bordered={true}  style={{marginTop:"20px"}} scroll={{
            x: 1000,
            y: 400,
          }} /> */}
          <Table
            columns={columns}
            dataSource={tablaBeneficios}
            bordered={true}
            loading={loadingBeneficios}
            style={{ marginTop: "20px" }}
            scroll={{
              x: 1000,
              y: 400,
            }}
          />
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
        
        </Col>
      </Row>
      <ErrorModal
        isVisible={modalErrorVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
      />
      <SuccessModal
        isVisible={modalSuccessVisible}
        onClose={handleOkSuccess}
        errorMessage={successMessage}
      />
      <ConfirmApprovalModal
        isVisible={isApprovalModalVisible}
        onClose={() => setApprovalModalVisible(false)}
        onApprove={handleApprove}
        solicitudId={selectedSolicitudId}
      />
      <ConfirmRejectionModal
        isVisible={isRejectionModalVisible}
        onClose={() => setRejectionModalVisible(false)}
        onReject={handleReject}
        solicitudId={selectedSolicitudId}
      />
    </Container>
  );
};

export default MantBenefitsExecutive;

import React, { useState,useEffect } from "react";

import { Button, Form, Input, ConfigProvider, Typography, message } from "antd";
import { Col, Row, Container } from "react-bootstrap";
import { messages } from "../utils/messages";
import { ErrorModal, SuccessModal } from "../utils/modalMsg";
import { CAMBIAR_CLAVE_OR_OMITE_LOGIN } from "../services/api";
import { useAuthContext } from "../contexts/authContext";

const { Text } = Typography;
const FormChangePassword = () => {

    const [form] = Form.useForm();
    const { user, usuarioSinPermisos} = useAuthContext();
    const [modalErrorVisible, setModalErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    // Modal success
    const [modalSuccessVisible, setModalSuccessVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleOk = () => {
      setModalErrorVisible(false);
    };
    const handleOkSuccess = () => {
      setModalSuccessVisible(false);
    };
  
    // const passwordOldRules = [
    //     { required: true, message: "Por favor ingrese su contraseña anterior!" },
    //     {
    //       pattern: new RegExp(
    //         "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*\\d)[A-Za-z\\d!@#$&*]{8,15}$"
    //       ),
    //       message:
    //         "La contraseña debe tener entre 8 y 15 caracteres, incluir al menos una mayúscula, un número y un caracter especial (!@#$&*).",
    //     },
    //   ];
    // const passwordNewRules = [
    //   { required: true, message: "Por favor ingrese su nueva contraseña!" },
    //   {
    //     pattern: new RegExp(
    //       "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*\\d)[A-Za-z\\d!@#$&*]{8,15}$"
    //     ),
    //     message:
    //       "La contraseña debe tener entre 8 y 15 caracteres, incluir al menos una mayúscula, un número y un caracter especial (!@#$&*).",
    //   },
    // ];
  


    const passwordOldRules = [
      { required: true, message: "¡Por favor ingrese su contraseña anterior!" },
      {
        pattern: new RegExp("^.{8,15}$"),
        message: "La contraseña debe tener entre 8 y 15 caracteres.",
      },
      {
        pattern: new RegExp("^(?=.*[A-Z]).+$"),
        message: "La contraseña debe incluir al menos una mayúscula.",
      },
      {
        pattern: new RegExp("^(?=.*\\d).+$"),
        message: "La contraseña debe incluir al menos un número.",
      },
      {
        pattern: new RegExp("^(?=.*[!@#$%^&*(),.?\:{}|<>\\[\\];~_+=-]).+$"),
        message: "La contraseña debe incluir al menos un carácter especial (!@#$%^&*.).",
      },
    ];
    const passwordNewRules = [
      { required: true, message: "¡Por favor ingrese su nueva contraseña!" },
      {
        pattern: new RegExp("^.{8,15}$"),
        message: "La contraseña debe tener entre 8 y 15 caracteres.",
      },
      {
        pattern: new RegExp("^(?=.*[A-Z]).+$"),
        message: "La contraseña debe incluir al menos una mayúscula.",
      },
      {
        pattern: new RegExp("^(?=.*\\d).+$"),
        message: "La contraseña debe incluir al menos un número.",
      },
      {
        pattern: new RegExp("^(?=.*[!@#$%^&*(),.?\:{}|<>\\[\\];~_+=-]).+$"),
        message: "La contraseña debe incluir al menos un carácter especial (!@#$%^&*.).",
      },
    ];
  
    const confirmPasswordNewRules = [
      {
        required: true,
        message: "¡Por favor, ingrese la nueva contraseña de confirmación!",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password_new") === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("¡La nueva contraseña que ingresó no coincide!")
          );
        },
      }),
    ];
  
    const customInputTheme = {
      components: {
        Input: {
          // padding: "10px 10px 10px 10px",
          colorTextPlaceholder: "#FFFFFF",
          colorBgContainer: "#0885C7",
          colorText: "#FFFFFF",
          colorError: "#ff4d4f",
        },
      },
    };
  
    const onFinish = async (values) => {
        const {password_old, password_new, password_new2} = values
        const email_login = user.usuario.email_login;
        const valuesEnviar = {
        "email_login" : email_login,
        "password_login" : password_old,
        "password_new" :password_new,
        "origen" : "clave" //SE APLICA ORIGEN CLAVE 
        }
        console.log(valuesEnviar);
        //PARA USAR API SE DEBE ENVIAR ORIGEN CLAVE PARA QUE HAGA LA ACCION CORRESPONDIENTE DE CAMBIAR CONTRASEÑA
        const { data, msg } = await CAMBIAR_CLAVE_OR_OMITE_LOGIN(valuesEnviar)
        if (data === null) {
        setModalErrorVisible(true);
        setErrorMessage(msg);
        } else {
        setModalSuccessVisible(true);
        setSuccessMessage(msg);
        form.resetFields();
        }
    };

    const onFinishFailed = (errorInfo) => {
      message.error("Complete los datos del formulario cambiar contraseña.")
    };
    useEffect(() => {
        usuarioSinPermisos();
    }, [usuarioSinPermisos]);
  return (
    <Container className="contenedor_changepass" fluid>
        <Row className="changepass_form_conteiner">
        <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            xs={12}
            style={{ textAlign: "center" }}
        >
            <Typography>
                <Text className="titulo">Cambiar contraseña</Text>
            </Typography>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} xs={12} className="changepass_text">
            {messages.text.changePass}
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
            <Form
            form={form}
            name="form_change_password"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="changepass_form_content"
            layout="vertical"
            initialValues={{
                "password_old": "",
                "password_new":"",
                "password_new2": "",
            }}
            >
            <Row>
                <ConfigProvider theme={customInputTheme}>
                    <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <Form.Item name="password_old" rules={passwordOldRules}>
                    <Input.Password
                      id="password_oldInput"
                      placeholder={messages.placeholder.password_old}
                      className="changepass_input"
                    />
                    </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <Form.Item name="password_new" rules={passwordNewRules}>
                    <Input.Password
                      id="password_newInput"
                      placeholder={messages.placeholder.password_new}
                      className="changepass_input"
                    />
                    </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} xs={12}>
                    <Form.Item
                    name="password_new2"
                    dependencies={["password_new"]}
                    rules={confirmPasswordNewRules}
                    >
                    <Input.Password
                      id="password_new2Input"
                      placeholder={messages.placeholder.password_newConfirm}
                      className="changepass_input"
                    />
                    </Form.Item>
                </Col>
                <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    xs={6}
                    className="changepass_leyend"
                >
                    • Min. 8 caracteres
                </Col>
                <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    xs={6}
                    className="changepass_leyend"
                >
                    • 1 símbolo (+,*#$)
                </Col>
                <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    xs={6}
                    className="changepass_leyend"
                >
                    • Max. 15 caracteres
                </Col>
                <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    xs={6}
                    className="changepass_leyend"
                >
                    • 1 mayúscula
                </Col>
                <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    xs={6}
                    className="changepass_leyend"
                >
                    • 1 número
                </Col>
                <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    xs={12}
                    style={{ marginTop: "5%" }}
                >
                    <Form.Item>
                    <Button className="changepass_boton" htmlType="submit">
                        Cambiar
                    </Button>
                    </Form.Item>
                </Col>
                </ConfigProvider>
            </Row>
            </Form>
        </Col>
        </Row>
        <ErrorModal
        isVisible={modalErrorVisible}
        onClose={handleOk}
        errorMessage={errorMessage}
        />
        <SuccessModal
        isVisible={modalSuccessVisible}
        onClose={handleOkSuccess}
        errorMessage={successMessage}
        />
    </Container>
  )
}

export default FormChangePassword

